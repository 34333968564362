import Types from "./../constants/payments";
const defaultState = {
  list: false,
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
