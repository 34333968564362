export default function simpleAgenda() {
  return (
    <div className="agenda-adv-data" style={{ margin: "20px 0" }}>
      <h3>Публичный договор присоединения (далее – Договор)</h3>
      <h2>1. Основные термины</h2>
      <p>
        1.1. Реферальная программа — партнерская программа школ устного
        счета «Соробан», размещенная на сайте https://amb-test.soroban.ua/
        (далее – Сайт), направленная на привлечение новых пользователей с
        целью предоставления услуг по обучению устному счету арифметических
        последовательностей по методике «Соробан»(далее – Программа).
        <br />
        1.2. Заказчик – физическое лицо-предприниматель, предлагающее
        заключить публичный договор присоединения с целью привлечения новых
        пользователей.
        <br />
        1.3. Исполнитель (Амбассадор) – лицо, акцептовавшее Публичный
        договор оферты и получившее персональную реферальную ссылку (QR-код)
        (далее – ссылка) для привлечения пользователей.
        <br />
        1.4. Персональная реферальная ссылка (QR-код) – ссылка на Сайт с
        уникальным номером, позволяющая определить (идентифицировать)
        Исполнителя, как лицо, которое привлекло нового пользователя
        (Клиента/Реферала), и, которое получает вознаграждение за
        привлечение такого пользователя.
        <br />
        1.5. Реферал – новый пользователь, привлеченный Исполнителем.
        <br />
        1.6. Клиент (Лид) – новый Реферал, привлеченный Исполнителем, по
        которому можно определить непосредственно самого Исполнителя,
        который провел оплату за первый месяц обучения, и, который обучался
        устному счету арифметических последовательностей по методике
        «Соробан» не менее чем 45 (сорок пять) дней.
        <br />
        1.7. Реферальный счет – финансовый̆ небанковский̆ счет (Баланс),
        который̆ открывается для Исполнителя в Программе после прохождения
        Исполнителем процедуры регистрации, и, на котором отражаются все
        денежные операции по Программе.
        <br />
        1.8. В настоящем Договоре могут использоваться иные термины, не
        определенные в настоящем разделе. Толкование таких терминов будет
        осуществляться в соответствии со смыслом Договора и действующим
        законодательством Украины. В случае отсутствия однозначного
        толкования термина в тексте Договора и в нормативно-правовых актах
        Украины, Стороны будут руководствоваться толкованием термина,
        сложившимся в сети Интернет и в практике делового оборота.
        <br />
      </p>

      <h2>2. Предмет договора</h2>
      <p>
        2.1. Заказчик предлагает заинтересованному лицу заключить Публичный
        договор присоединения гражданско–правового характера на условиях,
        изложенных в настоящем Договоре. Согласно ст. 633 и ст. 634
        Гражданского кодекса Украины, этот договор является публичным
        договором присоединения. Договор является официальным документом,
        публикуемый на Сайте.
        <br />
        2.2. В соответствии со ст. 642 Гражданского кодекса Украины
        настоящий Договор считается согласованным и заключенным Исполнителем
        путем совершения им действий, свидетельствующих о согласии соблюдать
        условия Договора, без подписания письменного экземпляра Сторонами.
        Действиями, свидетельствующими о согласии соблюдать условия
        Договора, является совершение Исполнителем следующего действия:
        подтверждение такого согласия Исполнителем на Сайте путем активации
        отметки «СОГЛАСЕН».
        <br />
        2.3. Заключение настоящего Договора осуществляется в порядке,
        определенном п. 2.2. Договора, путем присоединения Исполнителя к
        настоящему Договору, то есть путем принятия (акцепта) Исполнителем
        условий настоящего Договора в целом, без каких-либо условий, изъятий
        и оговорок. Заключая Договор, Исполнитель автоматически соглашается
        с полным и безусловным принятием положений Договора, в том числе
        стоимости работ, и всех приложений, являющихся неотъемлемой
        составной частью Договора. Если лицо несогласно с любым из условий
        настоящего Договора или Договора в целом, ему необходимо
        воздержаться от присоединения к Договору.
        <br />
        2.4. Принимая настоящий Договор, Исполнитель подтверждает, что:
        <br />
        - имеет необходимый объем гражданской дееспособности;
        <br />
        - на момент заключения настоящего Договора не связан какими-либо
        обязательствами и ему неизвестны какие-либо обстоятельства,
        препятствующие ему сделать вывод о целесообразности заключения им
        настоящего Договора и надлежащим образом выполнить все взятые на
        себя обязательства, указанные в настоящем Договоре;
        <br />
        - его волеизъявление является свободным и соответствует его
        внутренней воле;
        <br />
        - готов соблюдать и выполнять все условия настоящего Договора;
        <br />
        - не намерен осуществлять любые действия, которые помешали бы
        деловой репутации Заказчика и / или третьих лиц, связанных
        договорными отношениями с Заказчиком, или противоречащие
        законодательству Украины, международным нормам и обычаям делового
        оборота;
        <br />
        - его действия направлены на получение работ и в них не содержится
        злого умысла, мошеннических намерений, попыток несанкционированного
        доступа к информации, которая является собственностью Заказчика;
        <br />
        - персональная информация, предоставляемая им Заказчику, является
        верной, полной и актуальной.
        <br />
        2.5. Условия Договора определяются Заказчиком самостоятельно в
        соответствии и во исполнение требований действующего
        законодательства Украины. Условия Договора могут быть изменены
        Заказчиком с обязательным уведомлением об этом Исполнителя на Сайте.
        В случае несогласия Исполнителя с изменениями, внесенными в Договор,
        такой Исполнитель вправе расторгнуть Договор в соответствии с
        порядком, изложенным в п.5.1.2. настоящего Договора.
        <br />
        2.6. При внесении изменений в настоящий Договор, Заказчик размещает
        сообщение о таких изменениях на Сайте не менее чем за 10 (десять)
        календарных дней до вступления таких изменений в силу, кроме
        случаев, для которых Договором установлен иной срок и / или порядок
        уведомления о внесении изменений. При этом Заказчик гарантирует и
        подтверждает, что размещенная на Сайте текущая редакция текста
        настоящего Договора является действительной.
        <br />
        2.7. Исполнитель выполняет работы по привлечению новых Клиентов
        Заказчику, а Заказчик обязуется осуществлять оплату вознаграждения
        Исполнителю на условиях и в порядке, предусмотренных настоящим
        Договором.
        <br />
      </p>

      <h2>3.Порядок оплаты вознаграждения</h2>

      <p>
        3.1.Оплате подлежат работы Исполнителя, в результате которых был
        привлечен новый Клиент, который совершил переход на Программу по
        персональной реферальной ссылке Исполнителя, зарегистрировался на
        Сайте, произвел оплату услуг Заказчика за первый месяц обучения
        устному счету арифметических последовательностей по методике
        «Соробан» и фактически обучался не менее 45 (сорок пять) дней.
        <br />
        3.2. Стоимость работы Исполнителя составляет – 500 (пятьсот) грн. за
        одного привлеченного Клиента. Иного вознаграждения Исполнитель не
        получает.
        <br />
        3.3. При этом в случае, если сумма вознаграждения за отчетный месяц,
        составляет сумму менее 5000 (пять тысяч) грн. выплата вознаграждения
        в отчетном месяце не производится, а указанная сумма должна быть
        выплачена при достижении суммы вознаграждения в размере 5000 (пять
        тысяч) грн.
        <br />
        3.4. Вся платежная информация относительно оплаты работ Исполнителя
        отображается на его Реферальном счету и доступна Исполнителю на
        Сайте.
        <br />
        3.5. Оплата производится безналичным способом на банковские
        реквизиты, указанные Исполнителем при регистрации. Исполнитель
        единолично несет весь риск неблагоприятных последствий неверного
        указания своих банковских реквизитов.
        <br />
        3.6. Фактическое перечисление денежных средств происходит по
        требованию Исполнителя.
        <br />
        3.7. Денежные средства в качестве вознаграждения Исполнителя
        подлежат выплате в течении 2 (двух) банковских дней после получения
        требования Исполнителя о перечислении денежных средств.
        <br />
      </p>

      <h2>4. Права и обязанности Исполнителя</h2>
      <p>
        4.1. Исполнитель вправе
        <br />
        4.1.1. Получать вознаграждение в порядке и на условиях,
        установленных настоящим Договором.
        <br />
        4.1.2. При наличии возражения по суммам вознаграждений, отображенным
        на Реферальном счете, направить соответствующее письмо на
        электронный̆ адрес Заказчика ______________________ с указанием темы
        письма «Возражения по вознаграждению». Срок ответа на возражения
        Исполнителя составляет 2 (два) дня со дня получения письма
        Заказчиком.
        <br />
        4.1.3. Исполнитель имеет право получать необходимое консультирование
        по услугам Заказчика с целью исполнения обязательств по настоящему
        Договору
        <br />
        4.2. Исполнитель обязуется
        <br />
        4.2.1. Использовать Персональную реферальную ссылку исключительно в
        соответствии с настоящим Договором и не использовать ее в каких-либо
        иных целях, не предусмотренных настоящим Договором.
        <br />
        4.2.2. Действовать добросовестно и исключительно в интересах
        Заказчика, не совершать действий, способных навредить деловой
        репутации Заказчика, снизить уровень доверия к Заказчику или ввести
        потенциальных Клиентов в заблуждение относительно деятельности
        Заказчика.
        <br />
        4.2.3. Не оказывать аналогичных услуг, предусмотренных настоящим
        Договорам, конкурентам Заказчика (2х2, Индиго, Смартум, Абакуда и
        т.д, кроме школ устного счета «Соробан»).
        <br />
        4.2.4. Способствовать повышению уровня доверия потенциальных
        Клиентов и иных лиц к Заказчику.
        <br />
      </p>

      <h2>5.Права и Обязанности Заказчика</h2>

      <p>
        5.1. Заказчик вправе.
        <br />
        5.1.1. Без каких-либо ограничений, по своему усмотрению, заключать
        аналогичные договора с неограниченным кругом лиц.
        <br />
        5.1.2. В одностороннем порядке внести изменения в условия настоящего
        Договора. Указанные изменения вступают в силу с даты их публикации.
        В случае, если Исполнитель не согласен с такими изменениями, то он в
        течение 10 (десяти) календарных дней после даты опубликования новой
        редакции Договора направляет соответствующее уведомление на
        электронную почту Заказчика, в противном случае такие изменения
        считаются принятыми Исполнителем.
        <br />
        5.2. Заказчик обязуется.
        <br />
        5.2.1. Своевременно выплачивать Исполнителю вознаграждение,
        предусмотренное условиями настоящего Договора.
        <br />
        5.2.2. Предоставлять Исполнителю всю необходимую информацию,
        необходимую для привлечения новых Клиентов.
        <br />
      </p>

      <h2>6. Ответственность Сторон и решение споров</h2>

      <p>
        6.1. Стороны несут ответственность в соответствии с действующим
        законодательством Украины.
        <br />
        6.2. В случае невыполнения условий п. 4.2.2 настоящего Договора
        Исполнитель выплачивает Заказчику штраф ущерб в размере 10000
        (десять тысяч) грн.
        <br />
        6.3. Все споры между Сторонами рассматриваются путем переговоров на
        основе урегулирования конфликта с максимальным учетом интересов
        Заказчика и Исполнителя. В случае, если Стороны не смогут прийти к
        соглашению по спорным вопросам путем переговоров, такие споры
        подлежат передаче на рассмотрение суда в соответствии с действующим
        законодательством Украины.
        <br />
        6.4. Признание судом недействительным любого из положений настоящего
        Договора не влечет недействительности других его положений или
        Договора в целом, а потому не ведет к потере им юридической силы.
        <br />
      </p>

      <h2>7. Обстоятельства непреодолимой силы (форс-мажор)</h2>

      <p>
        7.1.Сторона освобождается от определенной настоящим Договором и /
        или действующим в Украине законодательством ответственности за
        полное или частичное нарушение Договора, если она докажет, что такое
        нарушение произошло вследствие действия форс-мажорных обстоятельств,
        определенных в настоящем Договоре, при условии, что их наступление
        было засвидетельствовано компетентным органом, определенным
        действующим законодательством Украины.
        <br />
        7.2. Под форс-мажорными обстоятельствами в настоящем Договоре
        понимаются любые чрезвычайные события внешнего относительно Сторон
        характера, которые возникают без вины Сторон, вне их воли или
        вопреки воле или желанию Сторон, и которые нельзя при условии
        употребления обычных для этого мероприятий предусмотреть и нельзя
        при всей заботливости и осмотрительности отвлечь (избежать), включая
        стихийные явления природного характера (землетрясения, наводнения,
        ураганы, разрушения в результате молнии и т.п.), бедствия
        биологического, техногенного и антропогенного происхождения (взрывы,
        пожары, массовые эпидемии, эпизоотии, эпифитотии т.п.),
        обстоятельства общественной жизни (война, военные действия, блокады,
        общественные волнения, проявления терроризма, массовые забастовки и
        локауты, бойкоты и т.п.), а также издание запретительных нормативных
        актов органов государственной власти или местного самоуправления,
        ограничивающие и делают невозможным выполнение Сторонами настоящего
        Договора или временно препятствуют таком исполнению.
        <br />
        7.3. Сторона, которая намерена сослаться на форс-мажорные
        обстоятельства, обязана безотлагательно с учетом возможностей
        технических средств мгновенной связи и характера имеющихся
        препятствий уведомить другую Сторону о наличии форс-мажорных
        обстоятельств и их влиянии на исполнение настоящего Договора.
        <br />
        7.4. Если же обстоятельства непреодолимой силы препятствовали
        своевременному сообщению другой стороны, сообщение должно быть
        направлено любыми техническими средствами мгновенной связи в течение
        5 (пяти) календарных дней с момента, когда это стало возможным.
        <br />
        7.5. Если форс-мажорные обстоятельства и (или) их последствия
        временно препятствуют выполнению настоящего Договора, то исполнение
        настоящего Договора приостанавливается на срок, в течение которого
        оно невозможно.
        <br />
        7.6. Если в связи с форс-мажорными обстоятельствами и (или) их
        последствиями, за которые ни одна из Сторон не отвечает, выполнение
        настоящего Договора является окончательно невозможным, то настоящий
        Договор считается прекращенным с момента возникновения невозможности
        выполнения настоящего Договора, однако Стороны не освобождаются от
        ответственности, предусмотренной настоящим Договором.
        <br />
        7.7. Сторона не вправе ссылаться на обстоятельства непреодолимой
        силы как на основания для освобождения от ответственности, если эти
        обстоятельства возникли после наступления срока исполнения
        соответствующих обязательств по настоящему Договору.
        <br />
      </p>

      <h2>8. Срок действия и расторжение договора.</h2>
      <p>
        8.1. Настоящий Договор заключается на срок с момента совершения
        Исполнителем действий, предусмотренных в п.2.2. Договора, и
        действует до 31 декабря 2030 года.
        <br />
        8.2. Настоящий Договор может быть расторгнут до истечения срока его
        действия по взаимному согласию Сторон или в одностороннем порядке, а
        также в случаях, предусмотренных настоящим Договором с соблюдением
        условий настоящего Договора.
        <br />
        8.3. В случае одностороннего расторжения настоящего Договора Сторона
        должна уведомить об этом другую Сторону. При этом Договор будет
        считаться расторгнутым со стороны Заказчика через три рабочих дня со
        дня направления Исполнителю сообщение на электронную почту .
        <br />
        8.4. Заказчик вправе расторгнуть настоящий Договор в одностороннем
        порядке с осуществлением немедленного блокирования доступа
        Исполнителя к Программе, в случаях:
        <br />
        - осуществление действий, которые могут навредить деловой репутации
        Заказчика и / или третьих лиц, связанных договорными отношениями с
        Заказчиком, или противоречат законодательству Украины, международным
        нормам и обычаям делового оборота;
        <br />
        - если Заказчиком будут сделаны попытки несанкционированного доступа
        к информации, которая является собственностью Заказчика;
        <br />
        - при предоставлении Исполнителем неверной, неполной и / или
        неактуальной персональной информации;
        <br />
        - принятия судом соответствующего решения;
        <br />
        - в других случаях, предусмотренных действующим законодательством
        Украины.
        <br />
      </p>

      <h2>9. Конфиденциальность и личные данные.</h2>

      <p>
        9.1. Вся информация, которая стала известна Исполнителю в связи с
        выполнением работ по настоящему Договору (в том числе пароли доступа
        к Программе) является конфиденциальной информации и коммерческой
        тайной Заказчика.
        <br />
        9.2. Исполнитель обязуются не разглашать и не передавать
        конфиденциальную информацию и коммерческую тайну Заказчика для
        ознакомления и / или использования третьим лицам без
        предварительного письменного согласия Заказчика.
        <br />
        9.3. Исполнитель в связи с заключением настоящего Договора дает свое
        согласие Заказчику на обработку любых персональных данных, ставших
        известными Заказчику в результате предоставления услуг на условиях
        настоящего Публичного договора присоединения. Обработка персональных
        данных включает, но не ограничивается сбором, регистрацией,
        накоплением, хранением, адаптацией, изменением, обновлением,
        использованием и распространением (включая передачу), обезличивание,
        уничтожение персональных данных, обрабатываемых Заказчиком, любым
        лицом, связанной с Заказчиком отношениями контроля с целью ведения
        базы персональных данных Исполнителей.
        <br />
        9.4. Исполнитель соглашается, что Заказчик не должен получать
        никакого дополнительного согласия Исполнителя для передачи
        персональных данных Исполнителя любому лицу, связанному с Заказчиком
        отношениями контроля или в рамках договорных отношений.
        <br />
      </p>
      <h2>10. Иные условия.</h2>
      <p>
        10.1. Стороны подтверждают полное и однозначное понимание ими
        предмета и содержания настоящего Договора, прав и обязанностей
        каждой стороны, вытекающих или связанных с выполнением Договора,
        суть терминов, принятых в Договоре, а также соответствие текста
        Договора намерениям и воли Сторон. Договор заключается на выгодных
        для Сторон условиях и не является результатом воздействия тяжелых
        для них обстоятельств.
        <br />
        10.2. Окончание срока настоящего Договора не освобождает Стороны от
        ответственности за его нарушение, которое произошло во время
        действия настоящего Договора.
        <br />
        10.3. В случаях, не предусмотренных настоящим Договором, Стороны
        руководствуются действующим законодательством Украины.
      </p>

      <h2>11. Реквизиты Заказчика:</h2>
    </div>
  )
}
