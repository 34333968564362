// eslint-disable-next-line
import { ApiConnector, GET } from "./../request.service";
export default (() => {
  const API = ApiConnector("/payments");
  return {
    GetUserPayments: async () =>
      await API(GET, "/user", false, { complete: false }).call(),
    GetServicePayments: async (service_seq) =>
      await API(GET, `/service/${service_seq}`, false, {
        complete: false,
      }).call(),
    ConfirmUserPayment: async (query) => API(GET, `/${query}/confirm`).call(),
    ConfirmServicePayment: async (query) => API(GET, `/${query}/affirm`).call(),
  };
})();
