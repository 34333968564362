import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import soroban from "./../../../resources/img/soroban.svg";
import BrandsComponent from "../../../components/Brands";

class BrandPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [
        {
          logo: soroban,
          title: "Soroban",
          program_title: "soroban",
          liked: true,
          brand_data: {
            payed: "50",
            result: "26",
            price: "350 UAH",
          },
        },
        // {
        //   logo: null,
        //   title: "Artishok",
        //   liked: false,
        //   program_title: "soroban",
        //   brand_data: {
        //     payed: "50",
        //     result: "26",
        //     price: "350 UAH",
        //   },
        // },
        // {
        //   logo: null,
        //   title: "LegoEduc",
        //   liked: true,
        //   program_title: "soroban",
        //   brand_data: {
        //     payed: "50",
        //     result: "26",
        //     price: "350 UAH",
        //   },
        // },
      ],
    };
  }

  handleLikeClick = (index) => {
    let brands = this.state.brands;
    brands[index].liked = !brands[index].liked;
    this.setState({
      brands,
    });
  };
  componentDidMount() {
    this.props.changeLogoState(false);
  }

  render() {
    return (
      <>
        <BrandsComponent brands={this.state.brands} {...this.props} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { isAuthenticated: state.user && state.user.loggedin };
};

export default connect(mapStateToProps, { changeLogoState })(BrandPage);
