import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";

import CustomersComponent from "../../../components/Customers";
import { setUserProfile } from "../../../actions/user";
import { getServices } from "../../../actions/service";
import { getServicePayments } from "../../../actions/payments";
import { withTranslation } from "react-i18next";
import {
  getServiceContracts,
  sendServiceContracts,
} from "../../../actions/service";
import ExportToExcel from "./export";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { toogleSnackbar } from "./../../../actions/modal";

class HomeServicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
    };
  }
  componentDidMount() {
    if (!this.props.user.first_name && this.props.role_id === 3) {
      this.props.history.push("/edit");
      this.props.toogleSnackbar({
        open: true,
        message: "Please complete your profile.",
      });
    } else {
      this.props.changeLogoState(true);
      this.fetchData();
    }
  }

  async fetchData() {
    await this.props.getServices(this.props.role_id);
    const activeService =
      this.props.services &&
      this.props.services.find((el) => el.service.active === true);
    if (activeService) {
      await getServicePayments(activeService.service.seq);
      await this.props.getServiceContracts({
        limit: 10,
        offset: 0,
        role: 4,
      });
    }
  }

  exportToCSV = async () => {
    const { t } = this.props;
    const res = await this.props.sendServiceContracts();
    const activeService =
      this.props.services &&
      this.props.services.find((el) => el.service.active === true);
    if (res && res.data && res.data === "REQUEST_SENT") {
      this.props.toogleSnackbar({
        open: true,
        message: `${t("home.sended_on_email")} ${activeService.service.email}`,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="home-top-text">
          <div>{t("homeServicePage.customer_list")}</div>
          <IconButton
            onClick={this.exportToCSV}
            aria-label="download customers"
            component="span"
          >
            <DownloadIcon />
          </IconButton>
        </div>
        <CustomersComponent {...this.props} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    services: state.user && state.user.services,
    info: state.user && state.user.info,
    role_id: state.user && state.user.role_id,
    user: state.user && state.user.info && state.user.info.user,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    changeLogoState,
    setUserProfile,
    getServiceContracts,
    getServicePayments,
    getServices,
    sendServiceContracts,
    toogleSnackbar,
  })(HomeServicePage)
);
