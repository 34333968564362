import Types from "../constants/drawler";
import store from "../store";
import { createServiceQr } from "./service";

export function openDrawler(item = {}) {
  return async (dispatch, getState) => {
    let qr = null;
    if (item && !item.qr) {
      const seq =
        getState().user.services &&
        getState().user.services.find((el) => el.service.active === true);
      const sameQr =
        seq &&
        seq.qr &&
        seq.qr.find(
          (el) => el.type === item.qr_type && el.role === item.grant_role
        );
      if (seq.qr && seq.qr.length && sameQr && +item.qr_type !== 2) {
        qr = { data: sameQr.code_secret };
      } else {
        qr = await createServiceQr({
          seq: seq.service.seq,
          body: { qr_type: item.qr_type, grant_role: item.grant_role },
        })(store.dispatch, store.getState);
      }
    }

    if (qr || item.qr) {
      dispatch({
        type: Types.OPEN,
        drawler: {
          ...item,
          qr: item.qr ? item.qr : qr.data,
        },
      });
    }
  };
}

export function closeDrawler() {
  return (dispatch, getState) => {
    dispatch({
      type: Types.CLOSE,
    });
  };
}
