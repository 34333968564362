import user from "./user";
import ui from "./ui";
import drawler from "./drawler";
import modal from "./modal";
import service from "./service";
import contracts from "./contracts";
import payments from "./payments";

const reducer = {
  user,
  service,
  contracts,
  payments,
  ui,
  modal,
  drawler,
};

export default reducer;
