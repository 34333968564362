import { compose, combineReducers, createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import { saveState } from "./localStorage";
import history from "./history";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("storage");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (ex) {
    return undefined;
  }
};
const persistedState = loadState();

const _compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    ...rootReducer,
    router: connectRouter(history),
    form: formReducer,
  }),
  persistedState,
  _compose(applyMiddleware(routerMiddleware(history)), applyMiddleware(thunk))
);

store.subscribe(() => {
  const { form, ui, user, service, contracts } = store.getState();
  setTimeout(() => {
    saveState({
      service,
      contracts,
      form,
      ui,
      user,
    });
  }, 100);
});

export default store;
