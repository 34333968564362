import React, { useState } from "react";

import MapPicker from "react-google-map-picker";

import { setUserGeo } from "../../../actions/user";
import store from "../../../store";

const DefaultLocation = { lat: 48.4407486, lng: 35.0412006 };
const DefaultZoom = 10;

const App = () => {
  const [zoom, setZoom] = useState(DefaultZoom);

  async function handleChangeLocation(lat, lng) {
    const request = new Request(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=AIzaSyCKiNcHpTPAlrL6_IlowSb0z0FfS5gjhFc`
    );
    try {
      const data = await fetch(request);
      const json = await data.json();
      const city = getLocationCity(json.results);
      setUserGeo({ latitude: lat, longitude: lng, city })(
        store.dispatch,
        store.getState
      );
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  function getLocationCity(places) {
    const data = places[0].address_components;
    const city = data.find((el) => el.types.includes("locality")).long_name;
    return `${city}`;
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  return (
    <div id="google-map">
      <MapPicker
        defaultLocation={DefaultLocation}
        zoom={zoom}
        mapTypeId="roadmap"
        style={{ height: "80vh" }}
        onChangeLocation={handleChangeLocation}
        onChangeZoom={handleChangeZoom}
        apiKey="AIzaSyBm3EggEDVvJqz8FoOY-6LST-Sdgrt3NXU"
      />
    </div>
  );
};

export default App;
