import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../actions/ui";
import Slide from "@mui/material/Slide";

import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Box from "@mui/material/Box";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { openDrawler } from "../../actions/drawler";
import { withTranslation } from "react-i18next";
import QrCodeIcon from "@mui/icons-material/QrCode";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MenuListComposition from "../MenuListComposition";
import { openModal } from "../../actions/modal";

class BrandsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: this.props.brands,
    };
  }

  handleLikeClick = (index) => {
    console.log(123);
  };

  handlePhoneClick = (item) => {
    console.log("phone", item);
    this.props.openModal({
      type: "PhoneShowModal",
      data: {
        phone: item.service.phone,
        serviceName: item.service.public_name,
      },
    });
  };

  handleShareClick = (item) => {
    this.props.openDrawler({
      title: item.service.public_name,
      service: item,
      icon: item.service.logo_url,
      role: this.props.role_id,
      qr: item.qr[0].code_secret,
      qr_type: item.qr[0].type,
      grant_role: item.qr[0].role,
    });
  };
  componentDidMount() {
    this.props.changeLogoState(false);
  }

  render() {
    const { t } = this.props;
    const services = this.props.services;
    return (
      <>
        {services &&
          services.map((el, index) => {
            return (
              <Slide key={index} in timeout={300}>
                <Box className="brands-list" sx={{ flexGrow: 1 }}>
                  <div className="top">
                    <div className="brand-icon-title">
                      <div
                        className="brand-icon"
                        style={
                          el.service.logo_url
                            ? { backgroundImage: `url(${el.service.logo_url})` }
                            : null
                        }
                      >
                        {!el.service.logo_url
                          ? `${el.service.public_name.split("")[0]}${
                              el.service.public_name.split("")[1]
                            }`
                          : null}
                      </div>
                      <h3>
                        <div className="brand-raiting">
                          <div className="brand-raiting-title">
                            {el.service.public_name}
                          </div>
                          {el.service.raiting ? (
                            <div className="brand-raiting-item">
                              {el.service.raiting}
                              <StarOutlinedIcon fontSize="small" />
                            </div>
                          ) : null}
                        </div>
                      </h3>
                    </div>
                    <div className="functionalButtons">
                      {this.props.home ? (
                        <IconButton onClick={() => this.handleShareClick(el)}>
                          <QrCodeIcon />
                        </IconButton>
                      ) : null}
                      <MenuListComposition
                        items={[
                          {
                            icon: !el.liked ? (
                              <FavoriteBorderIcon />
                            ) : (
                              <FavoriteIcon color="error" />
                            ),
                            title: t("reg.favorite"),
                            onClick: () => this.handleLikeClick(el.service.id),
                          },
                          {
                            icon: <LocalPhoneIcon />,
                            title: t("reg.contact_number"),
                            onClick: () => this.handlePhoneClick(el),
                          },
                        ]}
                      />
                      {false && <></>}
                    </div>
                  </div>
                  <div className="bottom">
                    {Object.keys({
                      client_count: el.client_count,
                      affirmed_amount: el.affirmed_amount,
                      credited_amount: el.credited_amount,
                    }).map((item, index) => {
                      return (
                        <div key={index} className="bottom-container">
                          <div>{`${t(`homeAbmPage.${item}`)} :`}</div>
                          <div>{el[item]}</div>
                        </div>
                      );
                    })}
                  </div>
                  {el.affirmed_amount ? (
                    <div className="ammount-avaiable">
                      <span>{t("brands.amount_avaiable")}</span>
                      <span>{el.affirmed_amount}</span>
                    </div>
                  ) : null}
                </Box>
              </Slide>
            );
          })}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.user && state.user.services,
    role_id: state.user && state.user.role_id,
  };
};

// export default connect(mapStateToProps, { changeLogoState, openDrawler })(
//   BrandsComponent
// );
export default withTranslation()(
  connect(mapStateToProps, { changeLogoState, openDrawler, openModal })(
    BrandsComponent
  )
);
