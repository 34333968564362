import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { processWithdrawCard } from "../../../actions/withdraw";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../onSubmit";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";

import add_card from "./../../../resources/img/icons/add-card.svg";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import { withTranslation } from "react-i18next";

class WithdrayComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: null,
      card_holder: null,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.props.initialize({
        card_code: "1111-1111-1111-1111",
        cardholder_name: "Ivan Chabanenko",
      });
    }, 500);
  }

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  renderCardInput = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <InputMask mask={"9999-9999-9999-9999"} {...input} {...custom}>
        {() => (
          <TextField
            error={touched && !!error}
            helperText={touched && !!error && error}
            {...input}
            {...custom}
            variant="outlined"
          />
        )}
      </InputMask>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="white-data-section">
          <div className="add-card-details">
            <div className="icon-card">
              <Icon>
                <img src={add_card} height={25} width={25} alt="add_card" />
              </Icon>
            </div>
            <h3>{t("withdrawContainer.card_details")}</h3>
          </div>
          <Form onSubmit={this.props.handleSubmit}>
            <div className="row item full">
              <Field
                name="card_code"
                component={this.renderCardInput}
                label={t("withdrawContainer.ad_16_number")}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="cardholder_name"
                component={this.renderTextField}
                label={t("withdrawContainer.card_holder_name")}
                variant="outlined"
                required
              />
            </div>
            <div className="withdraw-total-ammount">
              <div>{t("withdrawContainer.total_amount")}</div>
              <div>1750 UAH</div>
            </div>
            <div className="action-buttons">
              <Button variant="contained" className="withdraw" type="submit">
                {t("withdrawContainer.withdraw")}
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { isAuthenticated: state.user && state.user.loggedin };
};

const validate = (values) => {
  const errors = {};

  if (values.card_code && values.card_code.includes("_")) {
    errors.card_code = "Incorrect card number";
  }
  return errors;
};

const withForm = reduxForm({
  form: "AddWithdrawCard",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: processWithdrawCard,
});

export default withTranslation()(
  connect(mapStateToProps, { changeLogoState })(withForm(WithdrayComponent))
);
