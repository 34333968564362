// eslint-disable-next-line
import { ApiConnector, GET, POST, PATCH } from "./../request.service";
import STATUSES from "../../constants/statuses";

export default (() => {
  const API = ApiConnector("/service");
  return {
    CreateService: async (data) => await API(POST, "/create").call(data),
    GetService: async (data) => await API(GET, "/my").call(data),
    ModifyService: async (data) =>
      await API(PATCH, `/${data.seq}`).call(data.send),
    GetServiceContracts: async (data = { limit: 10, offset: 0 }, seq) =>
      await API(GET, `/${seq}/contracts`, false, data).call(),
    SendContracts: async (
      data = { statuses: STATUSES.map((el) => el.value).join(",") },
      seq
    ) => await API(GET, `/${seq}/clients`, false, data).call(),
    CreateInviteService: async (data) =>
      await API(POST, `/${data.seq}/create_invite`).call(data.body),
    ChangeClientStatus: async (body, query) =>
      await API(POST, `/${query}/client`).call(body),
    BotSecret: async (seq) =>
      await API(GET, `/${seq}/botsecret`).call(),
  };
})();
