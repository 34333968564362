import React from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { withTranslation } from "react-i18next";
import { getUserPayments } from "../../../actions/payments";
import { checkForUser } from "./../../../actions/user";
import { closeRequestModal } from "./../../../actions/modal";
import {
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

class PaymentConfirmModal extends React.Component {
  handleChange(e, index) {
    const activeService =
      this.props.services &&
      this.props.services.findIndex((el) => +el.service.id === +e.target.value);
    this.props.updateService(activeService);
  }

  handleAcceptWorkerRole = () => {
    console.log("worker");
    this.props.checkForUser("worker").then((res) => {
      this.props.history.push("/service/home");
      this.props.closeRequestModal();
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="modal-window">
        <div className="modal-window-info">
          <Stack spacing={2}>
            <Typography>{t("modal.worker_detected")}</Typography>
            <Typography>{t("modal.continue_worker")}</Typography>
            <FormControl sx={{ width: "220px" }} className="service-picker">
              <Select
                value={this.props.data.services[0].service.id}
                onChange={(e) => this.handleChange(e)}
                input={<OutlinedInput />}
              >
                {this.props.data.services.map((el, index) => (
                  <MenuItem value={el.service.id} key={index}>
                    {el.service.public_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
        <div className="modal-buttons-container">
          <Button
            className="modal-buttons no"
            onClick={this.props.closeRequestModal}
          >
            {t("modal.No")}
          </Button>
          <Button
            className="modal-buttons yes"
            onClick={this.handleAcceptWorkerRole}
          >
            {t("modal.Yes")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { services: state.user && state.user.services };
};
export default withTranslation()(
  connect(mapStateToProps, {
    getUserPayments,
    checkForUser,
    closeRequestModal,
  })(PaymentConfirmModal)
);
