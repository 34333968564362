import Types from "./../constants/user";
const defaultState = {
  info: null,
  loggedin: false,
  role_id: null,
  geo: {
    latitude: null,
    longitude: null,
    city: null,
  },
  oauth: {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
  },
  service: {
    phone: null,
    email: null,
    logo: null,
    title: null,
  },
  services: null,
  reg_start: false,
  smsTimer: null,
  qr: null,
};

const data = (state = defaultState, action) => {
  switch (action.type) {
    case Types.LOGIN:
    case Types.LANG:
    case Types.SETPROFILE:
    case Types.RESUME:
      return { ...state, ...action.user };
    case Types.SET_INFO:
      return { ...state, info: { ...action.info } };
    case Types.LOGOUT:
      return {
        ...defaultState,
        geo: state.geo,
        reg_start: state.reg_start,
        qr: state.qr
      };
    case Types.FULL_LOGOUT:
      return { ...defaultState };
    default:
      return state;
  }
};

export default data;
