import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../../onSubmit";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

import InputAdornment from "@mui/material/InputAdornment";

import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { withTranslation } from "react-i18next";
import { serviceRegisterSet } from "../../../../actions/register";
import LinkIcon from "@mui/icons-material/Link";
import { handleBack } from "../../../../actions/register";

class ServiceRegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.props.initialize({
      contact_number: this.props.service.contact_number || "",
      YouTube: this.props.service.YouTube || "",
      WebLink: this.props.service.WebLink || "",
      about: this.props.service.about || "",
    });
  }

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  renderPhoneMask = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="UA"
        limitMaxLength
        className={touched && !!error && error && "error"}
        {...input}
        {...custom}
      />
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className="login-data-container">
        <Slide in timeout={500}>
          <div className="select-auth-method">
            <Form onSubmit={this.props.handleSubmit}>
              <div className="row item full select">
                <div>{t("reg.contact_number")}</div>
                <Field
                  name="contact_number"
                  component={this.renderPhoneMask}
                  placeholder={t("reg.Write Here")}
                  variant="outlined"
                  required
                />
              </div>
              <div className="row item full select">
                <div>{t("reg.YouTube")}</div>
                <Field
                  name="YouTube"
                  component={this.renderTextField}
                  placeholder={t("reg.Add Link")}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon style={{ color: "#52b797" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="row item full select">
                <div>{t("reg.WebLink")}</div>
                <Field
                  name="WebLink"
                  component={this.renderTextField}
                  placeholder={t("reg.Add Link")}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon style={{ color: "#52b797" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="row item full select">
                <div>{t("reg.about")}</div>
                <Field
                  name="about"
                  component={this.renderTextField}
                  placeholder={t("reg.Write Here")}
                  variant="outlined"
                  multiline
                  rows={4}
                  required
                />
              </div>

              <Box className="light center margin-large txt14 flex">
                <Button
                  className="orange rounded center back-button"
                  onClick={() => handleBack()}
                >
                  {t("reg.back")}
                </Button>
                <Button className="orange rounded center" type="submit">
                  {t("reg.next")}
                </Button>
              </Box>
            </Form>
          </div>
        </Slide>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    service: state.service && state.service[3],
  };
};

const validate = (values) => {
  const errors = {};
  if (!isPossiblePhoneNumber(values.contact_number || "")) {
    errors.contact_number = "Incorrect prone number";
  }
  return errors;
};

const withForm = reduxForm({
  form: "ServiceRegisterPage3",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: serviceRegisterSet,
});

export default withTranslation()(
  connect(mapStateToProps, { serviceRegisterSet })(
    withForm(ServiceRegisterComponent)
  )
);
