import Types from "../constants/user";
import history from "../history";
import AuthService from "./../requests/services/auth.service";
import TokenService from "../requests/services/token.service";
import QrService from "../requests/services/qr.service";
import UserService from "../requests/services/user.service";
import { getServices } from "./service";
import { openModal } from "./modal";

const roleSchema = {
  ambassador: 1,
  service: 0,
  lead: 2,
  worker: 3,
};

export async function getUserContracts() {
  const res = await QrService.GetUserContracts();
  return res;
}

export function resumeToken(refresh_token) {
  return async (dispatch, getState) => {
    const { info } = getState().user;
    const response = await TokenService.Resume(refresh_token);
    setUserProfile({
      info: {
        ...info,
        token: response.data.token,
        refresh_token: response.data.token,
      },
    })(dispatch, getState);
    return response;
  };
}

export function sendPhoneCheck(data) {
  return async (dispatch, getState) => {
    const res = await AuthService.PhoneCheck({
      ...data,
    });
    return res;
  };
}

export function sendPhoneCodeCheck(data) {
  return async (dispatch, getState) => {
    const res = await AuthService.PhoneCodeCheck({
      ...data,
    });
    return res;
  };
}

export function processContractExist(data) {
  return async (dispatch, getState) => {
    delete data.qr;
    await setUserProfile({
      qr: null,
    })(dispatch, getState);
    await history.push(`/login/${history.location.pathname.split("/")[2]}`);
    const res = await sendAuthRequest(
      data,
      history.location.pathname.split("/")[2]
    )(dispatch, getState);
    return res;
  };
}

export function clearInfo() {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.SET_INFO,
      info: null,
    });
  };
}

export function sendAuthRequest(data, role = false, no_qr = false) {
  return async (dispatch, getState) => {
    const { user } = getState();
    let role_id = role;
    if (data.phone) {
      data.phone = data.phone.replaceAll(" ", "");
    }

    delete data.role;

    const qr = user.qr && !no_qr ? { qr: user.qr } : null;
    const res = await AuthService.SendAuthRequest({
      ...data,
      ...qr,
    });
    // PHONE NUMBER AUTH
    if (
      res &&
      res.data &&
      res.data.message === "CODE_SENT_SUCCESSFULLY" &&
      (+roleSchema[role_id] === 0 || +roleSchema[role_id] === 1)
    ) {
      dispatch({
        type: Types.SET_INFO,
        info: {
          ...res.data,
          phone: data.phone,
        },
      });
      return false;
    }
    if (res && res.data) {
      dispatch({
        type: Types.SET_INFO,
        info: {
          ...res.data,
        },
      });
    }
    const reg_start = getState().user.reg_start;

    // CHECK IF REG PROCESS IS NEEDED
    const reg_process =
      res &&
      res.data &&
      res.data.message &&
      res.data.message === "PHONE_REQUIRED";

    // LEAD AUTH
    if (
      res &&
      res.data &&
      res.data.message === "CODE_SENT_SUCCESSFULLY" &&
      role === 2
    ) {
      dispatch({
        type: Types.SET_INFO,
        info: {
          ...res.data,
        },
      });
      setUserProfile({
        role_id: role_id,
      })(dispatch, getState);
      return true;
    }

    // SET REG START DATA
    if (res && res.data && reg_process && roleSchema[role] !== 2) {
      setUserProfile({
        loggedin: true,
        role_id: role_id && roleSchema[role_id],
        reg_start: reg_process ? true : reg_start,
      })(dispatch, getState);
      return res;
    }

    return res;
  };
}

export function checkForUser(role_id) {
  return async (dispatch, getState) => {
    // WORKER AUTH
    if (+roleSchema[role_id] === 3) {
      const resService = await getServices()(dispatch, getState);
      if (
        resService &&
        resService.data &&
        resService.data.length &&
        resService.data.find((el) => el.role === 2)
      ) {
        setUserProfile({
          loggedin: true,
          role_id: 3,
          reg_start: false,
          services: resService.data
            .filter((el) => el.role === 2)
            .map((el, index) => ({
              ...el,
              service: {
                ...el.service,
                active: index === 0,
              },
            })),
        })(dispatch, getState);
        history.push("/service/home");
        return false;
      }
      return true;
    }
    // SERVICE AUTH
    if (+roleSchema[role_id] === 0) {
      const resService = await getServices(0)(dispatch, getState);
      const res =
        resService &&
        resService.data &&
        resService.data.length &&
        resService.data;
      if (res.find((el) => el.role === 2)) {
        openModal({
          type: "DetektedWorkerAccount",
          data: {
            services: res.filter((el) => el.role === 2),
          },
        })(dispatch, getState);
      }
      if (res.find((el) => el.role === 1)) {
        setUserProfile({
          loggedin: true,
          role_id: 0,
          reg_start: false,
          services: resService.data
            .filter((el) => el.role === 1)
            .map((el, index) => ({
              ...el,
              service: {
                ...el.service,
                active: index === 0,
              },
            })),
        })(dispatch, getState);
        history.push("/service/home");
        return false;
      }

      setUserProfile({
        loggedin: true,
        role_id: +roleSchema[role_id],
        reg_start: true,
      })(dispatch, getState);
      return true;
    }

    // AMBASSADOR AUTH
    if (+roleSchema[role_id] === 1) {
      await getUserContracts();
      const resService = await getServices(1)(dispatch, getState);
      if (
        resService &&
        resService.data &&
        resService.data.length &&
        resService.data.find((el) => el.role === 3)
      ) {
        setUserProfile({
          loggedin: true,
          role: 1,
          role_id: role_id && roleSchema[role_id],
          services: resService.data.filter((el) => el.role === 3),
        })(dispatch, getState);
        history.push("/home");
        return false;
      }
    }
  };
}

export function checkForAuth() {
  return (dispatch, getState) => {
    const schema = {
      0: "/service/home",
      1: "/home",
      2: "/user/home",
      3: "/service/home",
    };
    const state = getState();
    const loggedin = state.user && state.user.loggedin,
      role_id = state.user && state.user.role_id,
      reg_start = state.user && state.user.reg_start;
    if (loggedin && role_id !== null && !reg_start) {
      openModal({
        type: "AuthAlertModal",
        data: {
          next: schema[role_id],
        },
      })(dispatch, getState);
    }
  };
}

export function handleLogout() {
  return (dispatch, getState) => {
    dispatch({
      type: Types.FULL_LOGOUT,
    });
  };
}

export function setQrCode(qr) {
  return (dispatch, getState) => {
    dispatch({
      type: Types.SETPROFILE,
      user: {
        qr: qr || null,
      },
    });
  };
}

export function setUserProfile(item = false) {
  return (dispatch, getState) => {
    if (!item) {
      dispatch({
        type: Types.LOGOUT,
      });
    } else {
      dispatch({
        type: Types.SETPROFILE,
        user: {
          ...item,
        },
      });
    }
  };
}

export function setUserGeo(item) {
  return (dispatch, getState) => {
    const { geo } = getState().user;
    dispatch({
      type: Types.SETPROFILE,
      user: {
        geo: {
          ...geo,
          ...item,
        },
      },
    });
  };
}

export function updateUserTimer(item) {
  return (dispatch, getState) => {
    dispatch({
      type: Types.SETPROFILE,
      user: {
        smsTimer: item,
      },
    });
  };
}

export function setServiceData(item) {
  return (dispatch, getState) => {
    const { service } = getState().user;
    dispatch({
      type: Types.SETPROFILE,
      user: {
        service: {
          ...service,
          ...item,
        },
      },
    });
  };
}

export function setUserOauth(item) {
  return (dispatch, getState) => {
    const { oauth } = getState().user;
    dispatch({
      type: Types.SETPROFILE,
      user: {
        oauth: {
          ...oauth,
          ...item,
        },
      },
    });
  };
}

export function updateUserProfile(data) {
  return async (dispatch, getState) => {
    const { info } = getState().user;
    const res = await UserService.UpdateUserProfile(data);
    if (res) {
      setUserProfile({
        info: {
          ...info,
          user: {
            ...info.user,
            ...res.data,
          },
        },
      })(dispatch, getState);
      const { role_id } = getState().user;
      history.push(role_id === 3 || role_id === 0 ? "/service/home" : "/home");
    }
  };
}

export function editUserProfile(item) {
  console.log(item);
  if (item.card_code) {
    setTimeout(() => {
      history.push("/service/agenda");
    }, 1000);
    return;
  }
}
