import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../../onSubmit";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { withTranslation } from "react-i18next";
import { serviceRegisterSet } from "../../../../actions/register";
import { handleBack } from "../../../../actions/register";

class ServiceRegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.props.initialize({
      legal_name: this.props.service.legal_name || "",
      city: this.props.service.city || "",
      address: this.props.service.address || "",
      organization_code: this.props.service.organization_code || "",
    });
  }

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className="login-data-container">
        <Slide in timeout={500}>
          <div className="select-auth-method">
            <Form onSubmit={this.props.handleSubmit}>
              <div className="row item full select">
                <div>{t("reg.legal_name")}</div>
                <Field
                  name="legal_name"
                  component={this.renderTextField}
                  placeholder={t("reg.Write Here")}
                  variant="outlined"
                  required
                />
              </div>
              <div className="row item full select">
                <div>{t("reg.city")}</div>
                <Field
                  name="city"
                  component={this.renderTextField}
                  placeholder={t("reg.Write Here")}
                  variant="outlined"
                  required
                />
              </div>
              <div className="row item full select">
                <div>{t("reg.address")}</div>
                <Field
                  name="address"
                  component={this.renderTextField}
                  placeholder={t("reg.Index Street Building")}
                  variant="outlined"
                  multiline
                  rows={4}
                  required
                />
              </div>
              <div className="row item full select">
                <div>{t("reg.organization_code")}</div>
                <Field
                  name="organization_code"
                  component={this.renderTextField}
                  placeholder={t("reg.Write OKPO")}
                  variant="outlined"
                  type="number"
                  required
                />
              </div>
              <Box className="light center margin-large txt14 flex">
                <Button
                  className="orange rounded center back-button"
                  onClick={() => handleBack()}
                >
                  {t("reg.back")}
                </Button>
                <Button className="orange rounded center" type="submit">
                  {t("reg.next")}
                </Button>
              </Box>
            </Form>
          </div>
        </Slide>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    service: state.service && state.service[2],
  };
};

const validate = (values) => {
  const errors = {};
  return errors;
};

const withForm = reduxForm({
  form: "ServiceRegisterPage2",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: serviceRegisterSet,
});

export default withTranslation()(
  connect(mapStateToProps, { serviceRegisterSet })(
    withForm(ServiceRegisterComponent)
  )
);
