import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

import { setUserProfile, setUserOauth } from "../../../actions/user";
import google from "./../../../resources/img/google.svg";
import apple from "./../../../resources/img/apple.png";
import onSubmit from "../../onSubmit";
import { reduxForm, Form, Field, SubmissionError, change } from "redux-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import phone from "./../../../resources/img/icons/phone.svg";
import InputMask from "react-input-mask";
import {
  Typography,
  TextField,
  InputAdornment,
  Icon,
  Zoom,
  ButtonBase,
  CircularProgress,
  Button,
  Box,
  Divider,
} from "@mui/material";

import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { withTranslation } from "react-i18next";
import GoogleLogin from "react-google-login";
import AppleSignin from "react-apple-signin-auth";
import {
  sendAuthRequest,
  handleLogout,
  setQrCode,
  clearInfo,
  sendPhoneCodeCheck,
  checkForUser,
} from "./../../../actions/user";
import ClearIcon from "@mui/icons-material/Clear";
import Footer from "../../../components/Footer";
import store from "../../../store";

const roleSchema = {
  ambassador: 1,
  service: 0,
  lead: 2,
  worker: 3,
};
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: [
        // { name: "Google", icon: google, loading: false },
        // { name: "Apple ID", icon: apple, loading: false },
      ],
      phone: "",
      profile: null,
      allreadyAuth: false,
    };
  }
  componentDidMount() {
    this.props.changeLogoState(true);
    setTimeout(() => {
      // this.props.setUserProfile(false);
      this.props.setQrCode(this.props.match.params.qr);
    }, 500);
    if (this.props.oauth && this.props.oauth.id) {
      this.setState({ allreadyAuth: true });
    }
  }

  handleAuth = (index) => {
    const auth = this.state.auth;
    const isLoading = auth.findIndex((el) => el.loading === true);
    if (isLoading === -1 && !auth[index].loading) {
      auth[index].loading = true;
      this.setState({
        auth,
      });
    }
  };

  async routeToNextStep(item = false) {
    const role = this.props.match.params.role;
    let res;
    if (!item) {
      res = await this.props.sendAuthRequest(
        {
          phone: "380501890304",
        },
        role
      );
    }
    let schema = {
      service: "/service/welcome",
      ambassador: "/auth/phone",
    };
    if (res && res.data && res.data.message === "PHONE_REQUIRED") {
      this.props.setUserProfile({ reg_start: true });
    }
    if (res) this.props.history.push(schema[role]);
  }

  handleLogout = () => {
    this.props.handleLogout();
    this.setState({
      allreadyAuth: false,
    });
  };

  onChange = (event) => {
    this.setState({
      phone: event.target.value,
    });
  };

  renderPhoneMask = ({ input, meta: { touched, error, focus }, ...custom }) => {
    return (
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="UA"
        limitMaxLength
        {...input}
        {...custom}
      />
    );
  };

  renderTextField = ({
    input,
    meta: { touched, error },
    code_error,
    ...custom
  }) => {
    return (
      <InputMask mask={"9 9 9 9"} {...input} {...custom}>
        {() => (
          <TextField
            error={touched && !!code_error}
            helperText={touched && !!code_error && code_error}
            placeholder="+1-___-___-____"
            {...input}
            {...custom}
            variant="outlined"
          />
        )}
      </InputMask>
    );
  };

  codeCheck = async () => {
    let role = this.props.match.params.role;
    const worker =
      role === "service" &&
      this.props.match.params &&
      this.props.match.params.qr;
    if (worker) role = "worker";
    const code =
      this.props.LoginPage &&
      this.props.LoginPage.values &&
      this.props.LoginPage.values.code_check;
    if (code && !code.includes("_")) {
      this.setState({ loading: true });
      const res = await this.props.sendPhoneCodeCheck({
        user_seq: this.props.info.user.seq,
        code: code.replace(/\s+/g, ""),
      });
      if (res && res.error && res.error.message === "CODE_INVALID") {
        store.dispatch(
          change("LoginPage", "code_check_error", "Код неправильный")
        );
      }
      if (res && res.data && res.data.token) {
        this.props.setUserProfile({
          info: {
            ...this.props.user.info,
            message: "",
            token: res.data.token,
            refresh_token: res.data.refresh_token,
            loggedin: true,
            role_id: +roleSchema[role],
          },
        });
        this.props.checkForUser(role).then((resAuth) => {
          let schema = {
            service: "/service/welcome",
            ambassador: "/auth/phone",
            worker: "/edit",
          };
          if (role === "worker") {
            console.log("stop");
            return;
          }
          if (resAuth) {
            this.props.history.push(schema[role]);
          }
        });
      }
    }
  };

  componentWillUnmount() {
    this.props.setUserProfile({
      info: {
        ...this.props.user.info,
        message: "",
      },
    });
  }

  render() {
    const { t } = this.props;

    const handlePhoneCheck = (e) => {
      console.log(e);
    };
    const phoneValue =
      this.props.LoginPage &&
      this.props.LoginPage.values &&
      this.props.LoginPage.values.phone;
    const phoneError =
      (this.props.LoginPage &&
        this.props.LoginPage.syncErrors &&
        this.props.LoginPage.syncErrors.phone &&
        true) ||
      false;
    return (
      <div className="login-data-container">
        <div className="welcome-text center">
          <h2>{t("loginPage.welcome")}</h2>
          <h3>{t("loginPage.please_sign")}</h3>
        </div>

        <div className="select-auth-method">
          <Form onSubmit={this.props.handleSubmit}>
            {(this.props.info &&
              this.props.info.message &&
              this.props.info.message === "CODE_SENT_SUCCESSFULLY" && (
                <>
                  <Typography
                    component="div"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ArrowBackIcon onClick={() => this.props.clearInfo()} />
                    {this.props.info && this.props.info.phone && (
                      <h3
                        className="phone-check"
                        style={{ marginLeft: "10px" }}
                      >
                        {t("reg.message_send_on_phone")}
                        <span>{` ${
                          this.props.info && this.props.info.phone.substr(0, 6)
                        }*****${
                          this.props.info &&
                          this.props.info.phone.substr(11, 13)
                        }`}</span>
                      </h3>
                    )}
                  </Typography>

                  <Field
                    name="code_check"
                    className="center"
                    component={this.renderTextField}
                    variant="outlined"
                    placeholder={t("reg.checkCode")}
                    code_error={
                      (this.props.LoginPage &&
                        this.props.LoginPage.values &&
                        this.props.LoginPage.values.code_check_error) ||
                      false
                    }
                    sx={{
                      width: "100%",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                    required
                  />
                  <Button
                    className="orange rounded center"
                    sx={{ width: "100%", marginBottom: "20px" }}
                    onClick={this.codeCheck}
                  >
                    {t("reg.next")}
                  </Button>
                </>
              )) || (
              <>
                <div className="row item full">
                  <Field
                    name="phone"
                    component={this.renderPhoneMask}
                    variant="outlined"
                    required
                  />
                </div>

                <Button
                  className="orange rounded center"
                  sx={{ width: "100%", marginBottom: "20px" }}
                  type="submit"
                  disabled={!phoneValue || phoneError}
                >
                  {t("reg.next")}
                </Button>
              </>
            )}
          </Form>

          {false && (
            <Divider sx={{ marginBottom: "20px" }}>
              <Typography className="divider-text">
                або увійдіть через
              </Typography>
            </Divider>
          )}
          {this.state.auth.map((item, index) => {
            return (
              <Zoom key={index} in timeout={500}>
                <div>
                  {item.name === "Google" ? (
                    <ButtonBase style={{ width: "100%", marginBottom: "25px" }}>
                      <div
                        className="auth-item"
                        style={{
                          opacity: item.loading ? "0.5" : "1",
                        }}
                        onClick={() => this.routeToNextStep()}
                      >
                        <div
                          className="auth-icon"
                          style={{ backgroundImage: `url(${item.icon})` }}
                        ></div>
                        <div>
                          {t("loginPage.sign_in_using")} {item.name}
                        </div>
                        <div className="proceed-auth-button">
                          {item.loading ? (
                            <CircularProgress
                              style={{ color: "#fff" }}
                              size={18}
                            />
                          ) : (
                            <ArrowForwardOutlinedIcon fontSize="inherit" />
                          )}
                        </div>
                      </div>
                    </ButtonBase>
                  ) : null}
                  {item.name === "Apple ID" ? (
                    <ButtonBase style={{ width: "100%", marginBottom: "25px" }}>
                      <div
                        className="auth-item"
                        style={{
                          opacity: item.loading ? "0.5" : "1",
                        }}
                        onClick={() => this.routeToNextStep()}
                      >
                        <div
                          className="auth-icon"
                          style={{ backgroundImage: `url(${item.icon})` }}
                        ></div>
                        <div>
                          {t("loginPage.sign_in_using")} {item.name}
                        </div>
                        <div className="proceed-auth-button">
                          {item.loading ? (
                            <CircularProgress
                              style={{ color: "#fff" }}
                              size={18}
                            />
                          ) : (
                            <ArrowForwardOutlinedIcon fontSize="inherit" />
                          )}
                        </div>
                      </div>
                    </ButtonBase>
                  ) : null}
                </div>
              </Zoom>
            );
          })}
        </div>
        {false && this.state.allreadyAuth ? (
          <Box className="light center margin-large txt14">
            <Button
              className="orange rounded center"
              onClick={() => this.routeToNextStep(true)}
            >
              Продовжити як
              {` ${this.props.oauth.first_name} ${this.props.oauth.last_name}`}
            </Button>
            <Button
              className="rounded center text-black"
              onClick={this.handleLogout}
              endIcon={<ClearIcon />}
            >
              Вийти
            </Button>
          </Box>
        ) : null}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    oauth: state.user && state.user.oauth,
    info: state.user && state.user.info,
    user: state.user,
    LoginPage: state.form && state.form.LoginPage,
  };
};

const validate = (values) => {
  const errors = {};
  if (values.phone && !isPossiblePhoneNumber(values.phone)) {
    errors.phone = "Incorrect prone number";
  }
  return errors;
};

const loginRequest = (data, props) => {
  return async (dispatch, getState) => {
    const role = props.match.params.role;
    if (isPossiblePhoneNumber(data.phone)) {
      let res = await props.sendAuthRequest(
        {
          phone: data.phone.replaceAll(" ", ""),
        },
        role
      );
      const { info } = store.getState().user;
      if (info && info.code) {
        store.dispatch(change("LoginPage", "code_check", info.code));
      }
    }
  };
};

const withForm = reduxForm({
  form: "LoginPage",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: loginRequest,
});

export default withTranslation()(
  connect(mapStateToProps, {
    changeLogoState,
    setQrCode,
    setUserProfile,
    setUserOauth,
    sendAuthRequest,
    handleLogout,
    clearInfo,
    sendPhoneCodeCheck,
    checkForUser,
  })(withForm(LoginPage))
);
