import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import brand_amb from "./../../../resources/img/brand_amb.svg";
import service_amb from "./../../../resources/img/service_amb.svg";
import { changePrevUrl, changeLogoState } from "../../../actions/ui";
import "./index.css";
import { withTranslation } from "react-i18next";
import Zoom from "@mui/material/Zoom";
import { setUserProfile, checkForAuth } from "../../../actions/user";

function WelcomePage({ ...props }) {
  const { t } = props;

  useEffect(() => {
    props.changeLogoState(false);
    props.changePrevUrl();
    props.checkForAuth();
  }, []);
  const handleClick = (item) => {
    props.history.push(item);
  };
  return (
    <div className="login-data-container">
      <Zoom in timeout={300}>
        <div className="welcome-text">
          <h2>{t("home.welcome")}</h2>
          <h3>{t("home.please_select")}</h3>
        </div>
      </Zoom>
      <Zoom in timeout={300}>
        <div
          className="select-service"
          style={{ backgroundImage: `url(${brand_amb})` }}
          onClick={() => handleClick("/login/ambassador")}
        >
          <div className="select-service-container">
            <h3>{t("home.brand_ambassador")}</h3>
            <h4>
              <span>2034</span> {t("home.ambasadors_on_platform")}
            </h4>
          </div>
        </div>
      </Zoom>
      <Zoom in timeout={300}>
        <div
          className="select-service"
          style={{ backgroundImage: `url(${service_amb})` }}
          onClick={() => handleClick("/login/service")}
        >
          <div className="select-service-container">
            <h3>{t("home.service")}</h3>
            <h4>
              <span>3</span> {t("home.services_on_platform")}
            </h4>
          </div>
        </div>
      </Zoom>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default withTranslation()(
  connect(mapStateToProps, {
    changePrevUrl,
    changeLogoState,
    setUserProfile,
    checkForAuth,
  })(WelcomePage)
);
