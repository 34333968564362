import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import i18n from "i18next";
import "./index.css";

export default function Footer({ float }) {
  return (
    <footer>
      <div className="copyright">
        <Typography variant="caption" align="center" component="div">
          ©Copyright {new Date().getFullYear()} by Iurii Novosolov.
          {` ${i18n.t("copyright.All rights reserved")}.`}
          <br />
          <Link
            to="/privacy-policy"
            color="inherit"
            underline="always"
            component={RouterLink}
          >
            {` ${i18n.t("copyright.Privacy Policy")}`}
          </Link>
          {`. ${i18n.t("copyright.All copy prohibited the owner")}.`}
        </Typography>
      </div>
    </footer>
  );
}
