import React from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import ButtonBase from "@mui/material/ButtonBase";
import { getServiceContracts } from "../../../actions/service";
import { setFilterParams } from "../../../actions/ui";
import { withTranslation } from "react-i18next";

class FilterStatusModal extends React.Component {
  handleFilter = (value) => {
    let filter = this.props.filter;
    let index = this.props.filter.findIndex((el) => el.value === value);
    filter[index].active = !filter[index].active;
    this.props.setFilterParams(filter);
  };

  handleAccept = () => {
    this.props.closeRequestModal();
    this.props.getServiceContracts({
      limit: 10,
      offset: 0,
      role: 4,
    });
  };

  render() {
    const filter = this.props.ui && this.props.ui.filter;
    const { t } = this.props;
    return (
      <div className="modal-window">
        <div className="modal-window-info">
          <h2>{t("modal.Filter Client Status")}</h2>

          <div className="filters-container">
            {filter
              ? filter.map((el, index) => (
                  <ButtonBase
                    className={
                      el.active ? "filters-items active" : "filters-items"
                    }
                    onClick={() => this.handleFilter(el.value)}
                    key={index}
                  >
                    <div>{t(`statuses.${el.label}`)}</div>
                    <CheckIcon />
                  </ButtonBase>
                ))
              : null}
          </div>
        </div>
        <div className="modal-buttons-container">
          <Button
            className="modal-buttons no"
            onClick={this.props.closeRequestModal}
          >
            {t("modal.Close")}
          </Button>
          <Button
            className="modal-buttons yes orange"
            onClick={this.handleAccept}
          >
            {t("modal.Apply")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ui: state.ui, filter: state.ui && state.ui.filter };
};
export default withTranslation()(
  connect(mapStateToProps, {
    setFilterParams,
    getServiceContracts,
  })(FilterStatusModal)
);
