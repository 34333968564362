import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { sendAuthRequest } from "../../../actions/user";

import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { withTranslation } from "react-i18next";
import { getInviteData } from "../../../actions/invite";
import SimpleAgenda from "./simple-agenda";
import Agenda from "./agenda";

class BrandInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checked: false,
      service: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    if (this.props.user && this.props.user.qr) {
      const res = await getInviteData(this.props.user && this.props.user.qr);
      if (res && res.data && res.data.service) {
        this.setState({
          service: res,
        });
        return;
      }
    }
    this.setState({
      service: 0,
    });
  }

  handleMapSave = async () => {
    this.setState({
      loading: true,
    });
    await this.props.sendAuthRequest(
      {
        firstName: this.props.oauth.first_name,
        lastName: this.props.oauth.last_name,
        email: this.props.oauth.email,
        oauthId: this.props.oauth.id,
      },
      "ambassador",
      true
    );
  };
  render() {
    const { t } = this.props;
    return (
      <div className="white-data-section">
        {this.state.service !== 0 && this.state.service !== null && (
          <Agenda
            amb={this.props.oauth}
            info={this.props.info}
            service={this.state.service}
          />
        )}
        {this.state.service === 0 && <SimpleAgenda />}

        <FormGroup style={{ marginTop: "40px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checked}
                onClick={(e) => {
                  this.setState({ checked: !this.state.checked });
                }}
              />
            }
            label={
              <div className="checkbox-item">
                {t("AdvAgendaPage.accept")}{" "}
                <span>{t("AdvAgendaPage.conditions")}</span>
              </div>
            }
          />
        </FormGroup>
        <Box className="light center margin-large txt14">
          <Button
            className="orange rounded center"
            onClick={this.handleMapSave}
            disabled={!this.state.checked}
          >
            {this.state.loading ? (
              <CircularProgress size={23} style={{ color: "#fff" }} />
            ) : (
              `${t("brandInfoPage.submit")}`
            )}
          </Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    oauth: state.user && state.user.oauth,
    info: state.user && state.user.info && state.user.info.user,
    user: state.user,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { changeLogoState, sendAuthRequest })(BrandInfoPage)
);
