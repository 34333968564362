import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";

import "./index.css";

import facebook from "./../../resources/img/socials/facebook.svg";
import linkedin from "./../../resources/img/socials/linkedin.svg";
import telegram from "./../../resources/img/socials/telegram.svg";
import twitter from "./../../resources/img/socials/twitter.svg";
import viber from "./../../resources/img/socials/viber.svg";
import whatsapp from "./../../resources/img/socials/whatsapp.svg";

function ShareButtons(props) {
  const { url } = props;
  const size = 20;
  return (
    <div className="share-buttons">
      <FacebookShareButton url={url} size={size}>
        <img src={facebook} alt="facebook" />
      </FacebookShareButton>
      <TwitterShareButton url={url} size={size}>
        <img src={twitter} alt="twitter" />
      </TwitterShareButton>
      <LinkedinShareButton url={url} size={size}>
        <img src={linkedin} alt="linkedin" />
      </LinkedinShareButton>
      <WhatsappShareButton url={url} size={size}>
        <img src={whatsapp} alt="whatsapp" />
      </WhatsappShareButton>
      <TelegramShareButton url={url} size={size}>
        <img src={telegram} alt="telegram" />
      </TelegramShareButton>
      <ViberShareButton url={url} size={size}>
        <img src={viber} alt="viber" />
      </ViberShareButton>
    </div>
  );
}

export default ShareButtons;
