const def = {
  LOGIN: "@__set_email_password_get_profile",
  SETPROFILE: "@__set_profile_from_login",
  LOGOUT: "LOGOUT",
  RESUME: "@_resume_user_token",
  LANG: "@__switch_user_language",
  SET_INFO: "@__set_user_info",
  FULL_LOGOUT: "@_full_logout",
};

export default def;
