import Types from "../constants/ui";

export function setHeaderName(item = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.FILTER,
      ui: {
        header_name: item,
      },
    });
  };
}

export function noImage(item) {
  const fn = item.f;
  const ln = item.l;
  if (fn && ln) {
    return `${fn[0]}${ln[0]}`;
  } else if (fn && !ln) {
    return `${fn[0]}${fn[fn.length - 1]}`;
  } else if (!fn && ln) {
    return `${ln[0]}${fn[ln.length - 1]}`;
  }
}

export function setFilterParams(item = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.FILTER,
      ui: {
        filter: item,
      },
    });
  };
}

export function setParams(item = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.URL,
      ui: {
        params: item,
      },
    });
  };
}

export function changeLogoState(item = null) {
  return async (dispatch, getState) => {
    const { ui } = getState();
    const logo = item === null ? !ui.logo : item;
    dispatch({
      type: Types.LOGO,
      ui: {
        ...ui,
        logo: logo,
      },
    });
  };
}

export function changePrevUrl(item = "") {
  return async (dispatch, getState) => {
    const { ui } = getState();
    dispatch({
      type: Types.URL,
      ui: {
        ...ui,
        prevUrl: item,
      },
    });
  };
}
export function changeMenuState(item = false) {
  return async (dispatch, getState) => {
    let { ui } = getState();
    document.getElementById("root").className = item ? "root-menu-active" : "";
    dispatch({
      type: Types.URL,
      ui: {
        ...ui,
        menuAnimation: item,
      },
    });
    setTimeout(() => {
      let { ui } = getState();
      dispatch({
        type: Types.URL,
        ui: {
          ...ui,
          menu: item,
        },
      });
    }, 100);
  };
}
