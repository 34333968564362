import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import Zoom from "@mui/material/Zoom";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import bubbles from "./../../resources/img/bubbles.svg";
import ROUTES_BACK from "./constants/routerBack";
import WHITE_HEADER from "./constants/whiteHeaderLinks";
import LOGO_APEAR from "./constants/logoApear";
import HEADER_HEIGHT from "./constants/headerHeight";
import { changeMenuState, noImage } from "../../actions/ui";
// eslint-disable-next-line
import HeaderMenu from "./Menu";
import Icon from "@mui/material/Icon";
import burger from "./../../resources/img/icons/burger.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Button from "@mui/material/Button";
import payment_confirm_icon from "./../../resources/img/icons/payment-confirm.svg";
import open_box from "./../../resources/img/open-box.svg";
import filter from "./../../resources/img/filter.svg";
import { openModal } from "../../actions/modal";
import { openDrawler } from "../../actions/drawler";
import Skeleton from "@mui/material/Skeleton";
import LanguageSelect from "./LanguageSelect";
import { withTranslation } from "react-i18next";
import { setUserProfile } from "../../actions/user";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import SharedLogo from "../share/Logo";
import Badge from "@mui/material/Badge";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomIn: true,
      ammount: null,
      new_leads: null,
    };
  }

  componentDidMount() {
    if (window.swUpdateReady) {
      console.log("update ready!");
      // window.swUpdateReady = false;
      // window.stop();
      // window.location.reload();
    }
    this.colorBody();
    this.unlisten = this.props.history.listen((location, action) => {
      window.scrollTo(0, 0);

      this.setState({
        zoomIn: false,
        ammount: null,
        new_leads: null,
      });
      setTimeout(() => {
        // this.checkForAuth();
        this.colorBody();
      }, 10);
      setTimeout(() => {
        this.setState({
          zoomIn: true,
        });
      }, 100);
    });
  }

  checkForAuth() {
    if (
      this.props.loggedin &&
      this.props.role_id !== null &&
      !this.props.protected
    ) {
      const schema = {
        0: "/service/home",
        1: "/home",
        2: "/user/home",
      };
      this.props.history.push(schema[this.props.role_id]);
    }
  }

  getCorrectParams() {
    if (this.props.history.location.pathname.includes("/service/reg")) {
      return this.props.history.location.pathname;
    }
    const params = Object.keys(this.props.match.params);
    let pathname = !!params.length
      ? `${this.props.history.location.pathname}${params
          .map((el) => `/:${el}`)
          .join("")}`
      : this.props.history.location.pathname;
    if (!!params) {
      for (let item of Object.values(this.props.match.params)) {
        pathname = pathname.replace(`/${item}`, "");
      }
    }
    return pathname;
  }

  handleShareClick = (item) => {
    if (!this.props.menu) {
      this.props.openDrawler({
        title: item.title,
        icon: item.icon,
        role: this.props.role_id,
        qr_type: 1,
        grant_role: 3,
      });
    }
  };

  colorBody() {
    const pathname = this.getCorrectParams();
    const whiteHeader = WHITE_HEADER.includes(pathname);
    const activeService =
      this.props.services &&
      this.props.services.length &&
      this.props.services.find((el) => el.service.active === true);
    document.getElementById("io").className = !whiteHeader ? "white" : "";
    if (pathname === "/service/home" && activeService) {
      setTimeout(() => {
        this.setState({
          new_leads: `${
            this.props.contracts.filter((el) =>
              this.isToday(new Date(el.lead.created))
            ).length
          }`,
        });
      }, 500);
    }
  }

  isToday(someDate) {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }
  componentWillUnmount() {
    // if (this.unlisten) this.unlisten();
  }

  handleMenuOpen = () => {
    this.props.changeMenuState(!this.props.menu);
  };
  handleWithdrow = () => {
    this.props.history.push("/withdraw/payment-confirm");
  };

  handleFilter = () => {
    this.props.openModal({
      type: "FilterStatusModal",
    });
  };
  render() {
    let pathname = this.getCorrectParams();
    const whiteHeader = WHITE_HEADER.includes(pathname);
    const logoApear = LOGO_APEAR.includes(pathname);
    const logo = HEADER_HEIGHT[pathname];
    const handleClick = () => {
      if (
        (this.props.history.location.pathname === "/edit" ||
          this.props.history.location.pathname ===
            "/withdraw/payment-confirm") &&
        this.props.role_id === 3
      ) {
        this.props.history.push("/service/home");
        return;
      }
      const pathnameHome =
        this.props.history.location.pathname === "/user/home";
      let route = ROUTES_BACK[pathname];
      if (pathnameHome) {
        this.props.setUserProfile();
        return;
      }
      if (route) {
        if (route.includes("!")) {
          for (let item of route.split("!").slice(1, 999)) {
            if (item === "service") {
              route = route.replace(
                `!${item}`,
                `/${this.props.paramsUi.soroban}`
              );
            } else if (item === "id") {
              route = route.replace(`!${item}`, `/${this.props.paramsUi.id}`);
            } else {
              route = route.replace(
                `!${item}`,
                `/${this.props.match.params[item]}`
              );
            }
          }
        }
        this.props.history.push(route);
      }
    };
    pathname = pathname.replaceAll(":", "");
    const { i18n } = this.props;
    const withdraw =
      this.props.payments &&
      Object.values(this.props.payments).filter(
        (el) => el.sum > el.min_withdraw
      );
    let ammount = null;
    if (withdraw && withdraw.length) {
      ammount = withdraw
        .map((el) => el.payments)[0]
        .map((el) => (el.complete_am === null ? el.credited_amount : 0))
        .reduce((prev, next) => prev + next);
    }
    const payment_confirm = pathname === "/withdraw/payment-confirm";
    const sign_up = pathname === "/invite/id";
    const service = this.state.new_leads;
    const processHeaderHeight = () => {
      const values = !!(ammount || payment_confirm || sign_up || service);
      if (logo && values) return logo.data_header;
      return logo ? logo.header : whiteHeader ? "125px" : "90px";
    };

    const processContainerHeight = () => {
      const values = !!(ammount || payment_confirm || sign_up || service);
      if (logo && values) return logo.data_container;
      return logo ? logo.container : whiteHeader ? "70px" : "110px";
    };
    const go_back_name = i18n.t(`routes.${pathname}`);
    const header_name = this.props.ui && this.props.ui.header_name;
    const { t } = this.props;
    const activeService =
      (this.props.role_id === 0 || this.props.role_id === 3) &&
      this.props.services &&
      this.props.services.length &&
      this.props.services.find((el) => el.service.active === true).service;
    return (
      <>
        <header
          style={{
            height: processHeaderHeight(),
          }}
        >
          <div
            className="top-containers"
            style={{
              position: "absolute",
              width: "100%",
              height: processContainerHeight(),
              background: !whiteHeader
                ? "linear-gradient(132.08deg, #52b797 17.89%, #eeff87 120.86%)"
                : "#fff",
              boxShadow: whiteHeader ? "0px 4px 15px rgba(0, 0, 0, 0.06)" : "",
              borderRadius: this.props.drawler.open
                ? "15px 15px 15px 15px"
                : whiteHeader
                ? "0px 0px 15px 15px"
                : "",
            }}
          >
            {!whiteHeader ? (
              <div
                className="bubbles"
                style={{ backgroundImage: `url(${bubbles})` }}
              ></div>
            ) : null}
            {pathname === "/home" || pathname === "/service/home" ? (
              <div className="logo burger" onClick={this.handleMenuOpen}>
                <Icon>
                  <img src={burger} height={25} width={25} alt="burger" />
                </Icon>
              </div>
            ) : (
              <Zoom in={this.state.zoomIn}>
                <Button
                  className={`logo${logoApear && whiteHeader ? " green" : ""}`}
                  sx={{ textTransform: "initial" }}
                  style={{ marginTop: logoApear ? "-8px" : "4px" }}
                  onClick={handleClick}
                >
                  {logoApear ? (
                    <SharedLogo size="30" />
                  ) : (
                    <div
                      className={`go-back-icon txt14 light${
                        whiteHeader ? " black" : ""
                      }`}
                    >
                      <div className="icon-back">
                        <ArrowBackIosOutlinedIcon />
                      </div>
                      {!go_back_name.includes("./") ? (
                        go_back_name
                      ) : header_name ? (
                        header_name
                      ) : (
                        <Skeleton animation="wave" width={100} />
                      )}
                    </div>
                  )}
                </Button>
              </Zoom>
            )}

            {pathname === "/home" || pathname === "/service/home" ? (
              <div className="logo center" style={{ marginTop: "-11px" }}>
                <SharedLogo />
              </div>
            ) : null}
            <div className="settings">
              {pathname === "/service/home" ? (
                <div className="filter" onClick={this.handleFilter}>
                  <Badge
                    badgeContent={
                      this.props.ui &&
                      this.props.ui.filter.filter((el) => el.active).length
                    }
                    color="primary"
                  >
                    <Icon>
                      <img src={filter} height={25} width={25} alt="filter" />
                    </Icon>
                  </Badge>
                </div>
              ) : null}
              {pathname.includes("/service/reg") ? (
                <CircularProgressWithLabel
                  variant="determinate"
                  value={this.props.match.params.page * 20}
                  progressCount={`${this.props.match.params.page}/5`}
                  className="reg-progress"
                />
              ) : (
                <LanguageSelect {...this.props} />
              )}
            </div>
          </div>
          <div
            className="header-bottom-data"
            style={{ opacity: pathname.includes("login") && logo ? "1" : "0" }}
          >
            <SharedLogo />
          </div>
          {pathname === "/home" && ammount ? (
            <div className="header-bottom-info">
              <div className="info-left">
                <h2>{t("brands.amount_avaiable")}</h2>
                <h3>{ammount}</h3>
              </div>
              <div className="info-right bottom">
                <Button
                  variant="contained"
                  endIcon={<ArrowRightAltIcon />}
                  onClick={this.handleWithdrow}
                >
                  {t("withdrawContainer.withdraw")}
                </Button>
              </div>
            </div>
          ) : null}
          {payment_confirm ? (
            <div className="header-bottom-info">
              <div className="info-left payments">
                <img src={payment_confirm_icon} alt="payment_confirm_icon" />
              </div>
              <div className="info-left text">
                {t("homeServicePage.please_swipe")}
              </div>
            </div>
          ) : null}
          {sign_up ? (
            <div className="header-bottom-info open-box">
              <div className="info-left text">
                {t("homeServicePage.Sign Up and")}
                <br />
                {t("homeServicePage.Get Great Discounts")}
              </div>
              <div className="info-left">
                <img src={open_box} alt="open_box" />
              </div>
            </div>
          ) : null}
          {service && activeService ? (
            <div
              className="header-bottom-info open-box"
              onClick={() =>
                this.handleShareClick({
                  title: activeService.public_name,
                  icon: activeService.logo_url,
                })
              }
            >
              <div className="info-left list">
                <h3>{activeService.public_name}</h3>
                <h5>
                  {`${t("homeServicePage.Today You Have")} `}
                  <span>{this.state.new_leads}</span>
                  {` ${t("homeServicePage.New Leads")}`}
                </h5>
              </div>
              <div className="info-left">
                {activeService.logo_url ? (
                  <img src={activeService.logo_url} alt="service_logo" />
                ) : (
                  <div className="no-img">
                    {noImage({ f: activeService.public_name })}
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedin: state.user && state.user.loggedin,
    logo: state.ui && state.ui.logo,
    prevUrl: state.ui && state.ui.prevUrl,
    menu: state.ui && state.ui.menu,
    paramsUi: state.ui && state.ui.params,
    drawler: state.drawler,
    ui: state.ui,
    role_id: state.user && state.user.role_id,
    services: state.user && state.user.services,
    user: state.user,
    contracts: state.contracts && state.contracts.list,
    payments: state.payments && state.payments.list,
  };
};
export default withTranslation()(
  connect(mapStateToProps, {
    changeMenuState,
    openModal,
    openDrawler,
    setUserProfile,
  })(Header)
);
