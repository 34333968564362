// eslint-disable-next-line
import { ApiConnector, GET, POST } from "./../request.service";
export default (() => {
  const API = ApiConnector("/auth");
  return {
    SendAuthRequest: async (data) => await API(POST, "").call(data),
    PhoneCheck: async (data) => await API(POST, "/phone").call(data),
    PhoneCodeCheck: async (data) => await API(POST, "/phone/code").call(data),
  };
})();
