import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { reduxForm, Form, Field, change } from "redux-form";
import onSubmit from "../../onSubmit";
import { editUserProfile } from "../../../actions/user";
import phone from "./../../../resources/img/icons/phone.svg";
import Icon from "@mui/material/Icon";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneInput from "react-phone-number-input";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import store from "../../../store";

import { withTranslation } from "react-i18next";
import {
  updateUserTimer,
  sendPhoneCheck,
  setUserProfile,
  sendPhoneCodeCheck,
} from "../../../actions/user";
import { toogleSnackbar } from "../../../actions/modal";

class PhoneVerificationUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      verification: {
        0: "",
        1: "",
        2: "",
        3: "",
      },
      phoneCheck: true,
      interval: null,
    };
  }
  componentDidMount() {
    this.props.initialize({
      phone_number: this.props.service.phone,
    });
    // this.processPhoneCheck();
    this.props.updateUserTimer(60);
    this.startTimer();
    this.setState({ phoneCheck: true });
    this.props.changeLogoState(true);
    if (this.props.user.info && this.props.user.info.code) {
      this.setState({
        verification: {
          0: this.props.user.info.code.split("")[0],
          1: this.props.user.info.code.split("")[1],
          2: this.props.user.info.code.split("")[2],
          3: this.props.user.info.code.split("")[3],
        },
      });
    }
  }

  async processPhoneCheck() {
    if (
      (this.props.timer === null ||
        +this.props.timer === -1 ||
        +this.props.timer > -1) &&
      this.props.user &&
      this.props.user.info &&
      this.props.user.info.user_seq
    ) {
      const res = await this.props.sendPhoneCheck({
        phone: this.props.service.phone.replaceAll(" ", ""),
        user_seq:
          this.props.user &&
          this.props.user.info &&
          this.props.user.info.user_seq,
      });
      if (
        res &&
        res.error &&
        res.error.message &&
        res.error.message === "PHONE_USED"
      ) {
        this.props.history.push("/user/home");
      }

      if (res && res.data) {
        if (res.data.user_seq) {
          this.props.setUserProfile({
            info: {
              ...this.props.user.info,
              ...res.data,
            },
          });
        }
        if (this.props.timer === null || +this.props.timer === -1)
          this.props.updateUserTimer(60);
        this.startTimer();
        this.setState({ phoneCheck: true });
      }
    }
  }

  handleCodeCheck = async () => {
    this.setState({ loading: true });
    const res = await this.props.sendPhoneCodeCheck({
      user_seq:
        this.props.user &&
        this.props.user.info &&
        this.props.user.info.user &&
        this.props.user.info.user.seq,
      code: Object.values(this.state.verification).join(""),
    });
    if (res && res.error && res.error.message === "CODE_INVALID") {
      this.props.toogleSnackbar({
        open: true,
        message: "CODE_INVALID",
      });
      this.setState({
        verification: {
          0: "",
          1: "",
          2: "",
          3: "",
        },
      });
    }
    if (
      (res && res.data && res.data) ||
      (res && res.error && res.error.message === "USER_ACTIVATED")
    ) {
      this.props.setUserProfile({ loggedin: true, role_id: 2 });
      setTimeout(() => {
        this.props.history.push("/user/home");
      }, 1000);
    }
    this.setState({
      loading: false,
    });
  };

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  onChangeVerification = (e) => {
    const id = e.target.name.split("_")[e.target.name.split("_").length - 1];
    this.setState({
      debug: e.target.value,
    });
    if (e.target.value.length === 4) {
      const splitted = e.target.value.split("");
      this.setState({
        verification: {
          0: splitted[0],
          1: splitted[1],
          2: splitted[2],
          3: splitted[3],
        },
      });
      return;
    }
    this.setState({
      verification: {
        ...this.state.verification,
        [id]: e.target.value[e.target.value.length - 1],
      },
    });
    const nextId = e.target.value ? +id + 1 : id - 1;
    if (nextId >= 0 && nextId <= 3) {
      const nextElement = document.getElementById(`verific_code${nextId}`);
      setTimeout(() => {
        nextElement.focus();
      }, 50);
    }
  };

  startTimer() {
    if (this.props.timer <= 0) this.props.updateUserTimer(60);
    const interval = setInterval(() => {
      if (this.props.timer <= 0) {
        clearInterval(this.state.interval);
        this.props.updateUserTimer(0);
      }
      this.props.updateUserTimer(this.props.timer - 1);
    }, 1000);
    this.setState({
      interval,
    });
  }

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    if (input.value) console.log(input.value);
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  renderPhoneMask = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="UA"
        limitMaxLength
        {...input}
        {...custom}
      />
    );
  };

  render() {
    const { t } = this.props;
    const form = this.props.EditUserPage;
    const phoneNumber = this.props.service.phone || "";
    return (
      <div className="login-data-container">
        <div className="welcome-text center">
          <h2>Верифiкацiя телефону</h2>
          {this.state.phoneCheck ? (
            <h3 className="phone-check">
              {t("reg.message_send_on_phone")}

              <br />
              <span>{`${phoneNumber.substr(0, 6)}*****${phoneNumber.substr(
                11,
                13
              )}`}</span>
            </h3>
          ) : (
            <h3>Введiть свiй телефон щоб продовжити</h3>
          )}
        </div>

        <div className="select-auth-method">
          <Form onSubmit={this.props.handleSubmit}>
            {!this.state.phoneCheck ? (
              <div className="row item full">
                <Field
                  name="phone_number"
                  component={this.renderPhoneMask}
                  placeholder={t("reg.Phone Number")}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <img src={phone} height={25} width={25} alt="phone" />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            ) : null}

            {this.state.phoneCheck ? (
              <div className="code_confirm">
                {[0, 1, 2, 3].map((el) => {
                  return (
                    <div className="code" key={el}>
                      <TextField
                        key={el}
                        onChange={this.onChangeVerification}
                        margin="dense"
                        name={`verific_code_${el}`}
                        id={`verific_code${el}`}
                        value={this.state.verification[el]}
                        type="number"
                        variant="outlined"
                        className="phoneVerification"
                        inputProps={{
                          autoComplete: "one-time-code",
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}

            <div className="timer">
              {this.props.timer > 0
                ? `00:${
                    this.props.timer < 10
                      ? "0" + this.props.timer
                      : this.props.timer
                  }`
                : null}
            </div>
            {this.state.phoneCheck ? (
              <h4
                className={`resend-code${
                  this.props.timer > 0 ? " disabled" : ""
                }`}
              >
                Didn't receive code?{" "}
                <span onClick={() => this.processPhoneCheck()}>Repeat</span>
              </h4>
            ) : null}
            <Box className="light center margin-large txt14">
              {this.state.phoneCheck ? (
                <Button
                  className="orange rounded center"
                  onClick={this.handleCodeCheck}
                  disabled={
                    Object.values(this.state.verification).join("").length !== 4
                  }
                >
                  {this.state.loading ? (
                    <CircularProgress size={23} style={{ color: "#fff" }} />
                  ) : (
                    `${t("modal.Verify")}`
                  )}
                </Button>
              ) : (
                <Button
                  className="orange rounded center"
                  onClick={() => {
                    if (!this.state.phoneCheck) {
                      this.startTimer();
                    }
                    this.setState({ phoneCheck: true });
                  }}
                  disabled={
                    !!(form && form.syncErrors && form.syncErrors.phone_number)
                  }
                >
                  {this.state.loading ? (
                    <CircularProgress size={23} style={{ color: "#fff" }} />
                  ) : (
                    `${t("modal.Continue")}`
                  )}
                </Button>
              )}
            </Box>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    EditUserPage: state.form && state.form.PhoneVerifService,
    timer: state.user && state.user.smsTimer,
    service: state.user && state.user.service,
    info: state.user && state.user.info && state.user.info.user,
    reg_start: state.user && state.user.reg_start,
    user: state.user,
  };
};

const validate = (values) => {
  const errors = {};
  if (values.phone_number && values.phone_number.includes("_")) {
    errors.phone_number = "Incorrect prone number";
  }
  return errors;
};

const withForm = reduxForm({
  form: "PhoneVerifService",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: editUserProfile,
});

export default withTranslation()(
  connect(mapStateToProps, {
    changeLogoState,
    updateUserTimer,
    sendPhoneCheck,
    sendPhoneCodeCheck,
    toogleSnackbar,
    setUserProfile,
  })(withForm(PhoneVerificationUser))
);
