import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../actions/ui";
import InfiniteScroll from "react-infinite-scroll-component";

import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import calendar from "./../../resources/img/calendar.svg";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import STATUSES from "../../constants/statuses";
import Button from "@mui/material/Button";
import { withTranslation } from "react-i18next";
import { changeClentStatus } from "../../actions/service";
import Fade from "@mui/material/Fade";
import { getServiceContracts } from "../../actions/service";
import { confirmServicePayment } from "../../actions/payments";
import Skeleton from "@mui/material/Skeleton";

const schema = {
  2: "menu.Workers",
  3: "ambassador.title",
  4: "customers.user",
};
class CustomersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: this.props.customers,
    };
  }

  handleLikeClick = (index) => {
    let customers = this.state.customers;
    customers[index].liked = !customers[index].liked;
    this.setState({
      customers,
    });
  };

  componentDidMount() {
    this.props.changeLogoState(false);
  }

  handleSinglePage = (id, role) => {
    if (this.props.role_id === 0 && role === 4) {
      this.props.history.push(`/service/user/${id}`);
    }
  };

  processPayDay = (value) => {
    if (+value) value = +value;
    let d = new Date(value);

    const _min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    const _hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
    const _month =
      d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
    const _date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();

    const date = `${_date}.${_month}.${d.getFullYear()} `;
    const minutes = `${_hours}:${_min}`;
    const output = date + minutes;
    return output;
  };

  handleConfirmPayment = () => {
    confirmServicePayment();
  };

  render() {
    const processIconNull = (item) => {
      const fn = item.first_name;
      const ln = item.last_name;
      if (fn && ln) {
        return `${fn[0]}${ln[0]}`;
      } else if (fn && !ln) {
        return `${fn[0]}${fn[fn.length - 1]}`;
      } else if (!fn && ln) {
        return `${ln[0]}${fn[ln.length - 1]}`;
      }
    };
    const { t } = this.props;
    const contracts = this.props.contracts && this.props.contracts.list;

    return (
      (contracts && (
        <InfiniteScroll
          dataLength={contracts && contracts.length}
          hasMore={contracts.length < this.props.contracts.count}
          next={() =>
            this.props.getServiceContracts({
              limit: this.props.contracts.limit + 10,
            })
          }
          loader={
            contracts.length < this.props.contracts.count && (
              <Skeleton
                variant="rect"
                animation="wave"
                width={"100%"}
                height={215}
                style={{ marginBottom: "100px", borderRadius: "15px" }}
              />
            )
          }
        >
          {contracts.map((el, index) => {
            return (
              <Fade key={index} in>
                <Box className="brands-list" sx={{ flexGrow: 1 }}>
                  <div className="top">
                    <div
                      className="brand-icon-title"
                      onClick={() => this.handleSinglePage(el.lead.id, el.role)}
                    >
                      <div className="brand-icon small">
                        {!el.logo ? processIconNull(el.lead) : null}
                      </div>
                      <div className="users-name" style={{ textAlign: "left" }}>
                        <h4 className="first_name">
                          {el.lead.first_name} {el.lead.last_name}
                        </h4>
                        <h4 className="last_name">{t(schema[el.role])}</h4>
                      </div>
                    </div>
                    {(el.ambassador.first_name || el.ambassador.last_name) && (
                      <div className="brand-icon-title">
                        <div
                          className="users-name"
                          style={{ textAlign: "right" }}
                        >
                          <h4 className="first_name">
                            {el.ambassador.first_name} {el.ambassador.last_name}
                          </h4>
                          <h4 className="last_name">
                            {t(schema[el.creater_role])}
                          </h4>
                        </div>
                        <div className="brand-icon small">
                          {!el.logo ? processIconNull(el.ambassador) : null}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="pay-day">
                    <div className="pay-day-icon">
                      <Icon>
                        <img
                          src={calendar}
                          width="25"
                          height="25"
                          alt="calendar"
                        />
                      </Icon>
                      <h3>
                        {t(
                          `customers.${
                            (el.role < 4 && "created") || "status_change_time"
                          }`
                        )}
                      </h3>
                    </div>
                    <h3>
                      {this.processPayDay(
                        el.client_status_changed || el.pay_day
                      )}
                    </h3>
                  </div>
                  {(el.status && (
                    <div className="buttons">
                      <FormControl
                        sx={{ width: "100%" }}
                        className={`select-status disabled ${
                          STATUSES.find((item) => item.value === el.status)
                            .label
                        }`}
                        onClick={(e) =>
                          this.handleSinglePage(el.lead.id, el.role)
                        }
                      >
                        <Select
                          value={el.status}
                          input={
                            <OutlinedInput
                              className={
                                this.props.role_id === 3 ? "worker" : ""
                              }
                            />
                          }
                        >
                          {STATUSES.map((element, index) => {
                            return (
                              <MenuItem
                                value={element.value}
                                key={index}
                                disabled={
                                  element.disabled ||
                                  (el.status === 3 && element.value === 2) ||
                                  el.status === 4
                                }
                              >
                                {t(`statuses.${element.label}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {false && (
                        <Button
                          className="confirm-payment"
                          disabled={el.status === 1}
                          onClick={() => this.handleConfirmPayment(el)}
                        >
                          {t("customers.confirm_payment")}
                        </Button>
                      )}
                    </div>
                  )) ||
                    null}
                </Box>
              </Fade>
            );
          })}
        </InfiniteScroll>
      )) ||
      null
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.user && state.user.services,
    contracts: state.contracts && state.contracts,
    role_id: state.user && state.user.role_id,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    changeLogoState,
    changeClentStatus,
    getServiceContracts,
  })(CustomersComponent)
);
