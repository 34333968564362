import React from "react";

function agendaTemplate() {
  return (
    <div className="agenda-adv-data">
    <p align="center" >
<font face="Times New Roman, serif"><span lang="uk-UA"><b>ПРАВИЛА
ТА УМОВИ ВИКОРИСТАННЯ </b></span></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><b><span >WEB-ДОДАТКА
</span></b></span></font></font><font face="Times New Roman, serif"><span lang="uk-UA"><b>«</b></span></font><font face="Times New Roman, serif"><span lang="uk-UA"><b>AMBASERA</b></span></font><font face="Times New Roman, serif"><span lang="uk-UA"><b>»</b></span></font></p>
<p lang="uk-UA" align="center" >
<br/>

</p>
<ol>
	<li><p ><font face="Times New Roman, serif"><span lang="uk-UA">Терміни,
	що використовуються</span></font></p>
	</li><li><p ><font face="Times New Roman, serif"><span lang="uk-UA">Правила
	та умови, пов'язані з реєстрацією
	Користувача.</span></font></p>
	</li><li><p ><font face="Times New Roman, serif"><span lang="uk-UA">Правила
	запрошення Амбасадора.</span></font></p>
	</li><li><p ><font face="Times New Roman, serif"><span lang="uk-UA">Права
	та обов'язки Користувача.</span></font></p>
	</li><li><p ><font face="Times New Roman, serif"><span lang="uk-UA">Права
	та обов'язки Адміністрації web-додатку
	«Ambasera»</span></font></p>
	</li><li><p ><font face="Times New Roman, serif"><span lang="uk-UA">Обмеження
	та заборони.</span></font></p>
	</li><li><p ><font face="Times New Roman, serif"><span lang="uk-UA">Відповідальність.
	Обмеження відповідальності.</span></font></p>
</li></ol>
<p lang="uk-UA" align="justify" >
<br/>

</p>
<ol>
	<li><p align="justify" >
	<font face="Times New Roman, serif"><span lang="uk-UA"><b>ТЕРМІНИ,
	ЩО ВИКОРИСТОВУЮТЬСЯ</b></span></font></p>
	<ol>
		<li><p align="justify" >
		<font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><b><span >Web-додаток
		«Ambasera»</span></b></span></font></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><span >
		– партнерська реферальна програма
		для взаємодії Користувача та Амбасадора
		для залучення клієнтів до послуг
		Користувача </span></span></font></font><font face="Times New Roman, serif"><span lang="uk-UA">за
		посиланням </span></font><font face="Times New Roman, serif"><span lang="uk-UA">https://ambasera.com/</span></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><span >.</span></span></font></font></p>
		</li><li><p align="justify" >
		<font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><b><span >Користувач</span></b></span></font></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><span >
		– юридична особа або ФОП, що пропонує
		укласти Публічний договір приєднання
		з метою залучення нових клієнтів для
		надання власних послуг (наприклад,
		послуг в сфері розвитку дітей).</span></span></font></font></p>
		</li><li><p align="justify" >
		<font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><b><span >Амбасадор</span></b></span></font></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><span >
		</span></span></font></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><b><span >Користувача</span></b></span></font></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><span >
		– фізична особа, яка акцептувала
		Публічний договір приєднання та
		отримала персональний QR-код для
		залучення нових клієнтів:</span></span></font></font></p>
		<ol>
			<li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA"><b>Амбасадор-клієнт</b></span></font></font><font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">
			– </span></font></font><font face="Times New Roman, serif"><span lang="uk-UA">батьки
			учня, який вже користується послугами
			Користувача.</span></font></p>
			</li><li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA"><b>Зовнішній
			Амбасадор</b></span></font></font><font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">
			– </span></font></font><font face="Times New Roman, serif"><span lang="uk-UA">будь-яка
			третя повнолітня особа, яка не має
			відношення до послуг Користувача.</span></font></p>
			</li><li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA"><b>Амбасадор-співробітник</b></span></font></font><font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">
			– співробітник Користувача.</span></font></font></p>
		</li></ol>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA"><b>Авторизація</b></span></font><font face="Times New Roman, serif"><span lang="uk-UA">
		– вхід в особистий кабінет Користувача,
		шляхом використання google id або apple id для
		переходу у web-додаток «Ambasera».</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA"><b>Адміністрація
		web-додатку «Ambasera»</b></span></font><font face="Times New Roman, serif"><span lang="uk-UA">
		– команда розробників</span></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><span >
		web-додатку, що здійснюють управління,
		виконують технічні, організаційні та
		інші функції щодо забезпечення
		функціонування web-додатку «Ambasera», а
		також надають інформаційну, консультаційну
		та іншу підтримку Користувачам.</span></span></font></font></p>
		</li><li><p align="justify" >
		<b><font color="#333333"><font face="Times New Roman, serif"><font size="3" ><span lang="uk-UA"><span >Акаунт
		</span></span></font></font></font></b><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><span >–
		</span></span></font></font><font color="#333333"><font face="Times New Roman, serif"><font size="3" ><span lang="uk-UA"><span ><span >обліковий</span></span></span></font></font></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><span >
		запис, що містить відомості, які
		Користувач повідомив про себе
		Адміністрації в процесі проходження
		реєстрації у web-додатку «Ambasera».</span></span></font></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA"><b>QR код</b></span></font><font face="Times New Roman, serif"><span lang="uk-UA">
		– персональне посилання на web-додаток
		«Ambasera» з унікальним номером, що дозволяє
		визначити (ідентифікувати)
		Користувача/Амбасадора. </span></font>
		</p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA"><b>Реферал</b></span></font><font face="Times New Roman, serif"><span lang="uk-UA">
		– новий клієнт Користувача, залучений
		Амбасадором. </span></font>
		</p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA"><b>Клієнт
		</b></span></font><font face="Times New Roman, serif"><span lang="uk-UA">–
		новий Реферал, залучений Амбасадором,
		за яким можна визначити безпосередньо
		самого Амбасадора, який здійснив оплату
		за послуги Користувача. </span></font>
		</p>
	</li></ol>
</li></ol>
<p lang="uk-UA" align="justify" >
<br/>

</p>
<ol start="2">
	<li><p align="justify" >
	<font face="Times New Roman, serif"><span lang="uk-UA"><b>ПРАВИЛА
	ТА УМОВИ, ПОВ'ЯЗАНІ З РЕЄСТРАЦІЄЮ
	КОРИСТУВАЧА</b></span></font></p>
	<ol>
		<li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Реєстрація
		Користувача у web-додатку «Ambasera» є
		безкоштовною, добровільною та
		здійснюється за адресою в мережі
		Інтернет за посиланням https://ambasera.com/
		або за запрошенням через QR-код.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA"><span >Для
		реєстрації у </span></span></font><font color="#333333"><font face="Times New Roman, serif"><span lang="uk-UA"><span >web-додатку
		«Ambasera»</span></span></font></font><font face="Times New Roman, serif"><span lang="uk-UA"><span >
		Користувач повинен в</span></span></font><font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">нести
		дані своєї компанії:</span></font></font></p>
		<ol>
			<li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">номер
			телефону особи, яка реєструє послугу;</span></font></font></p>
			</li><li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">E-mail</span></font></font><font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">;
			</span></font></font>
			</p>
			</li><li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">публічне
			найменування;</span></font></font></p>
			</li><li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">логотип;
			</span></font></font>
			</p>
			</li><li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">пройти
			верифікацію телефону за допомогою
			смс-підтвердження;</span></font></font></p>
			</li><li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">вибрати
			країну присутності.</span></font></font></p>
		</li></ol>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Використання
		функціональних можливостей web-додатку
		«Ambasera» допускається лише після:</span></font></p>
		<ol>
			<li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">авторизації
			у web-додатку «Ambasera»; </span></font>
			</p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">заповнення
			Користувачем Заявки;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">створення
			Акаунту відповідно до встановленої
			Адміністрацією web-додатку «Ambasera»
			процедури.</span></font></p>
		</li></ol>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">В Заяві
		зазначається наступна інформація щодо
		компанії, яка надає послугу: </span></font>
		</p>
		<ol>
			<li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">юридична
			назва компанії; </span></font>
			</p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">адреса
			реєстрації компанії;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">ідентифікаційний
			код компанії; </span></font>
			</p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">номер
			телефону;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">посилання
			на відео щодо компанії;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">додаткова
			інформація про компанію.</span></font></p>
		</li></ol>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Далі
		вноситься фінансова інформація: </span></font>
		</p>
		<ol>
			<li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">вказується
			валюта платежів;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">розмір
			винагороди Амбасадора-клієнта за
			залучення нового Клієнту – споживача
			послуг Користувача;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">розмір
			винагороди зовнішньому Амбасадору
			за залучення нового Клієнту – споживача
			послуг Користувача;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">розмір
			винагороди Амбасадора-співробітника
			Користувача за нового Амбасадора,
			який залучив нового Клієнта – споживача
			послуг Користувача;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">розмір
			вітальної винагороди для нового
			Амбасадора, </span></font>
			</p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">розмір
			знижки для нового залученого Клієнта,
			</span></font>
			</p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">розмір
			мінімальної суми зняття коштів.</span></font></p>
		</li></ol>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Після
		цього вноситься інформація, щодо
		директора/засновника компанії</span></font></p>
		<ol>
			<li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA"><span >номер
			телефону;</span></span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA"><span >ПІБ;</span></span></font></p>
			</li><li><p align="justify" >
			<font color="#000000"><font face="Times New Roman, serif"><span lang="uk-UA">E-mail</span></font></font><font face="Times New Roman, serif"><span lang="uk-UA"><span >.</span></span></font></p>
		</li></ol>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA"><span >У
		заявці має бути зазначена повна, точна
		та достовірна інформація. Відповідальність
		за повноту, правильність та достовірність
		інформації та даних, викладених у
		заявці, несе Користувач. </span></span></font>
		</p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Персональні
		дані Користувача містяться в Акаунті
		Користувача, зберігаються та обробляються
		Адміністрацією web-додатку «Ambasera»
		відповідно до умов Політики
		конфіденційності.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Користувач
		надає згоду Адміністрації web-додатку
		«Ambasera» здійснювати обробку його
		персональних даних та інших даних, які
		Користувач надає Адміністрації
		web-додатку «Ambasera» шляхом їх розміщення
		у такому web-додатку, для досягнення
		цілей функціонування web-додатку. </span></font>
		</p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Використання
		web-додатку «Ambasera» можливо лише в рамках
		своїх функціональних можливостей: </span></font>
		</p>
		<ol>
			<li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">запрошення
			Амбасадора до співпраці;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">зміна
			статусу залученого Реферала/Клієнта
			Амбасадора, якщо в цьому є необхідність;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">перегляд
			інформації по Амбасадору та Клієнту
			/Рефералу.</span></font></p>
		</li></ol>
	</li></ol>
</li></ol>
<p lang="uk-UA" align="justify" >
<br/>

</p>
<ol start="3">
	<li><p align="justify" >
	<font face="Times New Roman, serif"><span lang="uk-UA"><b>ПРАВИЛА
	ЗАПРОШЕННЯ ТА РЕЄСТРАЦІЯ АМБАСАДОРА</b></span></font></p>
	<ol>
		<li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Амбасадором
		web-додатку «Ambasera» може бути будь яка
		фізична особа, яка проходить реєстрацію
		та дає згоду на оброблення персональних
		даних. </span></font>
		</p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Запрошуючи
		до співпраці Амбасадора, Користувач
		пропонує умови, які були зазначені у
		Заявці, яку заповнював Користувач.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Запрошення
		здійснюється за допомогою QR-коду, що
		згенерований web-додатком «Ambasera» у
		особистому кабінеті Користувача.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Після
		отримання QR-коду Амбасадор повинен:</span></font></p>
		<ol>
			<li><p align="justify" >
			 <font face="Times New Roman, serif"><span lang="uk-UA">пройти
			авторизацію за допомогою google id або
			apple id;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">вибрати
			місце знаходження;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">пройти
			верифікацію телефону за допомогою
			смс-підтвердження;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">підписати
			публічний договір приєднання.</span></font></p>
		</li></ol>
	</li></ol>
</li></ol>
<p lang="uk-UA" align="justify" >
<br/>

</p>
<ol start="4">
	<li><p align="justify" >
	<font face="Times New Roman, serif"><span lang="uk-UA"><b>ПРАВА
	ТА ОБОВ'ЯЗКИ КОРИСТУВАЧА</b></span></font></p>
	<ol>
		<li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Користувач
		має право:</span></font></p>
		<ol>
			<li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">Використовувати
			web-додаток «Ambasera» виключно з метою
			особистого використання відповідно
			до даних Правил та умов.</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">Безоплатно
			завантажувати і користуватися повним
			функціоналом web-додатку «Ambasera».</span></font></p>
		</li></ol>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Користувач
		зобов’язується:</span></font></p>
		<ol>
			<li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">належним
			чином дотримуватись даних Правил та
			умов;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">не
			вчиняти дій, які впливають на нормальну
			роботу web-додатку «Ambasera» і є його
			недобросовісним використанням;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">не
			здійснювати самостійно або із залученням
			третіх осіб декомпілювання web-додатку
			«Ambasera», або реверс-інжиніринг web-додатку
			«Ambasera»;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">використовувати
			web-додаток «Ambasera» тільки в законних
			цілях, дотримуватись чинного
			законодавства України, а також прав
			і законних інтересів Адміністрації
			web-додатку «Ambasera»;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">не
			здійснювати дій, спрямованих на
			отримання доступу до чужого особистого
			кабінету/облікового запису, шляхом
			підбору логіна й пароля, злому або
			інших дій;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">не
			копіювати та не використовувати
			матеріали, інформацію з web-додатку
			«Ambasera» в комерційних цілях, як у
			web-додатку «Ambasera», так і на інших
			ресурсах;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">не
			використовувати самостійно або із
			залученням третіх осіб web-додаток
			«Ambasera» з метою, яка може бути кваліфікована
			як порушення прав третіх осіб на
			об'єкти права інтелектуальної власності,
			недобросовісна конкуренція або інше
			порушення чинного законодавства
			України;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">надавати
			за запитом Адміністрації web-додатку
			«Ambasera» додаткову інформацію, яка має
			безпосереднє відношення до роботи у
			web-додатку «Ambasera»;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">виконувати
			умови, запропоновані під час реєстрації
			на користь Амбасадора.</span></font></p>
		</li></ol>
	</li></ol>
</li></ol>
<p lang="uk-UA" align="justify" >
<br/>

</p>
<ol start="5">
	<li><p align="justify" >
	<font face="Times New Roman, serif"><span lang="uk-UA"><b>ПРАВА
	ТА ОБОВ'ЯЗКИ АДМІНІСТРАЦІЇ WEB-ДОДАТКУ
	«AMBASERA».</b></span></font></p>
	<ol>
		<li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Адміністрація
		web-додатку «Ambasera» має право:</span></font></p>
		<ol>
			<li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">залишити
			за собою право на власний розсуд
			змінювати або видаляти будь-яку
			інформацію, яка публікується
			Користувачем, призупиняти, обмежувати
			або припиняти доступ Користувача до
			web-додатку «Ambasera» в будь-який час і без
			пояснення причин;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">розміщувати
			рекламну та/або іншу інформацію про
			Користувача будь-де та будь-коли без
			узгодження з Користувачем;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">призупинити
			роботу Користувача у web-додатку
			«Ambasera» за порушення та/або недотримання
			даних Правил та умов. При цьому
			Адміністрація web-додатку «Ambasera» не
			несе відповідальності за будь-який
			збиток, який може бути завдано
			Користувачу такими діями;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">надсилати
			своїм Користувачам інформаційні
			повідомлення, використовуючи web-додаток
			«Ambasera», при цьому Користувач також
			дає свою згоду на отримання таких
			інформаційних повідомлень.</span></font></p>
		</li></ol>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Адміністрація
		web-додатку «Ambasera» зобов’язується:</span></font></p>
		<ol>
			<li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">надавати
			Користувачу можливість отримання
			консультацій служби підтримки. Обсяг
			консультацій обмежується питаннями,
			пов'язаними з роботою web-додатку
			«Ambasera»;</span></font></p>
			</li><li><p align="justify" >
			<font face="Times New Roman, serif"><span lang="uk-UA">збирати,
			зберігати та використовувати персональні
			дані Користувача відповідно до Закону
			України «Про захист персональних
			даних.</span></font></p>
		</li></ol>
	</li></ol>
</li></ol>
<p lang="uk-UA" align="justify" >
<br/>

</p>
<ol start="6">
	<li><p align="justify" >
	<font face="Times New Roman, serif"><span lang="uk-UA"><b>ОБМЕЖЕННЯ
	ТА ЗАБОРОНИ</b></span></font></p>
	<ol>
		<li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Заборонено
		реєстрацію Користувача, послуги якого
		передбачають просування товарів та
		послуг сексуального та порнографічного
		змісту.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Заборонено
		створювати інші Акаунти з однією поштою
		та номером телефону.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Заборонено
		вказувати недостовірну інформацію,
		пов'язану з найменуванням компанії/бренду,
		адресу реєстрації та фактичне
		розташування компанії.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">При
		зверненні до чату підтримки заборонені:
		принизливі вислови, лайки, вульгарний
		або непристойний текст; нечитані знаки
		та символи; веб-адреси, номери телефонів,
		контактні дані; тексти рекламного
		характеру.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Заборонено
		несанкціоновано збирати та зберігати
		персональні дані інших осіб.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Всі
		об'єкти, доступні за допомогою web-додатку
		«Ambasera», у тому числі елементи дизайну,
		текст, графічні зображення, ілюстрації,
		відео, бази даних, музика, звуки та інші
		об'єкти (далі – зміст web-додатку
		«Ambasera»), а також будь-який контент,
		розміщений у </span></font><font face="Times New Roman, serif"><span lang="en-US">w</span></font><font face="Times New Roman, serif"><span lang="uk-UA">eb-додатку
		є об'єктами виключних майнових прав
		Адміністрації web-додатку «Ambasera».</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Заборонено
		використання контенту, а також будь-яких
		інших елементів web-додатку «Ambasera».
		Використання можливе лише в рамках
		функціоналу, запропонованого web-додатком
		«Ambasera». Жодні елементи змісту web-додатку
		«Ambasera», а також будь-який контент,
		розміщений у web-додатку «Ambasera», не
		можуть бути використані іншим чином
		без попереднього дозволу Адміністрації
		web-додатку «Ambasera». Під використанням
		маються на увазі, у тому числі:
		відтворення, копіювання, переробка,
		розповсюдження на будь-якій основі та
		ін.</span></font></p>
	</li></ol>
</li></ol>
<p lang="uk-UA" align="justify" >
<br/>

</p>
<ol start="7">
	<li><p align="justify" >
	<font face="Times New Roman, serif"><span lang="uk-UA"><b>ВІДПОВІДАЛЬНІСТЬ.
	ОБМЕЖЕННЯ ВІДПОВІДАЛЬНОСТІ.</b></span></font></p>
	<ol>
		<li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Адміністрація
		web-додатку «Ambasera» не несе відповідальності
		за відповідність web-додатку «Ambasera»
		цілком або її частин очікуванням
		Користувача, та не відшкодовує
		Користувачу будь-які пов'язані з цим
		збитки.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Адміністрація
		не несе відповідальності за малу
		кількість або відсутність замовлень
		послуг Користувача, невиправдані
		очікування і можливі збитки в процесі
		роботи у web-додатку «Ambasera».</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Адміністрація
		не несе відповідальності перед
		Користувачем за обмеження або припинення
		доступу до його послуг, якщо ці обмеження
		і припинення виникли внаслідок обставин
		непереборної сили, на настання яких
		Адміністрація не могли вплинути.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Адміністрація
		не несе відповідальності перед
		Користувачем або третіми особами за
		прямі та/або непрямі збитки, включаючи
		упущену вигоду або втрачені дані, шкоду
		честі, гідності або ділової репутації,
		понесені у зв'язку з використанням ним
		web-додатку «Ambasera», або неможливості їх
		використання, або несанкціонованого
		доступу до комунікацій Користувача
		третіх осіб.</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">Користувач
		самостійно несе відповідальність
		перед третіми особами за свої дії,
		пов'язані з використанням web-додатку
		«Ambasera», у тому числі якщо такі дії
		призведуть до порушення прав і законних
		інтересів третіх осіб, а також за
		недотримання законодавства при
		використанні web-додатку «Ambasera».</span></font></p>
		</li><li><p align="justify" >
		<font face="Times New Roman, serif"><span lang="uk-UA">У разі
		недотримання умов п. 4.2 та п. 6 цих Правил
		Користувач відшкодовує Адміністрації
		web-додатку «Ambasera» збитки у розмірі
		20000 (двадцять тисяч) грн.</span></font></p>
	</li></ol>
</li></ol>

    </div>
  );
}

export default agendaTemplate;