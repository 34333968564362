import store from "../store";
import { toogleSnackbar } from "../actions/modal";
import {
  resumeToken,
  processContractExist,
  handleLogout,
} from "../actions/user";
import API_ROOT from "../constants/api";

export const GET = (...args) => makeRequest("GET", ...args);
export const POST = (...args) => makeRequest("POST", ...args);
export const PUT = (...args) => makeRequest("PUT", ...args);
export const DELETE = (...args) => makeRequest("DELETE", ...args);
export const PATCH = (...args) => makeRequest("PATCH", ...args);

const formData = (object) =>
  Object.keys(object).reduce((form, key) => {
    form.append(key, object[key]);
    return form;
  }, new FormData());

async function makeRequest(
  method,
  endpoint,
  rawData = false,
  multipart = false
) {
  // console.log(123);
  const refresh_token =
    store.getState().user &&
    store.getState().user.info &&
    store.getState().user.info.refresh_token;

  const user = store.getState().user;
  const token = user && user.info && user.info.token;
  let headers = new Headers({
    "Content-Type": "application/javascript",
    apikey: "LBUPO@^*;sks4%g",
  });

  if (rawData && rawData.file) {
    const image = await makeUploadFetch(rawData.file, endpoint);
    if (image.data) {
      return image.data;
    }
  }
  if (token) headers.append("Authorization", `Bearer ${token}`);
  const options = {
    method,
    headers,
    mode: "cors",
    cache: "default",
  };
  let body =
    !!rawData && (multipart ? formData(rawData) : JSON.stringify(rawData));

  if (!!body) {
    Object.assign(options, { body });
  }
  const request = new Request(API_ROOT + endpoint, options);

  try {
    const data = await fetch(request);
    const json = await data.json();
    if (json) {
      if (json.error) {
        if (json.error.message === "SAME_CONTRACT_EXISTS") {
          const exits_contract = await processContractExist(rawData)(
            store.dispatch,
            store.getState
          );
          toogleSnackbar({
            open: true,
            message: `${json.error.message}`,
          })(store.dispatch, store.getState);
          return exits_contract;
        }
        if (json.error.message === "PHONE_USED") {
          toogleSnackbar({
            open: true,
            message: `${json.error.message}`,
          })(store.dispatch, store.getState);
          return json;
        }
        if (json.error.message === "CODE_INVALID") return json;
        if (json.error.message === "USER_ACTIVATED") {
          toogleSnackbar({
            open: true,
            message: `${json.error.message}`,
          })(store.dispatch, store.getState);
          return json;
        }
        if (json.error.message === "CANNOT_REFRESH_TOKEN") {
          handleLogout()(store.dispatch, store.getState);
          toogleSnackbar({
            open: true,
            message: `${json.error.message}`,
          })(store.dispatch, store.getState);
          return json;
        }
        if (json.error.message === "NOT_AUTHORIZED") {
          if (!refresh_token) handleLogout()(store.dispatch, store.getState);
          if (refresh_token) {
            await resumeToken(refresh_token)(store.dispatch, store.getState);

            return await makeRequest(method, endpoint, rawData, multipart);
          }
        }
        toogleSnackbar({
          open: true,
          message: `${json.error.message}`,
        })(store.dispatch, store.getState);
        return false;
      }
      return json;
    }
  } catch ({ message, name, number, url }) {
    console.log(message, name, number, url);
  }
}

const makeToQuery = (query, noGet = false) => {
  if (!query) {
    query = {};
  }
  query.time = +new Date();
  let str = [];
  for (let p in query)
    if (query.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(query[p]));
    }
  if (noGet) return str.join("&");
  return "?" + str.join("&");
};

const makeUploadFetch = async (data, endpoint) => {
  let myHeaders = new Headers();
  myHeaders.append("apikey", "LBUPO@^*;sks4%g");

  let formdata = new FormData();
  formdata.append("file", data);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const request = new Request(API_ROOT + endpoint, requestOptions);
  try {
    const res = await fetch(request);
    const json = await res.json();
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const ApiConnector = (prefix = "") => {
  return (callback, endpoint, multipart = false, query = false) => ({
    async call(body = false, skipGlobalErrorStack = false) {
      try {
        return await callback(
          ...[prefix + endpoint + makeToQuery(query), body, multipart]
        );
      } catch (message) {}
    },
  });
};
