import React from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { withTranslation } from "react-i18next";
import { closeRequestModal } from "../../../actions/modal";
import { openModal } from "../../../actions/modal";

class TelegramModal extends React.Component {
  handleLogOut = () => {
    this.props.closeRequestModal();
  };

  render() {
    const { t } = this.props;

    const handleTelegram = () => {
      window.open(this.props.data.link);
    };
    return (
      <div className="modal-window">
        <div className="modal-window-info cookies">
          <div className="info-text">
            {this.props.data.secret}
            <Button onClick={() => handleTelegram(this.props.data.link)}>
              Ambassera Bot
            </Button>
          </div>
        </div>
        <div className="modal-buttons-container">
          <Button
            className="modal-buttons no"
            onClick={() => this.props.closeRequestModal()}
          >
            {t("modal.Cancel")}
          </Button>
          <Button
            className="modal-buttons yes orange"
            onClick={() => this.props.closeRequestModal()}
          >
            {t("modal.Ok")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default withTranslation()(
  connect(mapStateToProps, { closeRequestModal, openModal })(TelegramModal)
);
