import ambaseraLogo from "../../resources/img/ambasera.png";

export default function sharedLogo({ size }) {
  return (
    <img
      width={size || 40}
      height={size || 40}
      style={{
        backgroundColor: "#fff",
        padding: "10px",
        borderRadius: size ? size / 2 : "20px",
      }}
      src={ambaseraLogo}
      alt="main app logo"
    />
  );
}
