import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import STATUSES from "../../../constants/statuses";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import calendar from "./../../../resources/img/calendar-user.svg";
import { setHeaderName } from "../../../actions/ui";
import { withTranslation } from "react-i18next";
import { changeClentStatus } from "../../../actions/service";
import { getServiceContracts } from "../../../actions/service";

class UsersSinglePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: null,
      status_code: 1,
    };
  }

  componentDidMount() {
    const user =
      this.props.contracts &&
      this.props.contracts.length &&
      this.props.contracts.find(
        (el) => el.lead.id === this.props.match.params.id
      );
    this.setState({
      status_code: user.status,
    });
    this.props.setHeaderName(`${user.lead.first_name} ${user.lead.last_name}`);
  }

  componentWillUMount() {
    this.props.setHeaderName();
  }

  handleStatusChange = async () => {
    await this.props.changeClentStatus(
      this.state.status.user,
      this.state.status.seq
    );

    await this.props.getServiceContracts({
      limit: 10,
      offset: 0,
      role: 4,
    });
    this.props.history.push("/service/home");
  };
  render() {
    const processIconNull = (item) => {
      const fn = item.lead.first_name;
      const ln = item.lead.last_name;
      if (fn && ln) {
        return `${fn[0]}${ln[0]}`;
      } else if (fn && !ln) {
        return `${fn[0]}${fn[fn.length - 1]}`;
      } else if (!fn && ln) {
        return `${ln[0]}${fn[ln.length - 1]}`;
      }
    };
    const { t } = this.props;
    const user =
      this.props.contracts &&
      this.props.contracts.length &&
      this.props.contracts.find(
        (el) => el.lead.id === this.props.match.params.id
      );
    const handleChange = async (item, customer) => {
      const activeService =
        this.props.services &&
        this.props.services.find((el) => el.service.active === true);
      this.setState({
        status: {
          user: {
            user_seq: customer.lead.id,
            status: item.target.value,
          },
          seq: activeService.service.seq,
        },
        status_code: item.target.value,
      });
    };
    return (
      <div className="white-data-section">
        <div className="user-info">
          <div className="user-image">
            {user.icon ? user.icon : processIconNull(user)}
          </div>
          <div className="user-name-role">
            <h4>
              {user.lead.first_name} {user.lead.last_name}
            </h4>
            <h5>{t(`customers.user`)}</h5>
          </div>
        </div>
        <div className="user-data">
          <div className="bottom">
            <div className="bottom-container">
              <div style={{ textTransform: "capitalize" }}>{`Телефон :`}</div>
              <div>
                <a href={`tel: ${user.lead.phone}`}>{user.lead.phone}</a>
              </div>
            </div>
            {user.lead.email ? (
              <div className="bottom-container">
                <div style={{ textTransform: "capitalize" }}>{`Телефон :`}</div>
                <div>{user.lead.email}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="buttons">
          <FormControl
            sx={{ width: "100%", margin: "20px 0" }}
            className={`select-status ${
              STATUSES.find((item) => item.value === this.state.status_code)
                .label
            }`}
          >
            <Select
              value={this.state.status_code}
              onChange={(e) => handleChange(e, user)}
              input={<OutlinedInput />}
            >
              {STATUSES.map((el, index) => (
                <MenuItem
                  value={el.value}
                  key={index}
                  disabled={
                    el.disabled ||
                    (+this.state.status_code === 3 && el.value === 2)
                  }
                >
                  {t(`statuses.${el.label}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {false ? (
          <>
            <div>Birthday</div>
            <div className="user-card birthday">
              <div
                className="back-image"
                style={{ backgroundImage: `url(${calendar})` }}
              ></div>
              {user.birthday}
            </div>
          </>
        ) : null}
        <Box className="light center margin-large txt14">
          <Button
            className="orange rounded center"
            onClick={() => this.handleStatusChange()}
            disabled={+user.status === +this.state.status_code}
          >
            {this.state.loading ? (
              <CircularProgress size={23} style={{ color: "#fff" }} />
            ) : (
              `${t("mapPage.save")}`
            )}
          </Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.user && state.user.services,
    contracts: state.contracts && state.contracts.list,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    changeLogoState,
    setHeaderName,
    changeClentStatus,
    getServiceContracts,
  })(UsersSinglePage)
);
