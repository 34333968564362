import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
// import ProtectedRedirectRoute from "./ProtectedRedirectRoute";

import LoginPage from "../containers/common/LoginContainer";
import WelcomePage from "../containers/common/WelcomeContainer";

import MapPage from "../containers/ambassador/MapContainer";
import BrandPage from "../containers/ambassador/BrandContainer";
import BrandSelectedPage from "../containers/ambassador/BrandSelectedContainer";
import BrandInfoPage from "../containers/ambassador/BrandInfoContainer";
import UploadImagePage from "../containers/ambassador/UploadImageContainer";
import HomeAbmPage from "../containers/ambassador/HomeAbmContainer";
import WithdrawContainer from "../containers/ambassador/WithdrawContainer";
import PaymentConfirmContainer from "../containers/ambassador/PaymentConfirmContainer";
import EditProfilePage from "../containers/ambassador/EditProfile";
import PhoneVerificationComponent from "./../containers/ambassador/PhoneVerification";

import LeadWelcomePage from "../containers/lead/Welcome";
import LeadRegisterPage from "../containers/lead/Register";
import LeadHomePage from "../containers/lead/Home";
import PhoneComfirm from "../containers/lead/PhoneComfirm";

import ServiceRegisterPage from "../containers/service/Welcome";
import AdvAgendaPage from "../containers/service/AdvAgenda";
import HomeServicePage from "../containers/service/Home";
import AmbassadorsServicePage from "../containers/service/Users";
import PhoneVerificationService from "../containers/service/PhoneVerification";
import ServiceRegisterComponent from "../containers/service/RegisterService";
import ModifyServiceComponent from "../containers/service/ModifyService";
import ServiceUsers from "../containers/common/SericeUsers";

import { Route, Redirect } from "react-router-dom";

import DebugService from "../requests/services/debug.service";

import PrivacyPolicy from "../components/PrivacyPolicy";
import { toogleSnackbar } from "../actions/modal";

import store from "../store";

const NotFound = () => <Redirect to="/" />;

const TestFire = () => {
  DebugService.ClearAllData().then((res) => {
    toogleSnackbar({
      open: true,
      message: "ALL DATA CLEARED",
    })(store.dispatch, store.getState);
  });

  return <Redirect to="/" />;
};

export class RootRoute extends React.Component {
  render() {
    return (
      <Switch>
        <ProtectedRoute
          exact
          path="/privacy-policy"
          component={PrivacyPolicy}
          noRedirect
        />
        <ProtectedRoute exact path="/" component={WelcomePage} />
        <ProtectedRoute exact path="/login/:role" component={LoginPage} />
        <ProtectedRoute exact path="/login/:role/:qr" component={LoginPage} />
        <ProtectedRoute exact path="/auth/map" component={MapPage} />
        <ProtectedRoute
          exact
          path="/auth/phone"
          component={PhoneVerificationComponent}
          protected
        />

        <ProtectedRoute exact path="/auth/image" component={UploadImagePage} />
        <ProtectedRoute exact path="/home" component={HomeAbmPage} protected />
        <ProtectedRoute exact path="/brands" component={BrandPage} protected />
        <ProtectedRoute
          exact
          path="/brands/:brand"
          component={BrandSelectedPage}
          protected
        />
        <ProtectedRoute
          exact
          path="/auth/agenda"
          component={BrandInfoPage}
          protected
        />
        <ProtectedRoute
          exact
          path="/brands/:brand/:code"
          component={BrandSelectedPage}
          protected
        />
        <ProtectedRoute
          exact
          path="/withdraw/add-card"
          component={WithdrawContainer}
          protected
        />
        <ProtectedRoute
          exact
          path="/withdraw/payment-confirm"
          component={PaymentConfirmContainer}
          protected
        />
        <ProtectedRoute
          exact
          path="/edit"
          component={EditProfilePage}
          protected
        />

        <ProtectedRoute
          exact
          path="/invite/:id"
          component={LeadWelcomePage}
          reset
        />
        <ProtectedRoute
          exact
          path="/user/register"
          component={LeadRegisterPage}
        />
        <ProtectedRoute exact path="/user/phone" component={PhoneComfirm} />
        <ProtectedRoute
          exact
          path="/user/home"
          component={LeadHomePage}
          protected
        />

        <ProtectedRoute
          exact
          path="/service/welcome"
          protected
          component={ServiceRegisterPage}
        />
        <ProtectedRoute
          exact
          path="/service/agenda"
          protected
          component={AdvAgendaPage}
        />
        <ProtectedRoute
          exact
          path="/service/home"
          component={HomeServicePage}
          protected
        />
        <ProtectedRoute
          exact
          path="/service/user/:id"
          component={AmbassadorsServicePage}
          protected
        />
        <ProtectedRoute
          exact
          protected
          path="/service/phone"
          component={PhoneVerificationService}
        />
        <ProtectedRoute
          exact
          path="/service/reg/:page"
          component={ServiceRegisterComponent}
          protected
        />
        <ProtectedRoute
          exact
          protected
          path="/service/edit"
          component={ModifyServiceComponent}
        />
        <ProtectedRoute
          exact
          protected
          path="/service/users"
          component={ServiceUsers}
        />
        <Route path="/fautwgmwlbjcrwubtztf" exact={true} component={TestFire} />
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    );
  }
}

// <ProtectedRoute path="/welcome" />

export default RootRoute;
