import Types from "./../constants/ui";
import STATUSES from "../constants/statuses";
const defaultState = {
  logo: false,
  prevUrl: "",
  menu: false,
  menuAnimation: false,
  params: null,
  header_name: null,
  filter: STATUSES,
  offset: 0,
  limit: 10,
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.URL:
    case Types.FILTER:
    case Types.LOGO:
      return { ...state, ...action.ui };
    default:
      return state;
  }
};

export default reducer;
