import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";

import soroban from "./../../../resources/img/soroban.svg";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { withTranslation } from "react-i18next";

class BrandSelectedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      copied: false,
      brand_info: {
        logo: soroban,
        title: "Soroban",
        rating: 4.5,
        contact_data: {
          phone: "+380 67 1237793",
          web_page: "https://soroban.ua",
        },
      },
    };
  }

  handleMapSave = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.props.history.push(`/brands/${this.props.match.params.brand}/info`);
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      copied: false,
    });
  };
  render() {
    const el = this.state.brand_info;
    const { t } = this.props;
    return (
      <div className="white-data-section">
        <div className="top">
          <div className="brand-icon-title">
            <div
              className="brand-icon"
              style={{ backgroundImage: `url(${el.logo})` }}
            >
              {!el.logo
                ? `${el.title.split("")[0]}${el.title.split("")[1]}`
                : null}
            </div>
            <h3
              onClick={() =>
                this.props.history.push(`/brands/${el.program_title}`)
              }
            >
              {el.title}
            </h3>
          </div>
          <div className="rating">
            <div>{el.rating}</div>
            <Rating
              name="hover-feedback"
              size="small"
              value={el.rating}
              precision={0.5}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
          </div>
        </div>
        <div className="bottom info">
          <h2>{t("brandSelectedPage.registre_to_school")}</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
            Nulla consequat massa quis enim
          </p>
        </div>
        <div className="brand-video">
          <iframe
            src="https://www.youtube.com/embed/YtUwio9gns0?mute=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div className="brand-contact-details">
          <h4>{t("brandSelectedPage.contact_details")}</h4>
          {Object.keys(el.contact_data).length &&
            Object.keys(el.contact_data).map((item, index) => {
              return (
                <div className="brand-contact-data" key={index}>
                  <div className="brand-contact-icon">
                    {item === "phone" ? (
                      <PhoneInTalkOutlinedIcon />
                    ) : (
                      <LanguageOutlinedIcon />
                    )}
                    <div className="brand-contact-text">
                      <a
                        href={
                          item === "phone"
                            ? `tel: ${el.contact_data[item]}`
                            : el.contact_data[item]
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {el.contact_data[item]}
                      </a>
                    </div>
                  </div>

                  <div className="brand-contact-copy">
                    <CopyToClipboard
                      text={el.contact_data[item]}
                      onCopy={() => this.setState({ copied: true })}
                    >
                      <ContentCopyOutlinedIcon />
                    </CopyToClipboard>
                  </div>
                </div>
              );
            })}
        </div>
        <Snackbar
          open={this.state.copied}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {t("brandSelectedPage.contact_info")}
          </MuiAlert>
        </Snackbar>
        <Box className="light center margin-large txt14">
          <Button
            className="orange rounded center"
            onClick={this.handleMapSave}
          >
            {this.state.loading ? (
              <CircularProgress size={23} style={{ color: "#fff" }} />
            ) : (
              `${t("brandSelectedPage.submit")}`
            )}
          </Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default withTranslation()(
  connect(mapStateToProps, { changeLogoState })(BrandSelectedPage)
);
