import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../onSubmit";
import { registerUser } from "../../../actions/register";
import phone from "./../../../resources/img/icons/phone.svg";
import bubbles_green from "./../../../resources/img/icons/bubbels-green.svg";
import email from "./../../../resources/img/icons/email.svg";
import Icon from "@mui/material/Icon";

import InputAdornment from "@mui/material/InputAdornment";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import upload from "./../../../resources/img/upload.svg";
import { withTranslation } from "react-i18next";

import { setServiceData } from "../../../actions/user";
import UploadPhotoService from "../../../requests/services/upload.service";

import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";

class ServiceRegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file_upload_error: null,
    };
  }
  componentDidMount() {
    this.props.initialize({
      phone_number: this.props.info && this.props.info.phone,
      email:
        (this.props.oauth && this.props.oauth.email) ||
        (this.props.user && this.props.user.email) ||
        "",
      brand_name: this.props.service_data.brand_name || "",
    });
    this.props.changeLogoState(true);
  }

  onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      // console.log();
      let img = document.getElementById("file").files[0];
      if (!img.type.includes("image")) {
        this.setState({ file_upload_error: "Неправильный тип файла" });
        return;
      }

      const res = await UploadPhotoService.UploadFile(img);
      this.props.setServiceData({
        logo: res,
      });
      this.setState({
        file_upload_error: null,
      });
    }
  };

  handleAuth = (index) => {
    const auth = this.state.auth;
    const isLoading = auth.findIndex((el) => el.loading === true);
    if (isLoading === -1 && !auth[index].loading) {
      auth[index].loading = true;
      this.setState({
        auth,
      });
      setTimeout(() => {
        this.props.history.push("/auth/image");
      }, 1000);
    }
  };

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  handleClickLogin = () => {
    this.props.history.push("/login/service");
  };

  render() {
    const { t } = this.props;
    return (
      <div className="login-data-container">
        <Slide in timeout={500}>
          <div className="welcome-text center">
            <h2>{t("serviceRegisterPage.welcome")}</h2>
            <h3>{t("serviceRegisterPage.please_sign_up")}</h3>
          </div>
        </Slide>
        <Slide in timeout={500}>
          <div className="select-auth-method">
            <Form onSubmit={this.props.handleSubmit}>
              <div className="row item full">
                <Field
                  name="email"
                  component={this.renderTextField}
                  placeholder={t("reg.Email")}
                  variant="outlined"
                  autoComplete="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <img src={email} height={25} width={25} alt="email" />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>
              <div className="row item full">
                <Field
                  name="brand_name"
                  component={this.renderTextField}
                  placeholder={t("reg.Brand Name")}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <img
                            src={bubbles_green}
                            height={25}
                            width={25}
                            alt="bubbles_green"
                          />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>

              {/*<div className={`upload-logo`}>*/}
              {/*  <div>*/}
              {/*    {this.props.image ? (*/}
              {/*      <img*/}
              {/*        src={this.props.image}*/}
              {/*        height={30}*/}
              {/*        width={30}*/}
              {/*        alt="upload"*/}
              {/*      />*/}
              {/*    ) : (*/}
              {/*      t("serviceRegisterPage.upload_logo")*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*  <div className="upload-logo-item">*/}
              {/*    <Icon>*/}
              {/*      <img src={upload} height={25} width={25} alt="upload" />*/}
              {/*    </Icon>*/}
              {/*  </div>*/}
              {/*  <input*/}
              {/*    type="file"*/}
              {/*    name="myImage"*/}
              {/*    id="file"*/}
              {/*    onChange={this.onImageChange}*/}
              {/*  />*/}
              {/*</div>*/}
              <Box m={2}>
                <Collapse in={this.state.file_upload_error}>
                  <Typography component="p" className="error">
                    {t("errors.wrong_img")}
                  </Typography>
                </Collapse>
              </Box>

              <Box className="light center margin-large txt14">
                <Button
                  className="orange rounded center"
                  style={{ width: "100%" }}
                  type="submit"
                >
                  {this.state.loading ? (
                    <CircularProgress size={23} style={{ color: "#fff" }} />
                  ) : (
                    `${t("modal.Continue")}`
                  )}
                </Button>
              </Box>
            </Form>
            <div className="already-have">
              {t("serviceRegisterPage.account")}
              <span onClick={this.handleClickLogin}>
                {t("serviceRegisterPage.sign_up")}
              </span>
            </div>
          </div>
        </Slide>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    oauth: state.user && state.user.oauth,
    ServiceRegister: state.form && state.form.ServiceRegister,
    image: state.user && state.user.service && state.user.service.logo,
    service_data: state.user && state.user.service,
    user: state.user && state.user.info && state.user.info.user,
    info: state.user && state.user.info,
  };
};

const validate = (values) => {
  const errors = {};
  if (values.phone_number && values.phone_number.includes("_")) {
    errors.phone_number = "Incorrect phone number";
  }
  return errors;
};

const withForm = reduxForm({
  form: "ServiceRegister",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: registerUser,
});

export default withTranslation()(
  connect(mapStateToProps, { changeLogoState, setServiceData })(
    withForm(ServiceRegisterPage)
  )
);
