import store from "./../store";

const data = (data, dispatch, props) => {
  const { onAccept } = props;

  // const { onAccept, filter, validate, values } = props;
  // const validationErrors = validate(values, props);

  // if (!!Object.keys(validationErrors).length) {
  // throw new SubmissionError(validationErrors);
  // } else {
  // eslint-disable-next-line
  const q = onAccept(data, props)(dispatch, store.getState);
  // }
};

export default data;
