const def = [
  "/",
  "/invite/:id",
  "/user/home",
  "/service/welcome",
  "/service/home",

];

export default def;
