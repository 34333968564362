import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { setUserProfile } from "../../../actions/user";

import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { withTranslation } from "react-i18next";
import { createService } from "../../../actions/service";
import Agenda from "./agenda";
import UaAgenda from "./uaAgenda";

class AdvAgendaPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checked: false,
    };
  }

  handleCreateService = async () => {
    this.setState({
      loading: true,
    });
    const res = await this.props.createService(this.state.file);
    setTimeout(() => {
      if (res && res.data.seq) {
        this.props.history.push("/service/home");
      }
    }, 1000);
  };
  render() {
    const { t, i18n } = this.props;
    return (
      <div className="white-data-section">
        {i18n.language === "ua" ? <UaAgenda /> : <Agenda />}

        <FormGroup style={{ marginTop: "40px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checked}
                onClick={(e) => {
                  this.setState({ checked: !this.state.checked });
                }}
              />
            }
            label={
              <div className="checkbox-item">
                {t("AdvAgendaPage.accept")}{" "}
                <span>{t("AdvAgendaPage.conditions")}</span>
              </div>
            }
          />
        </FormGroup>
        <Box className="light center margin-large txt14">
          <Button
            className="orange rounded center"
            onClick={this.handleCreateService}
            disabled={!this.state.checked}
          >
            {this.state.loading ? (
              <CircularProgress size={23} style={{ color: "#fff" }} />
            ) : (
              `${t("AdvAgendaPage.submit")}`
            )}
          </Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default withTranslation()(
  connect(mapStateToProps, { changeLogoState, setUserProfile, createService })(
    AdvAgendaPage
  )
);
