import React, { Component } from "react";
import { connect } from "react-redux";
import { changeLogoState } from "../../../actions/ui";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { setUserProfile } from "../../../actions/user";
import { setParams } from "../../../actions/ui";
import { withTranslation } from "react-i18next";
import { getInviteData } from "../../../actions/invite";
class LeadWelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      copied: false,
      brand_info: null,
    };
  }

  componentDidMount() {
    this.setParams();
  }

  async setParams() {
    const params = this.props.match.params;
    const service = await getInviteData(this.props.match.params.id);
    if (service && service.data && service.data.service) {
      this.setState({
        brand_info: {
          ...service.data.service,
          contact_data: {
            phone: service.data.service.phone,
            web_page: service.data.service.weblink,
          },
        },
      });
    }
    this.props.setParams({ id: params.id });
    this.props.setUserProfile(false, params.id);
  }

  handleLeadSave = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.props.history.push(`/user/register`);
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      copied: false,
    });
  };
  render() {
    const el = this.state.brand_info;
    const { t } = this.props;
    let youtube_code =
      el &&
      el.youtube &&
      el.youtube.en &&
      el.youtube.en.includes("/watch?v=") &&
      el.youtube.en
        .split("/watch?v=")[1]
        .substr(
          0,
          el.youtube.en.split("/watch?v=")[1].indexOf("&") === -1
            ? el.youtube.en.split("/watch?v=")[1].length
            : el.youtube.en.split("/watch?v=")[1].indexOf("&")
        );
    if (el && el.youtube && el.youtube.en.includes("https://youtu.be/"))
      youtube_code = el.youtube.en.split("https://youtu.be/")[1];
    const youtube = `https://www.youtube.com/embed/${youtube_code}?mute=1`;
    return (
      <div className="white-data-section">
        {el ? (
          <>
            <div className="top">
              <div className="brand-icon-title">
                <div
                  className="brand-icon"
                  style={{ backgroundImage: `url(${el.logo_url})` }}
                >
                  {!el.logo
                    ? `${el.public_name.split("")[0]}${
                        el.public_name.split("")[1]
                      }`
                    : null}
                </div>
                <h3>{el.public_name}</h3>
              </div>
              {el.rating ? (
                <div className="rating">
                  <div>{el.rating}</div>
                  <Rating
                    name="hover-feedback"
                    size="small"
                    value={el.rating}
                    precision={0.5}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                </div>
              ) : null}
            </div>
            <div className="bottom info">
              <h2>
                {t("leadWelcomePage.registre_to_school")}
                {` ${el.public_name}?`}
              </h2>
              <p>{el.about.en}</p>
            </div>
            {el.youtube && youtube_code ? (
              <div className="brand-video">
                <iframe
                  src={youtube}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            ) : null}

            <div className="brand-contact-details">
              <h4>{t("leadWelcomePage.contact_details")}</h4>
              {el.contact_data && el.contact_data.phone && (
                <div className="brand-contact-data">
                  <div className="brand-contact-icon">
                    <PhoneInTalkOutlinedIcon />
                    <div className="brand-contact-text">
                      <a
                        href={`tel: ${el.contact_data.phone}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {el.contact_data.phone}
                      </a>
                    </div>
                  </div>

                  <div className="brand-contact-copy">
                    <CopyToClipboard
                      text={el.contact_data.phone}
                      onCopy={() => this.setState({ copied: true })}
                    >
                      <ContentCopyOutlinedIcon />
                    </CopyToClipboard>
                  </div>
                </div>
              )}
              {el.contact_data && el.contact_data.web_page && (
                <div className="brand-contact-data">
                  <div className="brand-contact-icon">
                    <LanguageOutlinedIcon />
                    <div className="brand-contact-text">
                      <a
                        href={el.contact_data.web_page}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {el.contact_data.web_page}
                      </a>
                    </div>
                  </div>

                  <div className="brand-contact-copy">
                    <CopyToClipboard
                      text={el.contact_data.web_page}
                      onCopy={() => this.setState({ copied: true })}
                    >
                      <ContentCopyOutlinedIcon />
                    </CopyToClipboard>
                  </div>
                </div>
              )}
            </div>
            <Snackbar
              open={this.state.copied}
              autoHideDuration={2000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={this.handleClose}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={this.handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {t("leadWelcomePage.contact_info")}
              </MuiAlert>
            </Snackbar>
            <Box className="light center margin-large txt14">
              <Button
                className="orange rounded center"
                onClick={this.handleLeadSave}
                disabled={!this.props.match.params.id}
              >
                {this.state.loading ? (
                  <CircularProgress size={23} style={{ color: "#fff" }} />
                ) : (
                  `${t("leadWelcomePage.register")}`
                )}
              </Button>
            </Box>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

// export default connect(mapStateToProps, { changeLogoState, setParams })(
//   LeadWelcomePage
// );
export default withTranslation()(
  connect(mapStateToProps, { changeLogoState, setUserProfile, setParams })(
    LeadWelcomePage
  )
);
