import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { closeRequestModal } from "../../actions/modal";
import store from "../../store";
import Zoom from "@mui/material/Zoom";
import Switcher from "./modal";
import "./index.css";
import sun_rays from "./../../resources/img/congrats/sun-rays.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom direction="up" ref={ref} {...props} />;
});

function MuiDialog(props) {
  const {
    modal: { open, type, title, data },
  } = props;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="lg"
      fullWidth
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      onClose={() => closeRequestModal()(store.dispatch, store.getState)}
    >
      <DialogContent
        className={type === "CongragulationsModal" ? "green-modal" : ""}
      >
        {type === "CongragulationsModal" && (
          <div
            className="sun-rays"
            style={{ backgroundImage: `url(${sun_rays})` }}
          ></div>
        )}

        <Switcher data={data} type={type} title={title} {...props} />
      </DialogContent>
    </Dialog>
  );
}

export default MuiDialog;
