import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import arrow_down from "./../../resources/img/icons/arrow-down-white.svg";
import Icon from "@mui/material/Icon";
import LanguageIcon from "@mui/icons-material/Language";

export default function BasicMenu(props) {
  const { i18n } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => {
    if (typeof item === "string") {
      i18n.changeLanguage(item);
      localStorage.setItem("lang", item);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        className="lang-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className="language-select" onClick={handleClick}>
          <LanguageIcon />
          <div style={{ textTransform: "uppercase" }}>{i18n.language}</div>
          <Icon>
            <img src={arrow_down} height={20} width={20} alt="arrow_down" />
          </Icon>
        </div>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleClose("en")}>EN</MenuItem>
        <MenuItem onClick={() => handleClose("ua")}>UA</MenuItem>
        <MenuItem onClick={() => handleClose("ru")}>RU</MenuItem>
      </Menu>
    </div>
  );
}
