import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { openModal } from "../../../actions/modal";

import { setUserProfile } from "../../../actions/user";
import new_coupon from "./../../../resources/img/new-coupon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Grow from "@mui/material/Grow";
import { withTranslation } from "react-i18next";
import { getInviteData } from "../../../actions/invite";

class LeadHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      brands: [
        {
          logo: null,
          title: "",
          copied: false,
          program_title: "",
          discount: {
            title: "",
            code: "",
            valid: "",
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.props.changeLogoState(true);
    this.fetchData();
  }

  async fetchData() {
    const { t } = this.props;
    const res = await getInviteData(this.props.qr);
    this.props.setUserProfile({
      services: res.data,
    });
    const service = this.props.service;
    if (service) {
      this.setState({
        brands: [
          {
            logo: service.logo_url || null,
            title: service.public_name,
            copied: false,
            program_title: service.public_name,
            discount: {
              title: `${service.client_discount}% ${t("modal.Flat Discount")}`,
              code: "123456 ***",
              valid: "Valid til Nov 2021 ***",
            },
          },
        ],
      });
    }
  }

  handleCopy = (index) => {
    let brands = this.state.brands;
    brands[index].copied = true;
    this.setState({ brands });
    setTimeout(() => {
      brands[index].copied = false;
      this.setState({ brands });
    }, 2000);
  };

  handleOpenCoupon = () => {
    this.props.openModal({
      type: "CongragulationsModal",
      data: {
        text: "5% Flat Discount",
      },
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="white-data-section">
          <Grow in>
            <div
              className="header-bottom-info open-box home"
              onClick={this.handleOpenCoupon}
            >
              <div className="info-left text">
                <div>{t("leadHomePage.availble_coupons")}</div>
                <br />
                <div className="count">1</div>
              </div>
              <div className="info-left shake">
                <img src={new_coupon} alt="new_coupon" />
              </div>
            </div>
          </Grow>

          {this.state.brands.map((el, index) => (
            <CopyToClipboard
              key={index}
              text={el.discount.code}
              onCopy={() => this.handleCopy(index)}
            >
              <Grow in>
                <div
                  className={`discounts-coupon${el.copied ? " copied" : ""}`}
                >
                  <div className="discount-info">
                    <h3>{el.discount.title}</h3>
                    {false && (
                      <>
                        <h4>{el.discount.valid}</h4>
                        <div className="copy-coupon">
                          <div
                            className={`coupon-code${
                              el.copied ? " copied" : ""
                            }`}
                          >
                            {el.discount.code}
                          </div>
                          <CopyToClipboard
                            text={el.discount.code}
                            onCopy={() => this.handleCopy(index)}
                          >
                            <ContentCopyOutlinedIcon />
                          </CopyToClipboard>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="discount-separator"></div>
                  <div
                    className="dicount-icon"
                    style={{ backgroundImage: `url(${el.logo})` }}
                  ></div>
                </div>
              </Grow>
            </CopyToClipboard>
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    qr: state.ui && state.ui.params && state.ui.params.id,
    service: state.user && state.user.services && state.user.services.service,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    changeLogoState,
    setUserProfile,
    openModal,
  })(LeadHomePage)
);
