import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import Box from "@mui/material/Box";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import mapframe from "./../../../resources/img/takeaphoto.svg";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { withTranslation } from "react-i18next";

class UploadImagePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      photo: null,
      selfie: false,
    };
  }
  componentDidMount() {
    this.props.changeLogoState(false);
  }

  handleMapSave = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.props.history.push("/brands/soroban/info");
    }, 1000);
  };
  handleTakePhoto = (dataUri) => {
    this.setState({
      photo: dataUri,
    });
  };

  handleSeflie = (item) => {
    this.setState({ selfie: item });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="login-data-container">
        {this.state.selfie ? (
          this.state.photo ? (
            <img
              className="rounded"
              style={{ width: "100%" }}
              src={this.state.photo}
              alt="uploaded-item"
            />
          ) : (
            <Camera
              onTakePhoto={(dataUri) => {
                this.handleTakePhoto(dataUri);
              }}
            />
          )
        ) : null}

        {this.state.photo ? (
          <Button variant="outlined" onClick={() => this.handleTakePhoto(null)}>
            {t("uploadImagePage.again")}
          </Button>
        ) : null}
        {!this.state.selfie ? (
          <>
            <div className="welcome-text center">
              <img src={mapframe} alt="Map frame data" />
              <h2>{t("uploadImagePage.verify_identity")}</h2>
              <h3>{t("uploadImagePage.upload_selfie")}</h3>
            </div>
            <Button
              className="green-button center full"
              variant="outlined"
              startIcon={<AddAPhotoIcon />}
              onClick={() => this.handleSeflie(true)}
            >
              {t("uploadImagePage.take_selfie")}
            </Button>
          </>
        ) : null}

        <Box className="light center margin-large txt14">
          <Button
            className="orange rounded center"
            onClick={this.handleMapSave}
          >
            {this.state.loading ? (
              <CircularProgress size={23} style={{ color: "#fff" }} />
            ) : (
              `${t("uploadImagePage.submit")}`
            )}
          </Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { isAuthenticated: state.user && state.user.loggedin };
};

export default withTranslation()(
  connect(mapStateToProps, { changeLogoState })(UploadImagePage)
);
