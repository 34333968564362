import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { openModal } from "../../../actions/modal";

import Icon from "@mui/material/Icon";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import accept from "./../../../resources/img/icons/accept.svg";
import { withTranslation } from "react-i18next";
import { getUserPayments } from "../../../actions/payments";

class PaymentConfirmContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: null,
    };
  }

  componentDidMount() {
    this.props.getUserPayments();
    this.showSwipeTrail();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  showSwipeTrail() {
    this.timeout = setTimeout(() => {
      if (this.props.payments && Object.keys(this.props.payments)) {
        const container = document.querySelector(
          ".swipeable-list-item__content"
        );
        const clickBlock = document.querySelector(
          ".swipeable-list-item__trailing-actions"
        );
        if (container && clickBlock) {
          container.style.transition = "all 0.2s";
          clickBlock.style.transition = "all 0.2s";

          container.style.transform = "translateX(-84px)";
          clickBlock.style.width = "84px";

          setTimeout(() => {
            container.style.transform = "unset";
            clickBlock.style.width = "0px";
          }, 600);
          setTimeout(() => {
            container.style = "";
            clickBlock.style = "";
          }, 900);
        }
      }
    }, 1200);
  }

  render() {
    const { t } = this.props;
    const trailingActions = (price, title, payments) => (
      <TrailingActions>
        <SwipeAction
          destructive={false}
          onClick={() =>
            this.props.openModal({
              type: "PaymentConfirmModal",
              data: {
                price,
                title,
                payments,
              },
            })
          }
        >
          <div className="accept-payment">
            <Icon>
              <img src={accept} height={20} width={20} alt="accept" />
            </Icon>
            <div>{t("paymentConfirmContainer.recevied")}</div>
          </div>
        </SwipeAction>
      </TrailingActions>
    );
    return (
      <>
        <div className="white-data-section">
          {this.props.payments ? (
            <SwipeableList fullSwipe={false} type={ListType.IOS} threshold={2}>
              {this.props.payments &&
                Object.keys(this.props.payments).map((brand, index) => {
                  const el = this.props.payments[brand];
                  const sum = el.sum;
                  const { min_withdraw } = el;
                  el.payed = !sum;
                  return (
                    <SwipeableListItem
                      trailingActions={trailingActions(
                        sum,
                        el.public_name,
                        el.payments
                      )}
                      className={min_withdraw > sum ? "not_avaliable" : ""}
                      key={index}
                    >
                      <div className="swipe-accept">
                        <div className="left">
                          <div
                            className="brand-icon"
                            style={{ backgroundImage: `url(${el.logo_url})` }}
                          >
                            {!el.logo_url
                              ? `${el.public_name.split("")[0]}${
                                  el.public_name.split("")[1]
                                }`
                              : null}
                          </div>
                          <div className="payment-info">
                            <h3>{el.public_name}</h3>
                            <h4>{t("paymentConfirmContainer.total_amount")}</h4>
                            <h4>{t("paymentConfirmContainer.payed")}</h4>
                            <h4>{t("paymentConfirmContainer.minWidtdraw")}</h4>
                          </div>
                        </div>
                        <div className="right">
                          <div className="payment-info">
                            <h3
                              style={{
                                color: el.payed ? "#52B797" : "#DE4747",
                              }}
                            >
                              {el.payed
                                ? t("paymentConfirmContainer.payed")
                                : t("paymentConfirmContainer.not_payed")}
                            </h3>
                            <h4>{sum}</h4>
                            <h4>{el.complete_sum}</h4>
                            <h4>{min_withdraw}</h4>
                          </div>
                        </div>
                      </div>
                    </SwipeableListItem>
                  );
                })}
            </SwipeableList>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    services: state.user && state.user.services,
    payments: state.payments && state.payments.list,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { openModal, getUserPayments })(
    PaymentConfirmContainer
  )
);
