import React from "react";
import { connect } from "react-redux";
import cookies from "./../../../resources/img/modal-icons/cookies.svg";
import Button from "@mui/material/Button";
import { setFilterParams } from "../../../actions/ui";
import { withTranslation } from "react-i18next";

class CookiesModal extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="modal-window">
        <div className="modal-window-info cookies">
          <img src={cookies} alt="cookies" />
          <div className="info-text">
            <h2>{t("modal.We use cookies")}</h2>
            <h3>{t("modal.we_use_cookies_des")}</h3>
          </div>
        </div>
        <div className="modal-buttons-container">
          <Button
            className="modal-buttons no"
            onClick={this.props.closeRequestModal}
          >
            {t("modal.Decline")}
          </Button>
          <Button
            className="modal-buttons yes orange"
            onClick={() => {
              localStorage.setItem("cookies", "true");
              this.props.closeRequestModal();
            }}
          >
            {t("modal.Accept")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default withTranslation()(
  connect(mapStateToProps, { setFilterParams })(CookiesModal)
);
