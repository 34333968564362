import React from "react";
import { connect } from "react-redux";
import present from "./../../../resources/img/congrats/present.png";
import confeti from "./../../../resources/img/congrats/confeti.svg";
import dots from "./../../../resources/img/congrats/dots.svg";
import dots2 from "./../../../resources/img/congrats/dots2.svg";
import Zoom from "@mui/material/Zoom";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import { withTranslation } from "react-i18next";

class CongragulationsModal extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="modal-window congrats">
        <div className="congragulations-info">
          <Zoom in timeout={1000}>
            <div
              className="present"
              style={{ backgroundImage: `url(${present})` }}
            ></div>
          </Zoom>
          <Zoom in timeout={2000}>
            <div
              className="background-present"
              style={{ backgroundImage: `url(${confeti})` }}
            ></div>
          </Zoom>
          <Zoom in timeout={6000}>
            <div
              className="background-present"
              style={{ backgroundImage: `url(${dots})` }}
            ></div>
          </Zoom>
          <Zoom in timeout={5000}>
            <div
              className="background-present"
              style={{ backgroundImage: `url(${dots2})` }}
            ></div>
          </Zoom>
        </div>
        <Slide in timeout={1000}>
          <div className="modal-buttons-container congrats">
            <h2>{t("modal.Congragulations")}</h2>
            <div className="congrats-data">
              <h2>{t("modal.You get")}</h2>
              <h3>
                {`${this.props.service.client_discount}% `}
                {t("modal.Flat Discount")}
              </h3>
            </div>
            <Button className="modal-buttons full-width">
              {this.props.service.public_name}
            </Button>
            <div
              className="go-back-home"
              onClick={this.props.closeRequestModal}
            >
              {t("modal.Go back to homepage")}
            </div>
          </div>
        </Slide>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    service: state.user && state.user.services && state.user.services.service,
  };
};
export default withTranslation()(
  connect(mapStateToProps, {})(CongragulationsModal)
);
