import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../../onSubmit";

import InputAdornment from "@mui/material/InputAdornment";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { withTranslation } from "react-i18next";
import { serviceRegisterSet } from "../../../../actions/register";
import FormControl from "@mui/material/FormControl";
import { handleBack } from "../../../../actions/register";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import Select from "@mui/material/Select";

class ServiceRegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currency: [{ title: "UAH" }, { title: "USD" }],
    };
  }
  componentDidMount() {
    this.props.initialize({
      currency:
        this.props.service.currency ||
        (this.props.country && this.props.country.currency) ||
        "UAH",
      bonus_amb_lead: this.props.service.bonus_amb_lead || "",
      bonus_out_amb: this.props.service.bonus_out_amb || "",
      bonus_employee: this.props.service.bonus_employee || "",
      bonus_new_amb: this.props.service.bonus_new_amb || "",
      min_withdraw: this.props.service.min_withdraw || "",
      client_discount: this.props.service.client_discount || "",
    });
    if (this.props.country && this.props.country.currency) {
      this.setState({
        currency: [
          { title: this.props.country.currency },
          ...this.state.currency,
          ,
        ],
      });
    }
  }

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  renderSelect = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <FormControl variant="outlined" required>
        <Select
          native
          {...custom}
          {...input}
          inputProps={{
            name: input.name,
          }}
        >
          {custom.data.map((item, key) => (
            <option key={key} value={item.title}>
              {item.title}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className="login-data-container">
        <Slide in timeout={500}>
          <div className="select-auth-method">
            <Form onSubmit={this.props.handleSubmit}>
              <div className="row item full select">
                <div>{t("reg.currency")}</div>
                <Field
                  name="currency"
                  component={this.renderSelect}
                  data={this.state.currency}
                  placeholder={t("reg.Write Here")}
                  variant="outlined"
                  required
                />
              </div>
              <div className="row item full select">
                <div>{t("reg.bonus_amb_lead")}</div>
                <Field
                  name="bonus_amb_lead"
                  component={this.renderTextField}
                  placeholder={t("reg.in-dev")}
                  variant="outlined"
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon style={{ color: "#52b797" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="row item full select">
                <div>{t("reg.bonus_out_amb")}</div>
                <Field
                  name="bonus_out_amb"
                  component={this.renderTextField}
                  placeholder={t("reg.Add")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon style={{ color: "#52b797" }} />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>
              <div className="row item full select">
                <div>{t("reg.bonus_employee")}</div>
                <Field
                  name="bonus_employee"
                  component={this.renderTextField}
                  placeholder={t("reg.Add")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon style={{ color: "#52b797" }} />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>

              <div className="row item full select">
                <div>{t("reg.bonus_new_amb")}</div>
                <Field
                  name="bonus_new_amb"
                  component={this.renderTextField}
                  placeholder={t("reg.Add")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon style={{ color: "#52b797" }} />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>

              <div className="row item full select">
                <div>{t("reg.min_withdraw")}</div>
                <Field
                  name="min_withdraw"
                  component={this.renderTextField}
                  placeholder={t("reg.Add")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon style={{ color: "#52b797" }} />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>

              <div className="row item full select">
                <div>{t("reg.client_discount")}</div>
                <Field
                  name="client_discount"
                  component={this.renderTextField}
                  placeholder={`${t("reg.Add")} (%)`}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon style={{ color: "#52b797" }} />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>

              <Box className="light center margin-large txt14 flex">
                <Button
                  className="orange rounded center back-button"
                  onClick={() => handleBack()}
                >
                  {t("reg.back")}
                </Button>
                <Button className="orange rounded center" type="submit">
                  {t("reg.next")}
                </Button>
              </Box>
            </Form>
          </div>
        </Slide>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    service: state.service && state.service[4],
    country: state.service && state.service[1] && state.service[1].country,
  };
};

const validate = (values) => {
  const errors = {};
  return errors;
};

const withForm = reduxForm({
  form: "ServiceRegisterPage4",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: serviceRegisterSet,
});

export default withTranslation()(
  connect(mapStateToProps, { serviceRegisterSet })(
    withForm(ServiceRegisterComponent)
  )
);
