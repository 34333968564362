import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import Zoom from "@mui/material/Zoom";
import { changeMenuState, noImage } from "../../../actions/ui";
import avatar from "./../../../resources/img/avatar2.svg";
import Icon from "@mui/material/Icon";
import about from "./../../../resources/img/icons/about.svg";
import faq from "./../../../resources/img/icons/faq.svg";
import help from "./../../../resources/img/icons/help.svg";
import logout from "./../../../resources/img/icons/logout.svg";
import nav from "./../../../resources/img/icons/nav.svg";
import payment from "./../../../resources/img/icons/payment.svg";
import support from "./../../../resources/img/icons/support.svg";
import { setUserProfile } from "../../../actions/user";
import { openModal } from "../../../actions/modal";
import { withTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { updateService, getBotSecret } from "../../../actions/service";
import { openDrawler } from "../../../actions/drawler";

import QrCodeIcon from "@mui/icons-material/QrCode";

import GroupIcon from "@mui/icons-material/Group";
import TelegramIcon from "@mui/icons-material/Telegram";

class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomIn: true,
      menu: [
        // { icon: bubbels, title: "Brand Lists", link: "/brands" },
        {
          icon: payment,
          title: "Payment Confirmation",
          link: "/withdraw/payment-confirm",
        },
        { icon: nav, title: "Navigation", link: "" },
        { icon: faq, title: "FAQ", link: "" },
        { icon: about, title: "About Us", link: "" },
        { icon: help, title: "Help", link: "" },
        {
          icon: support,
          title: "Contact to support",
          link: "",
        },
      ],
      menu_service: [
        { icon: QrCodeIcon, qr: true, title: "Workers", link: "" },
        {
          icon: GroupIcon,
          users: true,
          title: "Service Users",
          link: "/service/users",
        },
        
        // {
        //   icon: TelegramIcon,
        //   telegram: true,
        //   title: "Telegram",
        //   link: "tg://resolve?domain=ambassero_bot",
        // },
        { icon: nav, title: "Navigation", link: "" },
        { icon: faq, title: "FAQ", link: "" },
        { icon: about, title: "About Us", link: "" },
        { icon: help, title: "Help", link: "" },
        {
          icon: support,
          title: "Contact to support",
          link: "",
        },
      ],
      menu_worker: [
        {
          icon: payment,
          title: "Payment Confirmation",
          link: "/withdraw/payment-confirm",
        },
        {
          icon: GroupIcon,
          users: true,
          title: "Service Users",
          link: "/service/users",
        },
        { icon: nav, title: "Navigation", link: "" },
        { icon: faq, title: "FAQ", link: "" },
        { icon: about, title: "About Us", link: "" },
        { icon: help, title: "Help", link: "" },
        {
          icon: support,
          title: "Contact to support",
          link: "",
        },
      ],
    };
  }

  handleHeaderLinks = (item) => {
    if (item) this.props.history.push(item);

    setTimeout(
      () => {
        this.props.changeMenuState(false);
      },
      item ? 500 : 0
    );
  };

  handleShareClick = () => {
    this.props.changeMenuState(!this.props.menu);
    setTimeout(() => {
      const activeService =
        this.props.services &&
        this.props.services.find((el) => el.service.active === true).service;
      this.props.openDrawler({
        title: activeService.public_name,
        icon: activeService.logo_url,
        role: this.props.role_id,
        qr_type: 2,
        grant_role: 2,
      });
    }, 450);
  };

  handleLogout = () => {
    this.props.openModal({
      type: "AreYouShureModal",
    });
  };

  handleEditClick = () => {
    const pathname = this.props.history.location.pathname;

    if (pathname === "/home" || pathname === "/service/home") {
      this.props.changeMenuState(false);
      setTimeout(() => {
        this.props.history.push(
          pathname === "/home" || this.props.role_id === 3
            ? "/edit"
            : "/service/edit"
        );
      }, 350);
    }
  };

  async handleTelegramClick(link) {
    const secret = await this.props.getBotSecret();
    this.props.openModal({
      type: "TelegramModal",
      data: {
        link,
        secret: secret.data,
      },
    });
  }

  handleChange(e, index) {
    const activeService =
      this.props.services &&
      this.props.services.findIndex((el) => +el.service.id === +e.target.value);
    this.props.updateService(activeService);
  }

  render() {
    const { t } = this.props;
    const menu = {
      0: this.state.menu_service,
      1: this.state.menu,
      3: this.state.menu_worker,
    };
    const pathname = this.props.history.location.pathname;
    const activeService =
      (this.props.role_id === 0 || this.props.role_id === 3) &&
      this.props.services &&
      this.props.services.length &&
      this.props.services.find((el) => el.service.active === true).service;
    return (
      <Zoom in={this.props.menu}>
        <div className="header-menu">
          <div className="user-info">
            <div
              className="user-info-img-container"
              onClick={this.handleEditClick}
            >
              {this.props.role_id === 0 && !activeService.logo_url ? (
                <div className="user-info-img">
                  {noImage({ f: activeService.public_name })}
                </div>
              ) : (
                <div
                  className="user-info-img"
                  style={{
                    backgroundImage: `url(${
                      this.props.role_id === 0 ? activeService.logo_url : avatar
                    })`,
                    backgroundPosition:
                      this.props.role_id === 0 ? "center" : "inherit",
                    backgroundPositionY:
                      this.props.role_id === 0 ? "center" : "5px",
                  }}
                ></div>
              )}

              {pathname === "/home" || pathname === "/service/home" ? (
                <EditIcon />
              ) : null}
            </div>

            <h2>
              {`${
                (this.props.info &&
                  this.props.info.user &&
                  this.props.info.user.first_name) ||
                ""
              } ${
                (this.props.info &&
                  this.props.info.user &&
                  this.props.info.user.last_name) ||
                ""
              }`}
            </h2>
            <h3>
              {this.props.role_id === 0 && t("menu.Service")}
              {this.props.role_id === 1 && t("menu.Ambassador")}
              {this.props.role_id === 3 && t("menu.Workers")}
            </h3>
            {(this.props.role_id === 0 ||
              this.props.role_id === 3) && (
                <FormControl sx={{ width: "220px" }} className="service-picker">
                  <Select
                    value={activeService.id}
                    onChange={(e) => this.handleChange(e)}
                    input={<OutlinedInput />}
                  >
                    {this.props.services.map((el, index) => (
                      <MenuItem value={el.service.id} key={index}>
                        {el.service.public_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
          </div>
          <div className="header-nav">
            {menu[this.props.role_id !== null ? this.props.role_id : 1].map(
              (el, index) => {
                return (
                  <div
                    className="header-nav-items"
                    key={index}
                    onClick={() =>
                      (el.qr && this.handleShareClick()) ||
                      (el.telegram && this.handleTelegramClick(el.link)) ||
                      this.handleHeaderLinks(el.link)
                    }
                  >
                    {el.qr || el.telegram || el.users ? (
                      <Icon component={el.icon}></Icon>
                    ) : (
                      <Icon>
                        <img src={el.icon} height={25} width={25} alt="icon" />
                      </Icon>
                    )}

                    <div className="header-link-title">
                      {t(`menu.${el.title}`)}
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div
            className="header-nav-items bottom-exit"
            onClick={this.handleLogout}
          >
            <Icon>
              <img src={logout} height={25} width={25} alt="logout" />
            </Icon>
            <div className="header-link-title">{t("modal.Logout")}</div>
          </div>
        </div>
      </Zoom>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu: state.ui && state.ui.menuAnimation,
    ui: state.ui,
    role_id: state.user && state.user.role_id,
    services: state.user && state.user.services,
    info: state.user && state.user.info,
  };
};
export default withTranslation()(
  connect(mapStateToProps, {
    changeMenuState,
    setUserProfile,
    openModal,
    updateService,
    openDrawler,
    getBotSecret,
  })(HeaderMenu)
);
