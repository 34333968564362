import React from "react";

function agendaTemplate() {
  return (
    <div className="agenda-adv-data">
<ol><li><p ><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>TERMS
AND CONDITIONS OF USING THE AMBASERA WEB-APPLICATION</b></span></font></font></font></p>
</li></ol>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
Terms used</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
Terms and conditions related to User registration.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
Rules of invitation of the Ambassador.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">4.
Rights and obligations of the User.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">5.
Rights and responsibilities of the Administration of the
web-application "Ambasera"</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">6.
Restrictions and prohibitions.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">7.
Responsibility. Restrictions are responsibility</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">.</span></font></font></font></p>
<p lang="uk-UA" >
<br />
<br />

</p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>1.
TERMS USED</b></span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>
</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>Web-application
"Ambasera"</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- a partner referral program for interaction between the User and the
Ambassador to attract customers to the services of the User at
https://ambasera.com/.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>User</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- a legal entity or sole proprietor who proposes to conclude a Public
Accession Agreement in order to attract new customers to provide
their own services (for example, services in the field of child
development).</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>Ambassador
of the User</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- an individual who has accepted the Public Accession Agreement and
received a personal QR-code to attract new
customers:Амбасадор-клієнт–&nbsp;parents of a
student who already uses the services of the User.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>Ambassador-client</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- parents of a student who already uses the services of the User.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>.
External Ambassador</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- any third adult who is not related to the services of the User.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>Ambassador-employee</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- employee of the User3. Authorization - login to the User's personal
account by using google id or apple id to go to the web-application
"Ambasera".</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">4.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>Authorization</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- login to the User's personal account by using google id or apple id
to go to the web-application "Ambasera".</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">5.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>Administration
of the web-application "Ambasera"</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- a team of web-application developers who manage, perform technical,
organizational and other functions to ensure the functioning of the
web-application "Ambasera", as well as provide
informational, consulting and other support to users.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">6.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>Account</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">-account
containing information that the User reported to the Administration
during the registration process in the web-application "Ambasera".6.
QR code - a personal link to the web-application "Ambasera"
with a unique number that allows you to identify (identify) the User
/ Ambassador.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">7.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>QR
code</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- a personal link to the web-application "Ambasera" with a
unique number that allows you to identify (identify) the User /
Ambassador.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">8.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>Referral</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- a new client of the User, attracted by the Ambassador.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">9.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>Client</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">
- a new Referral attracted by the Ambassador, which can directly
identify the Ambassador who paid for the services of the User</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>2.
TERMS AND CONDITIONS RELATED TO USER REGISTRATION</b></span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
Registration of the User in the web-application "Ambasera"
is free, voluntary and is carried out at the address on the Internet
at the link https://ambasera.com/ or by invitation via QR-code.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
To register in the Ambasera web application, the User must enter his
company data:</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
telephone number of the person registering the service;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
E-mail;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
public name;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">4.
logo;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">5.
pass phone verification via SMS confirmation;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">6.
select the country of presence.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
The use of the functionality of the web-application "Ambasera"
is allowed only after:</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
authorizations in the web-application "Ambasera";</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
filling in the Application by the User;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
creation of the Account in accordance with the procedure established
by the Administration of the web-application "Ambasera".</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">4.
The Application shall contain the following information about the
company providing the service:</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
legal name of the company;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
company registration address;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
company identification code;</span></font></font></font></p>
<p >
<font color="#000000"> <font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">4.telephone
number;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">5.video
link to the company;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">6.additional
information about the company.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">5.
Next, enter the financial information:</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
the currency of payments is specified;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
the amount of the Ambassador-Client's remuneration for attracting a
new Client - a consumer of the User's services;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
the amount of remuneration to the external Ambassador for attracting
a new Client - a consumer of the User's services;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">4.
the amount of remuneration of the Ambassador-employee of the User for
the new Ambassador who has attracted a new Client - a consumer of the
User's services;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">5.
the amount of the congratulatory reward for the new Ambassador,</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">6.
the amount of the discount for the new attracted Client,</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">7.
the amount of the minimum withdrawal amount.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">6.
After that, information about the director / founder of the company
is entered</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">.
telephone number;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
Name;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
E-mail.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">7.
The application must contain complete, accurate and reliable
information. The User is responsible for the completeness,
correctness and accuracy of the information and data contained in the
application.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">8.
Personal data of the User are contained in the User's Account, stored
and processed by the Administration of the web-application "Ambasera"
in accordance with the terms of the Privacy Policy.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">9.
The User agrees with the Administration of the Ambasera web
application to process his personal data and other data provided by
the User to the Administration of the Ambasera web application by
placing them in such web application in order to achieve the goals of
the web application.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">10.
Use of the web-application "Ambasera" is possible only
within the limits of its functionality:</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
invitation of the Ambassador to cooperate;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
change of the status of the referred Referral / Client of the
Ambassador, if necessary;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
review of information on the Ambassador and the Client / Referral.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>3.
RULES OF INVITATION AND REGISTRATION OF THE AMBASSADOR</b></span></font></font></font></p>
<ol>
<li><p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">The
ambassador of the web-application "Ambasera" can be any
individual who is registered and agrees to the processing of
personal data.</span></font></font></font></p>
</li><li><p ><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">By
inviting the Ambassador to cooperate, the User proposes the
conditions specified in the Application, which was filled in by the
User.</span></font></font></font></p>
</li><li><p ><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">The
invitation is made using a QR-code generated by the web-application
"Ambasera" in the personal account of the User.</span></font></font></font></p>
</li><li><p ><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">After
receiving the QR code, the Ambassador must:</span></font></font></font></p>
</li></ol>
<ol>
<li><p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">log
in with google id or apple id;</span></font></font></font></p>
</li><li><p ><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">choose
a location;</span></font></font></font></p>
</li><li><p ><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">pass
phone verification via SMS confirmation;</span></font></font></font></p>
</li><li><p ><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">sign
a public accession agreement.</span></font></font></font></p>
</li></ol>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>4.
RIGHTS AND OBLIGATIONS OF THE USER</b></span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>
</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">The
user has the right to:</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">Use
the Ambasera web application exclusively for personal use in
accordance with these Terms and Conditions.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
Free download and use the full functionality of the web-application
"</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">Ambasera".</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
The user undertakes:</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
properly comply with these Terms and Conditions;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
not to take actions that affect the normal operation of the
web-application "Ambasera" and are its unfair use;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
not to decompile the Ambasera web application or reverse-engineer the
Ambasera web application independently or with the involvement of
third parties;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">4.
use the Ambasera web application only for lawful purposes, comply
with the current legislation of Ukraine, as well as the rights and
legitimate interests of the Administration of the Ambasera web
application;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">5.
not to take actions aimed at gaining access to someone else's
personal account / account, by choosing a login and password, hacking
or other actions;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">6.
not to copy and not to use materials, information from the
web-application "Ambasera" for commercial purposes, both in
the web-application "Ambasera" and on other resources;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">7.
not to use independently or with the involvement of third parties
web-application "Ambasera" for the purpose which can be
qualified as infringement of the rights of the third parties on
objects of the intellectual property rights, unfair competition or
other infringement of the current legislation of Ukraine;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">8.
provide, upon request of the Administration of the Ambasera web
application, additional information that is directly related to the
work in the Ambasera web application;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">9.
comply with the conditions proposed during the registration in favor
of the Ambassador.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>5.
RIGHTS AND RESPONSIBILITIES OF THE AMBASERA WEB-APPLICATION
ADMINISTRATION</b></span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
The administration of the Ambasera web application has the right to:</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
reserve the right, in its sole discretion, to change or delete any
information published by the User, suspend, restrict or terminate the
User's access to the Ambasera web application at any time and without
explanation;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
place advertising and / or other information about the User anywhere
and anytime without the consent of the User;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
suspend the User's work in the web-application "Ambasera"
for violation and / or non-compliance with these Terms and
Conditions. At the same time, the Administration of the Ambasera
web-application is not responsible for any damage that may be caused
to the User by such actions;</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">4.
send informational messages to its Users using the Ambasera web
application, and the User also agrees to receive such informational
messages.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
The administration of the Ambasera web application undertakes:</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
provide the User with the opportunity to receive advice from the
support service. The scope of consultations is limited to issues
related to the work of the web-application "Ambasera";</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
collect, store and use personal data of the User in accordance with
the Law of Ukraine "On Personal Data Protection.</span></font></font></font></p>
<p lang="uk-UA" >
<br />
<br />

</p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>6.
RESTRICTIONS AND PROHIBITIONS</b></span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
It is prohibited to register a User whose services include the
promotion of goods and services of sexual and pornographic content.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
It is forbidden to create other Accounts with one mail and phone
number.</span></font></font></font></p>
<p ><a name="_GoBack"></a>
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
It is forbidden to indicate inaccurate information related to the
name of the company / brand, registration address and the actual
location of the company</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">4.
When contacting the chat support prohibited: derogatory expressions,
insults, vulgar or obscene text; unreadable signs and symbols; web
addresses, telephone numbers, contact details; advertising texts.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">5.
It is forbidden to collect and store personal data of other persons
without authorization.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">6.
All objects available through the Ambasera web application, including
design elements, text, graphics, illustrations, videos, databases,
music, sounds and other objects (hereinafter - the content of the web
application "Ambasera"), as well as any content posted in
the web-application are the subject of exclusive property rights of
the Administration of the web-application "Ambasera".</span></font></font></font><span lang="en-US">
</span><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">The
use of content as well as any other elements of the Ambasera web
application is prohibited. Use is possible only within the
functionality offered by the web-application "Ambasera". </span></font></font></font>
</p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">7.No
content of the Ambasera Web Application, as well as any content
hosted on the Ambasera Web Application, may be used in any other way
without the prior permission of the Ambasera Web Application
Administration. By use we mean, including: reproduction, copying,
processing, distribution on any basis, etc.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA"><b>7.
RESPONSIBILITY. LIMITATIONS OF LIABILITY.</b></span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">1.
The administration of the Ambasera web application is not responsible
for the compliance of the Ambasera web application in whole or in
part with the expectations of the User, and does not reimburse the
User for any related damages.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">2.
The Administration is not responsible for the small number or absence
of orders for the User's services, unreasonable expectations and
possible losses in the process of working in the web-application
"Ambasera".</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">3.
The Administration is not liable to the User for the restriction or
termination of access to its services, if these restrictions and
terminations arose due to force majeure circumstances, the occurrence
of which could not be influenced by the Administration.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">5.
The Administration is not liable to the User or third parties for
direct and / or indirect damages, including lost profits or lost
data, damage to honor, dignity or business reputation, incurred in
connection with his use of the web-application "Ambasera",
or impossibility of their use, or unauthorized access to
communications of the User by third parties.</span></font></font></font></p>
<p >
<font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">6.
The user is responsible to third parties for their actions related to
the use of the web-application "Ambasera", including if
such actions violate the rights and legitimate interests of third
parties, as well as for non-compliance with the law when using web-
Ambasera application.</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="en-US">
</span></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size="2" ><span lang="uk-UA">In
case of non-compliance with the conditions of clause 4.2 and clause 6
of these Rules, the User shall reimburse the Administration of the
Ambasera web-application for losses in the amount of UAH 20,000
(twenty thousand).</span></font></font></font></p>

    </div>
  );
}

export default agendaTemplate;
