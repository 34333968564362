import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./languages/en";
import ua from "./languages/ua";
import ru from "./languages/ru";

let domain = "com";
// const schema = {
//   ua: { en, ua },
//   com: { en, ua },
// };
// if (!isNaN(+domain)) domain = "ua";
// if (!schema[domain]) domain = "ua";
i18n.use(initReactI18next).init({
  resources: { en, ua, ru },
  lng: domain === "com" ? "en" : domain,
  interpolation: {
    escapeValue: false,
  },
});
const localStorageLang = localStorage.getItem("lang");
const langToUse = !localStorageLang ? "en" : localStorageLang;
i18n.changeLanguage(langToUse);
if (!localStorageLang) localStorage.setItem("lang", langToUse);
export default i18n;
