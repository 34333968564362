import Types from "./../constants/modal";
const defaultState = {
  open: false,
  title: "",
  type: "",
  data: null,
  snackbar: {
    message: "",
    open: false,
  },
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.OPEN:
      return { ...state, open: true, ...action.modal };
    case Types.CLOSE:
      return { ...defaultState };
    case Types.SNACKBAR:
      return { ...state, snackbar: action.modal.snackbar };
    default:
      return state;
  }
};

export default reducer;
