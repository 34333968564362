import Types from "./../constants/drawler";
const defaultState = {
  open: false,
  id: null,
  title: null,
  icon: null,
  role: null,
  qr_type: null,
  grant_role: null,
  qr: null,
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.OPEN:
      return { ...state, ...action.drawler, open: true };
    case Types.CLOSE:
      return { ...defaultState };
    default:
      return state;
  }
};

export default reducer;
