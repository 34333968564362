const def = {
  "/login/:role": {
    header: "235px",
    container: "220px",
  },
  "/login/:role/:qr": {
    header: "235px",
    container: "220px",
  },
  "/home": {
    header: "125px",
    container: "70px",
    data_header: "235px",
    data_container: "180px",
  },
  "/service/home": {
    header: "125px",
    container: "70px",
    data_header: "235px",
    data_container: "180px",
  },
  "/service/users": {
    header: "125px",
    container: "70px",
    data_header: "235px",
    data_container: "180px",
  },
  "/withdraw/payment-confirm": {
    header: "125px",
    container: "70px",
    data_header: "235px",
    data_container: "180px",
  },
  "/invite/:id": {
    header: "125px",
    container: "70px",
    data_header: "235px",
    data_container: "180px",
  },
  "/user/register": {
    header: "235px",
    container: "220px",
  }
};

export default def;
