import Types from "../constants/contracts";

export function changeContracts(contracts, data) {
  return (dispatch, getState) => {
    const list =
      contracts &&
      contracts.data &&
      contracts.data.map((el) => {
        return {
          lead: {
            id: el.receiver,
            first_name: el.receiver_fn,
            last_name: el.receiver_ln,
            phone: el.phone,
            created: el.created
          },
          ambassador: {
            id: el.creator,
            first_name: el.creator_fn,
            last_name: el.creator_ln,
          },
          status: el.client_status,
          payment: false,
          pay_day: el.created,
          client_status_changed: el.client_status_changed,
          role: el.receiver_role_index,
          creater_role: el.creator_role_index,
        };
      });
    dispatch({
      type: Types.DATA,
      payload: {
        list,
        count: contracts.count,
        ...data,
      },
    });
  };
}

export function clearContracts() {
  return (dispatch) => {
    dispatch({
      type: Types.CLEAR,
    });
  };
}
