import React from "react";
import { connect } from "react-redux";
import { setUserProfile } from "../../../actions/user";
import Button from "@mui/material/Button";

import { setFilterParams } from "../../../actions/ui";
import { withTranslation } from "react-i18next";

class AuthAlertModal extends React.Component {

  handleLogOut = () => {
    this.props.setUserProfile();
    this.props.closeRequestModal();
  };

  continueWithOld = () => {
    this.props.history.push(this.props.data.next);
    this.props.closeRequestModal();
  };

  render() {
    const { t } = this.props;
    return (
      <div className="modal-window">
        <div className="modal-window-info cookies">
          <div className="info-text">
            <h2>{t("modal.Attention")}</h2>
            <h3>{t("modal.already_logged")}</h3>
          </div>
        </div>
        <div className="modal-buttons-container">
          <Button className="modal-buttons no" onClick={this.handleLogOut}>
            {t("modal.Logout")}
          </Button>
          <Button
            className="modal-buttons yes orange"
            onClick={this.continueWithOld}
          >
            {t("modal.Continue")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default withTranslation()(
  connect(mapStateToProps, { setFilterParams, setUserProfile })(AuthAlertModal)
);
