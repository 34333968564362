const def = [
  "/brands",
  "/brands/:brand",
  "/brands/:brand/:info",
  "/brands/:brand/:code",
  "/home",
  "/withdraw/add-card",
  "/withdraw/payment-confirm",
  "/invite/:id",
  "/user/home",
  "/service/agenda",
  "/service/home",
  "/service/users",
  "/service/user/:id",
  "/service/reg/1",
  "/service/reg/2",
  "/service/reg/3",
  "/service/reg/4",
  "/service/reg/5",
];

export default def;
