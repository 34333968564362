import React from "react";

function agendaTemplate() {
  return (
    <div className="agenda-adv-data privacy-policy">
      <p align="center">
        <font face="Times New Roman, serif">
          <font size="3">
            <span lang="uk-UA">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ </span>
          </font>
        </font>
        <font face="Times New Roman, serif">
          <font size="3">
            <span lang="uk-UA">
              ТА ЗАХИСТУ ПЕРСОНАЛЬНИХ ДАНИХ
              <br />
              (надалі – «Політика»)
            </span>
          </font>
        </font>
      </p>
      <p lang="uk-UA" align="justify">
        <br />
        <br />
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Загальні положення</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Управління цим сайтом </span>
            </font>
          </font>
        </font>
        <span>
          <font size="3">
            <span lang="uk-UA">
              <font face="Times New Roman, serif">https://</font>
            </span>
          </font>
          <font size="3">
            <span lang="en-US">
              <font face="Times New Roman, serif">ambasera</font>
            </span>
          </font>
          <font size="3">
            <span lang="uk-UA">
              <font face="Times New Roman, serif">.</font>
            </span>
          </font>
          <font size="3">
            <span lang="en-US">
              <font face="Times New Roman, serif">com</font>
            </span>
          </font>
          <font size="3">
            <span lang="uk-UA">
              <font face="Times New Roman, serif">/</font>
            </span>
          </font>
        </span>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                (далі – «Сайт») здійснюється ФОП&nbsp;Новосьолов Юрій
                Олександрович (ідентифікаційний код 2643144435), особою, яка
                зареєстрована і діє відповідно до вимог законодавства України
                (далі – «Адміністратор»).
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Адміністратор з великою повагою ставиться до конфіденційної
                (персональної) інформації всіх без винятку осіб, які відвідали
                Сайт, а також тих, хто користується наданими Сайтом сервісами; у
                зв'язку із чим, Адміністратор прагне захищати конфіденційність
                персональних даних (відомостей чи сукупність відомостей про
                фізичну особу, яка ідентифікована або може бути конкретно
                ідентифікована), тим самим створивши і забезпечивши максимально
                комфортні умови використання сервісів Сайту кожному
                користувачеві.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                У цій Політиці встановлено порядок здійснення Адміністратором
                обробки персональних даних, види персональних даних, які
                збираються, цілі використання таких персональних даних, заходи
                безпеки для захисту персональних даних, а також інформація для
                користувача щодо внесення змін, блокування або видалення своїх
                персональних даних та звернення з будь-якими питаннями, які
                можуть виникнути у Вас щодо практики захисту персональних даних.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <a name="_GoBack"></a>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Збір та використання персональних даних</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Володільцем персональних даних користувачів Сайту є
                Адміністратор. Володілець персональних даних може доручити
                обробку персональних даних Розпоряднику персональних даних
                відповідно до договору, укладеного в письмовій формі, при цьому
                Розпорядник персональних даних може обробляти персональні дані
                лише з метою і в обсязі, визначених у договорі.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                При використанні користувачем сервісів Сайту Адміністратором або
                Розпорядником здійснюється обробка даних користувача, у тому
                числі даних, що надаються користувачем як при заповненні
                реєстраційних форм, так і в процесі користування сервісами,
                зокрема файли cookie. Адміністратор збирає тільки ті персональні
                дані, які свідомо і добровільно надані Вами як суб'єктом
                персональних даних в цілях використання сервісів Сайту, що
                відповідно до вимог законодавства є згодою суб'єкта персональних
                даних на обробку своїх персональних даних відповідно до
                сформульованої в цій Політиці мети їх обробки.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                При відвідуванні Сайту фіксуються всі входи до системи. Інші
                відомості по трафіку користувача не обробляються і не
                зберігаються. Звертаємо Вашу увагу: Адміністратор обмежується
                збором мінімального обсягу інформації, необхідного виключно для
                виконання запиту суб'єкта персональних даних. У будь-якому
                випадку, коли запитується необов'язкова до надання інформація,
                користувача буде повідомлено в момент збору такої інформації.
                Адміністратор не збирає будь-яку інформацію, до обробки якої,
                законодавством встановлені певні вимоги, зокрема відповідно до
                статті 7 Закону України «Про захист персональних даних».
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Цілі використання персональних даних</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Персональні дані використовуються в цілях забезпечення надання
                Інтернет-сервісів Сайту, обміну інформацією, відносин у сфері
                реклами, розвитку та комунікації відповідно та на виконання
                законів України, у тому числі, але не виключно: «Про захист
                персональних даних», «Про інформацію», «Про рекламу», а також
                відповідно до Правил та умов використання сайту та інших актів,
                що регулюють діяльність ФОПа.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Термін зберігання персональних даних</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Персональні дані зберігаються на термін не більше, ніж це
                необхідно відповідно до мети їх обробки. Після того, як суб'єкт
                персональних даних перестав бути користувачем Сайту шляхом
                подання відповідного запиту на видалення його акаунту до
                Адміністрації Сайту, його персональні дані також видаляються.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Використання файлів cookie</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Cookie – це текстовий файл або файли, що містять невеликий обсяг
                інформації, які надсилаються веб-браузеру і зберігаються на
                пристрої користувача. До таких пристроїв можна віднести
                комп'ютер, мобільний телефон або інший пристрій, за допомогою
                якого користувач відвідує Сайт. Файли cookie можуть бути вічними
                і зберігатися в комп'ютері, поки користувач їх не видалить або
                тимчасовими, тобто зберігаються тільки до закриття браузера.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">!!!Важливо:</span>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA"></span>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                при повторному відвідуванні користувачем Сайту, дані файлів
                cookie оновлюються; у більшості випадків, веб-браузер за
                замовчуванням допускає автоматичне зберігання файлів cookie на
                пристрої користувача; відключення файлів cookie може призвести
                до обмеження доступу до опублікованих матеріалів та/або
                неповноцінного функціонування сервісів Сайту.{" "}
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Взаємодія Сайту з іншими ресурсами</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                При використанні користувачем сервісів на сторінках Сайту можуть
                бути присутніми коди інших інтернет ресурсів і третіх осіб, в
                результаті чого такі інтернет ресурси і треті особи отримують
                Ваші дані. Отже, ці інтернет-ресурси можуть отримувати і
                обробляти інформацію, про те, що Ви відвідали ці сторінки, а
                також іншу інформацію, яку передає браузер користувача. Такими
                інтернет-ресурсами можуть бути соціальні плагіни мереж
                (наприклад, Facebook, Twitter, Google+,{" "}
              </span>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="en-US">WhatsApp</span>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">, Vibe</span>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="en-US">r</span>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                , Telegram та ін.). Використання зазначених сервісів
                Адміністратору необхідно для оперативного аналізу активності
                користувачів. Дані, отримані від зазначених сервісів
                Адміністратор не зберігає і не обробляє. Відповідно, якщо
                користувач в силу будь-яких причин не бажає, щоб зазначені
                сервіси отримували доступ до його персональних даних, користувач
                може за власним бажанням видалити свій акаунт чи профіль,
                очистити файли cookie (через свій браузер).
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Безпека неповнолітніх</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Сайт не призначений для неповнолітніх користувачів.
                Адміністратор дуже серйозно ставиться до питань безпеки,
                особливо щодо осіб, які не досягли повноліття, у зв'язку із чим,
                зі свого боку, Адміністратор звертається до батьків із закликом
                пояснити своїм дітям про проблеми безпеки в Інтернеті, про їх
                конкретну мету і потребу у використання тих чи інших сервісів
                Сайту.{" "}
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Взаємодія Адміністратора з третіми особами стосовно персональних
                даних
              </span>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">.</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Адміністратор не здійснює передачу персональних даних третім
                особам, крім випадків, коли така передача є вимогою
                законодавства, на прохання суб'єкта персональних даних або в
                інших випадках, викладених в цій Політиці. Адміністратор
                розуміє, що особиста інформація є цінністю і невід'ємним
                змістом, в тому числі, особистих немайнових прав будь-якої
                фізичної особи, тому вживає всіх можливих заходів для захисту
                особистої інформації користувачів, добровільно і усвідомлено
                переданої останніми Адміністратору.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                На Сайті можуть бути посилання на інші веб-сайти (виключно в
                інформаційних цілях). При переході по посиланню на інші
                веб-сайти дія цієї Політики на такі сайти поширюватися не буде.
                У зв'язку з чим, Адміністратор рекомендує переглядати політику в
                сфері конфіденційності і персональних даних кожного веб-сайту
                перед тим, як передавати будь-які персональні дані, за якими Вас
                можуть ідентифікувати.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Конфіденційність активності суб'єкта персональних даних на Сайті
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Відомості про активність (трафік) на Сайті користувачів, які
                проходять через мережу, або електронну пошту користувача, або{" "}
              </span>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="en-US">QR</span>
            </font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">-</font>
          </font>
        </font>
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                код захищені відповідно до законодавства. Тобто, Адміністратор
                жодним чином або способом не порушує таємницю «активності»
                користувача при використанні останнім сервісів Сайту.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Захист персональних даних</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Адміністратор використовує загальноприйняті стандарти
                технологічного та операційного захисту інформації та
                персональних даних від втрати, неправильного використання, зміни
                або знищення. Однак, не дивлячись на всі зусилля, Адміністратор
                не може гарантувати абсолютну захищеність від будь-яких загроз,
                що виникають поза межами регулювання Адміністратору.{" "}
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Адміністратор забезпечує застосування всіх відповідних
                зобов'язань щодо дотримання конфіденційності, а також технічних
                і організаційних заходів безпеки для запобігання
                несанкціонованого або незаконного розголошення або обробки такої
                інформації та даних, їх випадкової втрати, знищення або
                пошкодження.{" "}
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Адміністратор надає доступ до інформації і персональних даних
                тільки уповноваженим працівникам, які дали згоду на забезпечення
                конфіденційності такої інформації та даних відповідно до вимог
                Адміністратору.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                Поширення персональних даних без згоди суб'єкта персональних
                даних або уповноваженої ним особи дозволяється у випадках,
                визначених законом, і лише (якщо це необхідно) в інтересах
                національної безпеки, економічного добробуту та прав людини.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">Зміна Політики</span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                До цієї Політики періодично та без попереднього повідомлення
                користувача можуть вноситись зміни та доповнення, у тому числі,
                при зміні вимог законодавства.
              </span>
            </font>
          </font>
        </font>
      </p>
      <p align="justify">
        <font color="#000000">
          <font face="Times New Roman, serif">
            <font size="3">
              <span lang="uk-UA">
                У випадку внесення суттєвих змін до цієї Політики
                Адміністратором буде розміщено повідомлення на Сайті та
                зазначено термін набрання цими змінами чинності. Якщо протягом
                зазначеного терміну Ви не відмовитеся від їх прийняття в
                письмовій формі, це означитиме, що Ви погоджуєтеся з
                відповідними змінами Політики. Просимо час від часу переглядати
                Політику для того, щоб бути в курсі будь-яких змін або
                доповнень.
              </span>
            </font>
          </font>
        </font>
      </p>
    </div>
  );
}

export default agendaTemplate;
