import React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { closeDrawler, openDrawler } from "../../actions/drawler";
import store from "../../store";
import Icon from "@mui/material/Icon";
import arrow_down from "./../../resources/img/icons/arrow-down.svg";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ShareButtons from "./share";
import Stack from "@mui/material/Stack";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import Chip from "@mui/material/Chip";

const drawerBleeding = 56;

const Root = styled("div")(({ theme, ...custom }) => {
  return {
    zIndex: !custom.open ? "-1000" : "1000",
    height: "100%",
    backgroundColor:
      theme.palette.mode === "light"
        ? grey[100]
        : theme.palette.background.default,
  };
});

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const link_type = {
  1: "multi_use",
  2: "single_use",
};

const role = {
  4: "client",
  3: "ambasador",
  2: "worker",
};

function SwipeableEdgeDrawer(props) {
  const { window, drawler, i18n } = props;
  let timeout = null;
  const [copied, setCopied] = React.useState(false);
  const origin = document.location.origin;
  let link =
    drawler.role === 0
      ? `${origin}/login/ambassador/${drawler.qr}`
      : `${origin}/invite/${drawler.qr}`;

  if (
    drawler.service &&
    drawler.service.role === 3 &&
    drawler.title === "Ambasera"
  ) {
    link = `${origin}/login/service/${drawler.qr}`;
  }

  if (drawler.role === 0 && drawler.grant_role === 2)
    link = `${origin}/login/service/${drawler.qr}`;
  if (drawler.role === 3 && drawler.grant_role === 3)
    link = `${origin}/login/ambassador/${drawler.qr}`;

  const title = drawler.title;

  const icon = drawler.icon;

  const handleCopied = (newOpen) => () => {
    clearInterval(timeout);
    setCopied(newOpen);
    timeout = setTimeout(() => {
      setCopied(false);
    }, 5000);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const handleQrClick = () => {
    document.getElementById("copy-qr-button").click();
  };
  return (
    <Root open={drawler.open}>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: "visible",
            trasition: "all 0.2s",
            opacity: drawler.open ? "1" : "0",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={drawler.open}
        onClose={() => closeDrawler()(store.dispatch, store.getState)}
        onOpen={() => openDrawler()(store.dispatch, store.getState)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Typography
            sx={{
              p: 2,
              color: "text.secondary",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            {i18n.t("drawler.Scan the Qr Code")}
          </Typography>
          <Typography
            sx={{ position: "absolute", right: "25px", top: "15px" }}
            onClick={() => closeDrawler()(store.dispatch, store.getState)}
          >
            <Icon>
              <img src={arrow_down} height={25} width={25} alt="arrow_down" />
            </Icon>
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            height: "100%",
            overflow: "auto",
            textAlign: "center",
          }}
        >
          <Stack spacing={2} alignItems="center">
            <div
              style={{
                backgroundImage: `url(${icon})`,
              }}
              className="drawler-logo"
            >
              {!icon && title
                ? `${title.split("")[0]}${title.split("")[1]}`
                : null}
            </div>

            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "500",
                color: "#52B797",
              }}
            >
              {title}
            </Typography>
            <QRCode value={link} onClick={handleQrClick} />
            <Typography color="gray" variant="caption">
              {`${i18n.t(`drawler.${link_type[drawler.qr_type]}`)}, ${i18n.t(
                `drawler.${role[drawler.grant_role]}`
              )}`}
            </Typography>
            <CopyToClipboard
              text={link}
              id="copy-qr-button"
              onCopy={handleCopied(true)}
            >
              <div className={copied ? "drawler-copy copied" : "drawler-copy"}>
                <div>
                  {i18n.t(
                    copied ? "drawler.Copy Link Success" : "drawler.Copy Link"
                  )}
                </div>
                <div className="brand-contact-copy">
                  {copied ? <DoneAllIcon /> : <ContentCopyOutlinedIcon />}
                </div>
              </div>
            </CopyToClipboard>
            <Divider>
              <Typography
                sx={{
                  color: "text.secondary",
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                {i18n.t("drawler.Or Share it with")}
              </Typography>
            </Divider>
            <ShareButtons url={link} />
          </Stack>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

// SwipeableEdgeDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

export default SwipeableEdgeDrawer;
