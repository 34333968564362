import Types from "./../constants/contracts";
const defaultState = {
  list: false,
  count: null,
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.DATA:
      return { ...state, ...action.payload };
    case Types.CLEAR:
      return { ...defaultState };
    default:
      return state;
  }
};

export default reducer;
