import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { ConnectedRouter } from "connected-react-router";
import RootRoute from "./router";
import history from "./history";
import "./i18n.js";
import "./App.css";
import "./animations.css";
import 'react-phone-number-input/style.css'

import { subscribeUser } from "./subscription";

import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";

import LocalizationProvider from "@mui/lab/LocalizationProvider";

import AdapterDateFns from "@mui/lab/AdapterDateFns";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
  de: deLocale,
};

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap["ru"]}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <RootRoute />
      </ConnectedRouter>
    </Provider>
  </LocalizationProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

subscribeUser();
