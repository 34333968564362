import React, { Component } from "react";
import { connect } from "react-redux";
// import { Route, Redirect } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../../components/Header";
import HeaderMenu from "../../components/Header/Menu";
import { changeMenuState } from "../../actions/ui";
import Drawer from "../../components/Drawer";
import MuiDialog from "../../components/ModalComponents";
import { closeRequestModal, toogleSnackbar } from "../../actions/modal";
import Snackbar from "@mui/material/Snackbar";

class Layout extends Component {
  handleMenuClick = () => {
    if (this.props.menu) this.props.changeMenuState();
  };

  handleClassMainContaoner = () => {
    const menu = this.props.menu && " active";
    const drawler = this.props.drawler_open && " active-drawler";
    return `main-container${menu || drawler || ""}`;
  };

  render() {
    const handleClose = () => {
      this.props.toogleSnackbar({
        open: false,
        message: "",
      });
    };
    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    return (
      <>
        {this.props.menu ? <HeaderMenu {...this.props} /> : null}
        <div
          className={this.handleClassMainContaoner()}
          onClick={this.handleMenuClick}
        >
          <Header {...this.props} />
          <section className="main-data-container">
            {this.props.children}
          </section>
        </div>
        <div>
          <Drawer {...this.props} />
        </div>
        <MuiDialog {...this.props} />
        <Snackbar
          open={this.props.snackbar.open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={this.props.snackbar.message}
          action={action}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu: state.ui && state.ui.menu,
    drawler: state.drawler,
    drawler_open: state.drawler && state.drawler.open,
    modal: state.modal,
    snackbar: state.modal && state.modal.snackbar,
    payments: state.payments && state.payments.list,
  };
};
export default connect(mapStateToProps, {
  changeMenuState,
  closeRequestModal,
  toogleSnackbar,
})(Layout);
