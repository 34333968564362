import Types from "./../constants/service";
const defaultState = {
  1: {
    country: null,
    cities: [],
  },
  2: {
    legal_name: null,
    city: null,
    address: null,
    organization_code: null,
  },
  3: {
    contact_number: null,
    YouTube: null,
    WebLink: null,
    about: null,
  },
  4: {
    currency: null,
    bonus_amb_lead: null,
    bonus_out_amb: null,
    bonus_employee: null,
    bonus_new_amb: null,
    min_withdraw: null,
    client_discount: null,
  },
  5: {
    phone_owner: null,
    first_name_owner: null,
    last_name_owner: null,
    email_owner: null,
  },
};

const data = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SETDATA:
      return { ...state, ...action.service };
    case Types.CLEAR_SERVICE_DATA:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default data;
