import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../../onSubmit";
import Icon from "@mui/material/Icon";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

import InputAdornment from "@mui/material/InputAdornment";

import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { withTranslation } from "react-i18next";
import { serviceRegisterSet } from "../../../../actions/register";
import FormControl from "@mui/material/FormControl";
import { handleBack } from "../../../../actions/register";
import phone from "./../../../../resources/img/icons/phone.svg";
import user from "./../../../../resources/img/icons/user.svg";
import email from "./../../../../resources/img/icons/email.svg";

import Select from "@mui/material/Select";

class ServiceRegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.props.initialize({
      phone_owner: this.props.service.phone_owner || "",
      first_name_owner: this.props.service.first_name_owner || "",
      last_name_owner: this.props.service.last_name_owner || "",
      email_owner: this.props.service.email_owner || "",
    });
  }

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  renderPhoneMask = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="UA"
        limitMaxLength
        className={touched && !!error && error && "error"}
        {...input}
        {...custom}
      />
    );
  };

  renderSelect = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <FormControl variant="outlined" required>
        <Select
          native
          {...custom}
          {...input}
          inputProps={{
            name: input.name,
          }}
        >
          {custom.data.map((item, key) => (
            <option key={key} value={item.title}>
              {item.title}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  };

  handleClickLogin = () => {
    this.props.history.push("/login/service");
  };

  handleBack = () => {
    let item = this.props.match.params.page;
    item = `/service/reg/${item - 1}`;
    if (+item === 1) item = "/service/phone";
    this.props.history.push(item);
  };

  render() {
    const { t } = this.props;
    return (
      <div className="login-data-container">
        <Slide in timeout={500}>
          <div className="select-auth-method">
            <Form onSubmit={this.props.handleSubmit}>
              <div className="row item full select">
                <Field
                  name="phone_owner"
                  component={this.renderPhoneMask}
                  placeholder={t("reg.Phone Number")}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <img src={phone} height={25} width={25} alt="phone" />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>
              <div className="row item full select">
                <Field
                  name="first_name_owner"
                  component={this.renderTextField}
                  placeholder={t("reg.First Name")}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <img src={user} height={25} width={25} alt="user" />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>
              <div className="row item full select">
                <Field
                  name="last_name_owner"
                  component={this.renderTextField}
                  placeholder={t("reg.Last Name")}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <img src={user} height={25} width={25} alt="user" />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>
              <div className="row item full select">
                <Field
                  name="email_owner"
                  type="email"
                  component={this.renderTextField}
                  placeholder={t("reg.Email")}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <img src={email} height={25} width={25} alt="email" />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>
              <Box className="light center margin-large txt14 flex">
                <Button
                  className="orange rounded center back-button"
                  onClick={() => handleBack()}
                >
                  {t("reg.back")}
                </Button>
                <Button className="orange rounded center" type="submit">
                  {t("reg.next")}
                </Button>
              </Box>
            </Form>
          </div>
        </Slide>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    service: state.service && state.service[5],
  };
};

const validate = (values) => {
  const errors = {};
  if (!isPossiblePhoneNumber(values.phone_owner || "")) {
    errors.phone_owner = "Incorrect prone number";
  }
  return errors;
};

const withForm = reduxForm({
  form: "ServiceRegisterPage5",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: serviceRegisterSet,
});

export default withTranslation()(
  connect(mapStateToProps, { serviceRegisterSet })(
    withForm(ServiceRegisterComponent)
  )
);
