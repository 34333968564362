import React from "react";

function agendaTemplate({ amb, service, info }) {
  const data = service && service.data && service.data.service;
  console.log(data);
  console.log(amb);
  return (
    <div className="agenda-adv-data">
      <p align="center">
        <font face="Times New Roman, serif">
          <span lang="uk-UA">
            <b>Публічний договір приєднання</b>
          </span>
        </font>
      </p>
      <p align="center">
        <font face="Times New Roman, serif">
          <span lang="uk-UA">
            <b>(далі – Договір)</b>
          </span>
        </font>
      </p>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <table cellPadding="7" cellSpacing="0">
        <colgroup>
          <col />

          <col />
        </colgroup>
        <tbody>
          <tr valign="top">
            <td>
              <p>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <b>
                        <span>Користувач</span>
                      </b>
                    </span>
                  </font>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    <b></b>
                  </span>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <b>
                        <span> web-додатку «Ambasera»</span>
                      </b>
                    </span>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p lang="uk-UA" align="justify">
                <br />
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">Публічна назва</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.public_name}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">
                        Інформація про послуги Користувача{" "}
                      </span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.about.en}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">Юридична назва</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.legal_name}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">Адреса реєстрації</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.legal_address}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">Код ЄДРПОУ</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.okpo}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">Номер телефону</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.phone}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">E-mail</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.email}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">Веб-сайт</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.weblink}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">ПІБ директора/засновника</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">
                        {data &&
                          `${data.first_name_owner} ${data.last_name_owner}`}
                      </span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">
                        Номер телефону директора/засновника
                      </span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.phone_owner}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">E-mail директора/засновника</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.email_owner}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <table cellPadding="7" cellSpacing="0">
        <colgroup>
          <col />

          <col />
        </colgroup>
        <tbody>
          <tr valign="top">
            <td>
              <p>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    <b>Амбасадор</b>
                  </span>
                </font>
              </p>
            </td>
            <td>
              <p lang="uk-UA" align="justify">
                <br />
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="en-US">Google id/ apple id</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="en-US">{amb && amb.id}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">Номер телефону</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{info && info.phone}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">E-mail</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{amb && amb.email}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">Валюта платежу</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.currency}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          {false && (
            <tr valign="top">
              <td>
                <p>
                  <font color="#000000">
                    <font face="Times New Roman, serif">
                      <font size="2">
                        <span lang="uk-UA">
                          Розмір винагороди для Амбасадора-клієнта
                        </span>
                      </font>
                    </font>
                  </font>
                </p>
              </td>
              <td>
                <p>
                  <font color="#000000">
                    <font face="Times New Roman, serif">
                      <font size="2">
                        <span lang="uk-UA">{data && data.amb_cl_fee}</span>
                      </font>
                    </font>
                  </font>
                </p>
              </td>
            </tr>
          )}

          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">
                        Розмір винагороди для зовнішнього Амбасадора
                      </span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.amb_fee}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">
                        Розмір винагороди для Амбасадора-співробітника
                      </span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.worker_fee}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">
                        Розмір вітальної винагороди для нового Амбасадора
                      </span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.new_amb_bonus}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">
                        Мінімальна сума винагороди для зняття коштів
                      </span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.min_withdraw}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">
                        Знижка для нового залученого Клієнта (Ліда).
                      </span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td>
              <p>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <font size="2">
                      <span lang="uk-UA">{data && data.client_discount}</span>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>ОСНОВНІ ТЕРМІНИ</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <b>
                        <span>Web-додаток «Ambasera»</span>
                      </b>
                    </span>
                  </font>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <span>
                        – партнерська реферальна програма для взаємодії
                        Користувача та Амбасадора для залучення нових клієнтів
                        до послуг Користувача{" "}
                      </span>
                    </span>
                  </font>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">за посиланням </span>
                </font>
                <font color="#0000ff">
                  <u>
                    <a href="https://ambasera.com/">
                      <font face="Times New Roman, serif">
                        <span lang="uk-UA">https://ambasera.com/</span>
                      </font>
                    </a>
                  </u>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">(далі – Сайт)</span>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <span>.</span>
                    </span>
                  </font>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    <b>Адміністрація web-додатку «Ambasera»</b>
                  </span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">– </span>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <span>
                        співробітники власника web-додатку «Ambasera», що
                        здійснюють управління, виконують технічні, організаційні
                        та інші функції щодо забезпечення функціонування
                        web-додатку, а також надають інформаційну,
                        консультаційну та іншу підтримку Користувачу/Амбасадору.
                      </span>
                    </span>
                  </font>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <b>
                        <span>Користувач</span>
                      </b>
                    </span>
                  </font>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <span>
                        – юридична особа або ФОП, що пропонує укласти Публічний
                        договір приєднання з метою залучення нових клієнтів для
                        надання власних послуг (наприклад, послуг в сфері
                        розвитку дітей).
                      </span>
                    </span>
                  </font>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <b>
                        <span>Амбасадор</span>
                      </b>
                    </span>
                  </font>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <span></span>
                    </span>
                  </font>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <b>
                        <span>Користувача</span>
                      </b>
                    </span>
                  </font>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <span>
                        – фізична особа, яка акцептувала Публічний договір
                        приєднання та отримала персональний QR-код для залучення
                        нових клієнтів.
                      </span>
                    </span>
                  </font>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <b>Амбасадор-клієнт</b>
                    </span>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">– </span>
                  </font>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    батьки учня, який вже користується послугами Користувача.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <b>Зовнішній Амбасадор</b>
                    </span>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">– </span>
                  </font>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    будь-яка третя повнолітня особа, яка не має відношення до
                    послуг Користувача.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <b>Амбасадор-співробітник</b>
                    </span>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">– співробітник Користувача.</span>
                  </font>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    <b>QR-код</b>
                  </span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">– </span>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <span>персональне</span>
                    </span>
                  </font>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    посилання на web-додаток «Ambasera» з унікальним номером, що
                    дозволяє визначити (ідентифікувати) Користувача або
                    Амбасадора, як особу, яка залучила нового клієнта (Реферала/
                    Клієнта), та яка отримує винагороду за залучення такого
                    клієнту.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    <b>Реферал</b>
                  </span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    – новий клієнт Користувача, який залучено Амбасадором.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    <b>Клієнт</b>
                  </span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    – новий Реферал, залучений Амбасадором, за яким можна
                    визначити безпосередньо самого Амбасадора, та який здійснив
                    оплату за послуги Користувача.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    <b>Реферальний рахунок</b>
                  </span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    – фінансовий небанківський рахунок (баланс), який
                    відкривається для Амбасадора в{" "}
                  </span>
                </font>
                <font color="#333333">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      <span>web-додатку «Ambasera»</span>
                    </span>
                  </font>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    після проходження Амбасадором процедури реєстрації, що
                    відкривається для контролю коштів Амбасадора, і на якому
                    відображаються всі грошові операції.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    У цьому Договорі можуть бути використані інші терміни, не
                    визначені в цьому розділі. Тлумачення таких термінів
                    здійснюватиметься відповідно до змісту Договору та чинного
                    законодавства України. У разі відсутності однозначного
                    тлумачення терміну в тексті Договору та нормативно-правових
                    актах України, Сторони керуватимуться тлумаченням терміну,
                    що склалося в мережі Інтернет та у практиці ділового
                    обороту.
                  </span>
                </font>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol start="2">
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>ПРЕДМЕТ ДОГОВОРУ</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Користувач пропонує зацікавленій особі (в майбутньому –
                    Амбасадору) укласти Публічний договір приєднання
                    цивільно-правового характеру на умовах, викладених у цьому
                    Договорі. Відповідно до ст. 633 та ст. 634 Цивільного
                    кодексу України цей договір є публічним договором
                    приєднання. Договір є офіційним документом, який
                    публікується на Сайті.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Відповідно до ст. 642 Цивільного кодексу України цей Договір
                    вважається узгодженим та укладеним Амбасадором шляхом
                    вчинення ним дій, що свідчать про згоду дотримуватись умов
                    Договору, без підписання письмового примірника Сторонами.
                    Дії, що свідчать про згоду дотримуватись умов Договору, є
                    вчинення Амбасадором наступної дії: підтвердження такої
                    згоди Амбасадором на Сайті шляхом активації позначки «Я
                    приймаю всі загальні положення та умови».
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Укладання цього Договору здійснюється у порядку, визначеному
                    п.&nbsp;2.2. Договору, шляхом приєднання Амбасадора до цього
                    Договору, тобто шляхом прийняття (акцепту) Амбасадором умов
                    цього Договору загалом, без будь-яких умов, вилучень та
                    застережень. Укладаючи Договір, Амбасадор автоматично
                    погоджується з повним та безумовним прийняттям положень
                    Договору, у тому числі вартості робіт, та всіх додатків, які
                    є невід'ємною складовою Договору. Якщо особа не погоджується
                    з будь-якою з умов цього Договору або Договору в цілому, їй
                    необхідно утриматись від приєднання до Договору.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Приймаючи цей Договір, Амбасадор підтверджує, що:
                  </span>
                </font>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ul>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                має необхідний обсяг громадянської дієздатності;
              </span>
            </font>
          </p>
        </li>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                його волевиявлення є вільним та відповідає його внутрішній волі;
              </span>
            </font>
          </p>
        </li>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                готовий дотримуватись та виконувати всі умови цього Договору;
              </span>
            </font>
          </p>
        </li>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                не має наміру здійснювати будь-які дії, які б завадили діловій
                репутації Користувача та/або третіх осіб, пов'язаних договірними
                відносинами із Користувачем, або суперечать законодавству
                України, міжнародним нормам та звичаям ділового обороту;
              </span>
            </font>
          </p>
        </li>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                його дії спрямовані на отримання робіт і в них немає злого
                наміру, шахрайських намірів, спроб несанкціонованого доступу до
                інформації, яка є власністю Користувача;
              </span>
            </font>
          </p>
        </li>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                персональна інформація, що надається їм Користувачу, є
                правильною, повною та актуальною.
              </span>
            </font>
          </p>
        </li>
      </ul>
      <ol>
        <ol start="5">
          <li>
            <p align="justify">
              <font face="Times New Roman, serif">
                <span lang="uk-UA">
                  Амбасадор виконує роботи із залучення нових клієнтів для
                  послуг Користувача, а Користувач зобов'язується здійснювати
                  оплату винагороди Амбасадору на умовах та в порядку,
                  передбачених цим Договором.
                </span>
              </font>
            </p>
          </li>
        </ol>
      </ol>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol start="3">
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>ПОРЯДОК ОПЛАТИ ВИНАГОРОДИ</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Оплаті підлягає робота Амбасадора, внаслідок якої було
                    залучено нового Клієнта, який здійснив перехід на Сайт з
                    персонального QR-кода Амбасадора, зареєструвався на Сайті,
                    здійснив оплату послуг Користувача.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Винагорода Амбасадора розраховується, виходячи з умов, які
                    були зазначені на етапі реєстрації Користувача (розмір
                    винагороди).{" "}
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    При цьому, якщо сума винагороди за звітний місяць становить
                    суму меншу м
                  </span>
                </font>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      інімальної суми винагороди для зняття коштів (яка
                      зазначається на етапі реєстрації Користувача)
                    </span>
                  </font>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    виплата винагороди у звітному місяці не провадиться, а
                    зазначена сума має бути виплачена при досягненні м
                  </span>
                </font>
                <font color="#000000">
                  <font face="Times New Roman, serif">
                    <span lang="uk-UA">
                      інімальної суми винагороди для зняття коштів (яка
                      зазначається на етапі реєстрації Користувача).
                    </span>
                  </font>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Вся платіжна інформація щодо оплати робіт Амбасадора
                    відображається на його Реферальному рахунку та доступна
                    Амбасадору на Сайті.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Оплата провадиться за домовленістю Сторін. Фактичне
                    перерахування винагороди відбувається на вимогу Амбасадора.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Винагорода Амбасадора підлягає виплаті протягом 2 (двох)
                    банківських днів після отримання вимоги Амбасадора про
                    перерахування коштів.
                  </span>
                </font>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol start="4">
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>ПРАВА ТА ОБОВ'ЯЗКИ АМБАСАДОРА</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">Амбасадор має право:</span>
                </font>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <font face="Times New Roman, serif">
                      <span lang="uk-UA">
                        Отримувати винагороду у порядку та на умовах,
                        встановлених цим Договором.
                      </span>
                    </font>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <font face="Times New Roman, serif">
                      <span lang="uk-UA">
                        За наявності заперечення щодо розміру винагороди,
                        відображеної на Реферальному рахунку, надіслати
                        відповідний лист на електронну адресу Користувача із
                        зазначенням теми листа «Заперечення щодо винагороди».
                        Строк відповіді на заперечення Амбасадора складає 2
                        (два) робочих дні з дня одержання листа Користувачем.
                      </span>
                    </font>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <font face="Times New Roman, serif">
                      <span lang="uk-UA">
                        Амбасадор має право отримувати необхідне консультування
                        щодо послуг Користувача з метою виконання зобов'язань за
                        цим Договором.
                      </span>
                    </font>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">Амбасадор зобов'язується:</span>
                </font>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <font face="Times New Roman, serif">
                      <span lang="uk-UA">
                        Використовувати QR-кода Амбасадора виключно відповідно
                        до цього Договору та не використовувати його для
                        будь-яких інших цілей, не передбачених цим Договором.
                      </span>
                    </font>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <font face="Times New Roman, serif">
                      <span lang="uk-UA">
                        Діяти сумлінно та виключно на користь Користувача, не
                        вчиняти дій, здатних нашкодити діловій репутації
                        Користувача, знизити рівень довіри до Користувача або
                        ввести потенційних Клієнтів в оману щодо діяльності
                        Користувача.
                      </span>
                    </font>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <font face="Times New Roman, serif">
                      <span lang="uk-UA">
                        Сприяти підвищенню рівня довіри потенційних Клієнтів та
                        інших осіб до Користувача.
                      </span>
                    </font>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol start="5">
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>ПРАВА ТА ОБОВ'ЯЗКИ КОРИСТУВАЧА</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">Користувач має право:</span>
                </font>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <font face="Times New Roman, serif">
                      <span lang="uk-UA">
                        Без будь-яких обмежень, на свій розсуд, укладати
                        аналогічні договори з необмеженим колом осіб.
                      </span>
                    </font>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">Користувач зобов'язується:</span>
                </font>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <font face="Times New Roman, serif">
                      <span lang="uk-UA">
                        Своєчасно виплачувати Амбасадору винагороду, передбачену
                        умовами цього Договору.
                      </span>
                    </font>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <font face="Times New Roman, serif">
                      <span lang="uk-UA">
                        Надавати Амбасадору всю необхідну інформацію, необхідну
                        для залучення нових Клієнтів.
                      </span>
                    </font>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol start="6">
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>ВІДПОВІДАЛЬНІСТЬ СТОРІН ТА ВИРІШЕННЯ СПОРІВ</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Сторони несуть відповідальність відповідно до чинного
                    законодавства України.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Усі суперечки між Сторонами розглядаються шляхом переговорів
                    з урахуванням врегулювання конфлікту з максимальним
                    урахуванням інтересів Користувача та Амбасадора.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    У разі, якщо Сторони не зможуть дійти згоди з спірних питань
                    шляхом переговорів, такі суперечки підлягають передачі на
                    розгляд суду відповідно до чинного законодавства України.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Визнання судом недійсним будь-якого з положень цього
                    Договору не тягне за собою недійсність інших його положень
                    або Договору в цілому, а тому не веде до втрати ним
                    юридичної сили.
                  </span>
                </font>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol start="7">
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ (ФОРС-МАЖОР)</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Сторона звільняється від визначеної цим Договором та/або
                    чинним в Україні законодавством відповідальності за повне
                    або часткове порушення Договору, якщо вона доведе, що таке
                    порушення сталося внаслідок дії форс-мажорних обставин,{" "}
                  </span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    а саме: пожежі, стихійного лиха, збройного конфлікту,
                    перекриття шляхів руху транспорту внаслідок страйку, рішень
                    Уряду або інших обставин, які перебувають поза контролем
                    Сторін
                  </span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    , та за умови, що їх настання було засвідчено компетентним
                    органом, визначеним чинним законодавством України.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Сторона, яка має намір послатися на форс-мажорні обставини,
                    зобов'язана невідкладно з урахуванням можливостей технічних
                    засобів миттєвого зв'язку та характеру наявних перешкод
                    повідомити іншу Сторону про наявність форс-мажорних обставин
                    та їх вплив на виконання цього Договору.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Якщо форс-мажорні обставини та (або) їх наслідки тимчасово
                    перешкоджають виконанню цього Договору, то виконання цього
                    Договору припиняється на строк, протягом якого воно
                    неможливе.
                  </span>
                </font>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol start="8">
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>ТЕРМІН ДІЇ ТА РОЗІРВАННЯ ДОГОВОРУ</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Цей Договір укладається на строк з моменту вчинення
                    Амбасадором дій, передбачених у п.2.2.&nbsp;Договору, та діє
                    до 31 грудня 2030 року.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Цей Договір може бути розірваний до закінчення строку його
                    дії за взаємною згодою Сторін або в односторонньому порядку,
                    а також у випадках, передбачених цим Договором з дотриманням
                    умов цього Договору.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    У разі одностороннього розірвання цього Договору Сторона
                    повідомить про це іншу Сторону. При цьому Договір
                    вважатиметься розірваним з боку Користувача через три робочі
                    дні з дня направлення Амбасадору повідомлення на електронну
                    пошту.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Користувач має право розірвати цей Договір в односторонньому
                    порядку із здійсненням негайного блокування доступу
                    Амбасадора до Сайту у випадках:
                  </span>
                </font>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ul>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                здійснення дій, що можуть зашкодити діловій репутації
                Користувача та/або третіх осіб, пов'язаних договірними
                відносинами із Користувачем, або суперечать законодавству
                України, міжнародним нормам та звичаям ділового обороту;
              </span>
            </font>
          </p>
        </li>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                якщо Амбасадором зроблено спроби несанкціонованого доступу до
                інформації, яка є власністю Користувача/Адміністрації
              </span>
            </font>
            <font face="Times New Roman, serif">
              <span lang="uk-UA">web-додатку «Ambasera»</span>
            </font>
            <font face="Times New Roman, serif">
              <span lang="uk-UA">;</span>
            </font>
          </p>
        </li>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                при наданні Амбасадором невірної, неповної та/або неактуальної
                персональної інформації;
              </span>
            </font>
          </p>
        </li>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">ухвалення судом відповідного рішення;</span>
            </font>
          </p>
        </li>
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                в інших випадках, передбачених чинним законодавством України.
              </span>
            </font>
          </p>
        </li>
      </ul>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol start="9">
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>КОНФІДЕНЦІЙНІСТЬ ТА ПЕРСОНАЛЬНІ ДАНІ</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Вся інформація, яка стала відома Амбасадору у зв'язку з
                    виконанням робіт за цим Договором (зокрема паролі доступу до
                    Сайту) є конфіденційною інформацією та комерційною
                    таємницею.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Сторони зобов'язуються не розголошувати та не передавати
                    конфіденційну інформацію для ознайомлення та/або
                    використання третім особам без попередньої письмової згоди.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Амбасадор у зв'язку з укладанням цього Договору дає свою
                    згоду на обробку будь-яких персональних даних, які стали
                    відомими Користувачу/Адміністрації{" "}
                  </span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">web-додатку «Ambasera»</span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    внаслідок надання послуг на умовах цього публічного договору
                    приєднання. Обробка персональних даних включає, але не
                    обмежується збором, реєстрацією, накопиченням, зберіганням,
                    адаптацією, зміною, оновленням, використанням та
                    розповсюдженням (включаючи передачу), знеособлення, знищення
                    персональних даних, що обробляються Користувачем або
                    Адміністрацією{" "}
                  </span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">web-додатку «Ambasera»</span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    , пов'язаною із Користувачем відносинами контролю.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Амбасадор погоджується, що Користувач не повинен отримувати
                    жодної додаткової згоди Амбасадора для передачі персональних
                    даних Амбасадора будь-якій особі, пов'язаній із Користувачем
                    відносинами контролю або в рамках договірних відносин.
                  </span>
                </font>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p lang="uk-UA" align="justify">
        <br />
      </p>
      <ol start="10">
        <li>
          <p align="justify">
            <font face="Times New Roman, serif">
              <span lang="uk-UA">
                <b>ІНШІ УМОВИ</b>
              </span>
            </font>
          </p>
          <ol>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Сторони підтверджують повне та однозначне розуміння ними
                    предмета та змісту цього Договору, прав та обов'язків кожної
                    сторони, що випливають або пов'язані з виконанням Договору,
                    суть термінів, прийнятих у Договорі, а також відповідність
                    тексту Договору до намірів та волі Сторін. Договір
                    укладається на вигідних для Сторін умовах та не є
                    результатом впливу тяжких для них обставин.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    Закінчення терміну цього Договору не звільняє Сторони від
                    відповідальності за порушення, яке сталося під час дії цього
                    Договору.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    У випадках, що не передбачені цим Договором, Сторони
                    керуються чинним законодавством України.
                  </span>
                </font>
              </p>
            </li>
            <li>
              <p align="justify">
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">Користувач</span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">в</span>
                </font>
                <font face="Times New Roman, serif">
                  <span lang="uk-UA">
                    односторонньому порядку може внести зміни до умов цього
                    Договору. Зазначені зміни набирають чинності з дати
                    публікації. Якщо Амбасадор не погоджується з такими змінами,
                    то він протягом 10 (десяти) календарних днів після дати
                    опублікування нової редакції Договору надсилає відповідне
                    повідомлення на електронну пошту Користувача, інакше такі
                    зміни вважаються прийнятими Амбасадором.
                  </span>
                </font>
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}

export default agendaTemplate;
