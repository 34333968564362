import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import PhoneInput from "react-phone-number-input";

import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Slide from "@mui/material/Slide";
import { withTranslation } from "react-i18next";

import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import PageFive from "./PageFive";

class ServiceRegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  renderPhoneMask = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="UA"
        limitMaxLength
        {...input}
        {...custom}
      />
    );
  };

  handleClickLogin = () => {
    this.props.history.push("/login/service");
  };

  render() {
    const schema = {
      1: <PageOne {...this.props} />,
      2: <PageTwo {...this.props} />,
      3: <PageThree {...this.props} />,
      4: <PageFour {...this.props} />,
      5: <PageFive {...this.props} />,
    };
    return (
      <div className="login-data-container">
        <Slide in timeout={500}>
          <div>{schema[this.props.match.params.page]}</div>
        </Slide>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    oauth: state.user && state.user.oauth,
    ServiceRegister: state.form && state.form.ServiceRegister,
    image: state.user && state.user.service && state.user.service.logo,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {})(ServiceRegisterComponent)
);
