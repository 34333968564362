import history from "../history";
import Types from "../constants/service";
import { setServiceData, sendAuthRequest } from "./user";

export function registerUser(item) {
  return async (dispatch, getState) => {
    const { logo } = getState().user && getState().user.service;
    const message =
      getState().user && getState().user.info && getState().user.info.message;
    if (logo) item.logo = logo;
    if (item.phone_number) {
      item.phone_number = item.phone_number.replaceAll(" ", "");
      setServiceData(item)(dispatch, getState);
      history.push("/service/reg/1");
      return;
    }

    const auth = await sendAuthRequest(item, 2)(dispatch, getState);
    if (auth) setServiceData(item)(dispatch, getState);
    history.push("/user/phone");
  };
}

export function serviceRegisterSet(item) {
  return (dispatch, getState) => {
    const page = document.location.pathname.split("/")[
      document.location.pathname.split("/").length - 1
    ];
    const { service } = getState();
    dispatch({
      type: Types.SETDATA,
      service: {
        ...service,
        [page]: { ...service[page], ...item },
      },
    });
    if (+page === 5) {
      history.push(`/service/agenda`);
      return;
    }
    history.push(`/service/reg/${+page + 1}`);
  };
}

export function handleBack() {
  let page = document.location.pathname.split("/")[
    document.location.pathname.split("/").length - 1
  ];
  let item = `/service/reg/${page - 1}`;
  if (+page === 1) item = "/service/welcome";
  history.push(item);
}
