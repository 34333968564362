import Agenda from "./agenda";
import { changeMenuState } from "../../actions/ui";
import store from "../../store";

export default function privacyPolicy() {
  changeMenuState(false)(store.dispatch, store.getState);
  return (
    <div className="white-data-section">
      <Agenda />
    </div>
  );
}
