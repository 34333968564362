import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../../onSubmit";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { withTranslation } from "react-i18next";
import { serviceRegisterSet } from "../../../../actions/register";
import FormControl from "@mui/material/FormControl";
import { handleBack } from "../../../../actions/register";
import { Country, State, City } from "country-state-city";

import { Select, Autocomplete, TextField } from "@mui/material";

class ServiceRegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.props.initialize({
      country: this.props.service.country || null,
    });
  }

  renderSelect = ({ input, meta: { touched, error }, ...custom }) => {
    const { onChange, ...rest } = input;
    const getSelectedOption = () => {
      return custom.data.find(
        (o) => o.name === (input.value.name || input.value || null)
      );
    };
    return (
      <Autocomplete
        autoSelect
        value={getSelectedOption() || null}
        options={custom.data}
        autoHighlight
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => {
          console.log(newValue);
          onChange(newValue);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={input.value}
              variant="outlined"
              fullWidth
            />
          );
        }}
      />
    );
  };

  handleBack = () => {
    let item = this.props.match.params.page;
    item = `/service/reg/${item - 1}`;
    if (+item === 1) item = "/service/phone";
    this.props.history.push(item);
  };

  render() {
    const options = Country.getAllCountries();

    const { t } = this.props;
    return (
      <div className="login-data-container">
        <Slide in timeout={500}>
          <div className="select-auth-method">
            <Form onSubmit={this.props.handleSubmit}>
              <div className="row item full select">
                <div>{t("reg.Select Country")}</div>
                <Field
                  name="country"
                  component={this.renderSelect}
                  data={options}
                  variant="outlined"
                  required
                />
              </div>
              <Box className="light center margin-large txt14 flex">
                <Button
                  className="orange rounded center back-button"
                  onClick={() => handleBack()}
                >
                  {t("reg.back")}
                </Button>
                <Button className="orange rounded center" type="submit">
                  {t("reg.next")}
                </Button>
              </Box>
            </Form>
          </div>
        </Slide>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    service: state.service && state.service[1],
  };
};

const validate = (values) => {
  const errors = {};
  return errors;
};

const withForm = reduxForm({
  form: "ServiceRegisterPage1",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: serviceRegisterSet,
});

export default withTranslation()(
  connect(mapStateToProps, { serviceRegisterSet })(
    withForm(ServiceRegisterComponent)
  )
);
