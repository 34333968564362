import React from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import ButtonBase from "@mui/material/ButtonBase";
import { withTranslation } from "react-i18next";

class PhoneShowModal extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="modal-window">
        <div className="modal-window-info">
          <h2>{`${t("modal.service_phone")} ${
            this.props.data.serviceName
          }`}</h2>
          <a
            style={{ textDecoration: "underline" }}
            href={`tel: ${this.props.data.phone}`}
          >
            {this.props.data.phone}
          </a>
        </div>
        <div className="modal-buttons-container">
          <Button
            className="modal-buttons no"
            onClick={this.props.closeRequestModal}
            style={{ width: "100%" }}
          >
            {t("modal.Close")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default withTranslation()(connect(mapStateToProps, {})(PhoneShowModal));
