const def = {
  "/login/role": "/",
  "/login/role/qr": "/",
  "/auth/map": "/login/ambassador",
  "/auth/phone": "/login/ambassador",
  "/brands": "/auth/image",
  "/brands/brand": "/brands",
  "/auth/agenda": "/auth/phone",
  "/auth/image": "/login/ambassador",
  "/withdraw/add-card": "/home",
  "/withdraw/payment-confirm": "/home",
  "/user/register": "/invite!id",
  "/service/agenda": "/service/reg/5",
  "/service/user/id": "/service/home",
  "/edit": "/home",
  "/service/phone": "/service/welcome",
  "/service/users": "/service/home",
  "/service/reg/1": "/service/phone",
  "/service/reg/2": "/service/reg/1",
  "/service/reg/3": "/service/reg/2",
  "/service/reg/4": "/service/reg/3",
  "/service/reg/5": "/service/reg/4",
  "/service/edit": "/service/home",
  "/user/phone": "/user/register",
  "/privacy-policy": "/",
};

export default def;
