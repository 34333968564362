import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import mapframe from "./../../../resources/img/map-frame.svg";
import GoogleMap from "./googleMap";
import Box from "@mui/material/Box";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ButtonBase from "@mui/material/ButtonBase";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { withTranslation } from "react-i18next";
import { setUserGeo } from "../../../actions/user";

class MapPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapActive: false,
      loading: false,
      address: null,
      position: {
        lat: 0,
        lng: 0,
      },
      defaultPosition: {
        lat: 48.4407486,
        lng: 35.0412006,
      },
    };
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }
  componentDidMount() {
    if ("geolocation" in navigator) {
      this.getGeoLocation();
    }
    this.props.changeLogoState(false);
  }

  getGeoLocation() {
    const success = (pos) => {
      let crd = pos.coords;
      this.setState({
        defaultPosition: {
          lat: crd.latitude,
          lng: crd.longitude,
        },
      });
      this.fetchAddress(crd.latitude, crd.longitude);
    };
    navigator.geolocation.getCurrentPosition(success);
  }

  async fetchAddress(lat, lng) {
    const request = new Request(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=AIzaSyCKiNcHpTPAlrL6_IlowSb0z0FfS5gjhFc`
    );
    try {
      const data = await fetch(request);
      const res = await data.json();
      const city = this.getLocationCity(res.results);
      this.props.setUserGeo({
        latitude: lat,
        longitude: lng,
        city,
      });
    } catch (e) {
      console.log(e);
    }
  }

  getLocationCity(places) {
    const data = places[0].address_components;
    // const administrative_area = data.find((el) =>
    //   el.types.includes("administrative_area_level_1")
    // ).long_name;
    const city = data.find((el) => el.types.includes("locality")).long_name;
    return `${city}`;
  }

  handleMapSave = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.props.history.push("/auth/phone");
    }, 1000);
  };

  handleLocationChange({ position, address, places }) {
    if (places) {
      address = this.getLocationCity(places);
    }
    if (position && address) {
      this.setState({ position, address, mapActive: false });
    }
  }
  // <h3>{t("mapPage.choose_location")}</h3>
  render() {
    const { t } = this.props;
    // const handleLocationChange = ({ position, address, places }) => {};
    return (
      <div className="login-data-container">
        {this.state.mapActive ? (
          <div onClick={() => this.setState({ mapActive: false })}>
            <GoogleMap />
          </div>
        ) : (
          <>
            <div className="welcome-text center">
              <img src={mapframe} alt="Map frame data" />
              <h2>{t("mapPage.enable_location")}</h2>
            </div>
            <Box className="light center margin txt14">
              {t("mapPage.or_choose_lcoation")}
            </Box>
            <ButtonBase
              className="custom-select-container"
              onClick={() => this.setState({ mapActive: true })}
            >
              <LocationOnOutlinedIcon />
              <h3>
                {this.props.geo && this.props.geo.city
                  ? this.props.geo.city
                  : t("mapPage.select")}
              </h3>
            </ButtonBase>
            <Box
              className="light center margin txt14"
              onClick={() => this.getGeoLocation()}
            >
              Обрати поточну локацiю
            </Box>
            <Box className="light center margin-large txt14">
              <Button
                className="orange rounded center"
                onClick={this.handleMapSave}
              >
                {this.state.loading ? (
                  <CircularProgress size={23} style={{ color: "#fff" }} />
                ) : (
                  // "Save"
                  `${t("mapPage.save")}`
                )}
              </Button>
            </Box>
          </>
        )}
      </div>
    );
  }
}

// <GoogleMap />

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    geo: state.user && state.user.geo,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { changeLogoState, setUserGeo })(MapPage)
);
