import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";

import UsersSinglePage from "./user";
class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    return <UsersSinglePage {...this.props} />;
    // const role = user.role;
    // if (role === 2) {
    //   return <UsersSinglePage user_data={user} {...this.props} />;
    // } else if (role === 1) {
    //   return <AmbassadorSinglePage user_data={user} {...this.props} />;
    // }
  }
}

const mapStateToProps = (state) => {
  return { contracts: state.contracts && state.contracts.list };
};

export default connect(mapStateToProps, { changeLogoState })(UsersPage);
