// API LIST
const PROD_API = "https://api.ambasera.com";
const TEST_API = "https://api-test.ambasera.com";
const TEST_LOCAL_API = "http://192.168.0.100:8888/panel";

const HOSTNAME = window.location.hostname;

let URL = PROD_API;

const LOCAL_BACK = 0;
const PROD = 0;

//  WiLL EXPORT NEEDED API LINK
if (HOSTNAME.includes("local") || HOSTNAME.includes("192.168")) {
  URL = (PROD && PROD_API) || (LOCAL_BACK && TEST_LOCAL_API) || TEST_API;
} else if (HOSTNAME.includes("test")) {
  URL = TEST_API;
}

export default URL;
