import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../onSubmit";
import { registerUser } from "../../../actions/register";
import phone from "./../../../resources/img/icons/phone.svg";
import user from "./../../../resources/img/icons/user.svg";
import email from "./../../../resources/img/icons/email.svg";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

import Icon from "@mui/material/Icon";

import InputAdornment from "@mui/material/InputAdornment";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { getInviteData } from "../../../actions/invite";
import { setUserProfile } from "../../../actions/user";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { withTranslation } from "react-i18next";

class LeadRegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brand_info: null,
    };
  }
  componentDidMount() {
    this.props.changeLogoState(true);
    this.props.initialize({
      firstName: this.props.service.firstName || "",
      lastName: this.props.service.lastName || "",
      email: this.props.service.email || "",
      phone: this.props.service.phone || "",
    });
    this.setParams();
  }

  async setParams() {
    const params = this.props.match.params;
    const service = await getInviteData(this.props.uiQr);
    if (service && service.data && service.data.service) {
      this.setState({
        brand_info: {
          ...service.data.service,
          contact_data: {
            phone: service.data.service.phone,
            web_page: service.data.service.weblink,
          },
        },
      });
    }
    this.props.setUserProfile({ qr: this.props.uiQr });
  }

  handleAuth = (index) => {
    const auth = this.state.auth;
    const isLoading = auth.findIndex((el) => el.loading === true);
    if (isLoading === -1 && !auth[index].loading) {
      auth[index].loading = true;
      this.setState({
        auth,
      });
      setTimeout(() => {
        this.props.history.push("/auth/image");
      }, 1000);
    }
  };

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  renderPhoneMask = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="UA"
        limitMaxLength
        {...input}
        {...custom}
      />
    );
  };

  renderDatePicker = ({
    input: { onBlur, value, ...inputProps },
    meta: { touched, error },
    ...custom
  }) => {
    const onChange = (dates) => {
      Date.parse(dates)
        ? inputProps.onChange(dates.toISOString())
        : inputProps.onChange(null);
    };
    return (
      <MobileDatePicker
        {...inputProps}
        {...custom}
        autoOk
        error={!!error}
        animateYearScrolling
        allowKeyboardControl={false}
        disableFuture
        variant="inline"
        inputVariant="outlined"
        value={value || null}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} {...custom} />}
      />
    );
  };
  render() {
    const { t } = this.props;
    const el = this.state.brand_info;
    return (
      <div className="login-data-container">
        <div className="welcome-text center">
          <h3>
            {t("leadWelcomePage.registre_to_school")}
            {` ${el && el.public_name}?`}
          </h3>
        </div>

        <div className="select-auth-method">
          <Form onSubmit={this.props.handleSubmit}>
            <div className="row item full">
              <Field
                name="phone"
                component={this.renderPhoneMask}
                placeholder={t("reg.Phone Number")}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={phone} height={25} width={25} alt="phone" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="firstName"
                component={this.renderTextField}
                placeholder={t("reg.First Name")}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={user} height={25} width={25} alt="user" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <div className="row item full">
              <Field
                name="lastName"
                component={this.renderTextField}
                placeholder={t("reg.Last Name")}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={user} height={25} width={25} alt="user" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <div className="row item full">
              <Field
                name="email"
                component={this.renderTextField}
                placeholder={t("reg.Email")}
                variant="outlined"
                type="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={email} height={25} width={25} alt="email" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <Box className="light center margin-large txt14">
              <Button className="orange rounded center" type="submit">
                {this.state.loading ? (
                  <CircularProgress size={23} style={{ color: "#fff" }} />
                ) : (
                  `${t("leadRegisterPage.sign_up")}`
                )}
              </Button>
            </Box>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    service: state.user && state.user.service,
    uiQr: state.ui && state.ui.params && state.ui.params.id,
  };
};

const validate = (values) => {
  const errors = {};
  if (values.phone && !isPossiblePhoneNumber(values.phone)) {
    errors.phone = "Incorrect phone number";
  }
  return errors;
};

const withForm = reduxForm({
  form: "LeadRegister",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: registerUser,
});

export default withTranslation()(
  connect(mapStateToProps, { changeLogoState, setUserProfile })(
    withForm(LeadRegisterPage)
  )
);
