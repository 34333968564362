import PaymentsService from "../requests/services/payments.service";
import Types from "../constants/payments";

export function getUserPayments() {
  return async (dispatch, getState) => {
    const { services } = getState().user;
    const payments = await PaymentsService.GetUserPayments();
    if (services && payments && payments.data) {
      let payments_list = {};
      for (let item of payments.data) {
        const service = services.find(
          (el) => el.service.seq === item.service_seq
        );
        const { public_name } = service.service;
        if (payments_list[public_name]) {
          payments_list[public_name].payments.push(item);
        } else {
          payments_list[public_name] = {
            payments: [item],
            ...service.service,
          };
        }
      }
      if (
        payments_list &&
        Object.keys(payments_list) &&
        Object.keys(payments_list).length
      ) {
        for (let el of Object.keys(payments_list)) {
          payments_list[el].sum = processSumPayments(payments_list[el]);
          payments_list[el].complete_sum = processCompletedPayments(
            payments_list[el]
          );
        }
      }
      dispatch({
        type: Types.DATA,
        payload: {
          list: payments_list,
        },
      });
      return payments;
    }
  };
}

function processSumPayments(el) {
  const not_complete_payments = el.payments.filter(
    (item) => item.complete_am === null
  );
  const sum = not_complete_payments.length
    ? not_complete_payments
        .map((item) => item.credited_amount)
        .reduce((prev, next) => prev + next)
    : 0;
  return sum;
}

function processCompletedPayments(el) {
  const not_complete_payments = el.payments.filter(
    (item) => item.complete_am !== null
  );
  const sum = not_complete_payments.length
    ? not_complete_payments
        .map((item) => item.complete_am)
        .reduce((prev, next) => prev + next)
    : 0;
  return sum;
}

export async function getServicePayments(data) {
  const res = await PaymentsService.GetServicePayments(data);
  return res;
}

export async function confirmUserPayment(data) {
  const res = await PaymentsService.ConfirmUserPayment(data);
  return res;
}

export async function confirmServicePayment(data) {
  const res = await PaymentsService.ConfirmServicePayment(data);
  return res;
}
