import React from "react";
import { connect } from "react-redux";
import payment_confirm from "./../../../resources/img/modal-icons/payment-confirm.svg";
import Button from "@mui/material/Button";
import { withTranslation } from "react-i18next";
import { confirmUserPayment } from "../../../actions/payments";
import { getUserPayments } from "../../../actions/payments";

class PaymentConfirmModal extends React.Component {
  handlePaymentsAccept = async () => {
    for (let item of this.props.data.payments) {
      if (item.complete_am === null) {
        await confirmUserPayment(item.id);
      }
    }
    this.props.getUserPayments();
    this.props.closeRequestModal();
  };

  render() {
    const { t } = this.props;
    return (
      <div className="modal-window">
        <div className="modal-window-info">
          <img src={payment_confirm} alt="payment_confirm" />
          <div className="info-text">
            {t("modal.Are you sure")}
            <span> {this.props.data.price} UAH </span>
            {t("modal.From the")} {this.props.data.title} ?
          </div>
        </div>
        <div className="modal-buttons-container">
          <Button
            className="modal-buttons no"
            onClick={this.props.closeRequestModal}
          >
            {t("modal.No")}
          </Button>
          <Button
            className="modal-buttons yes"
            onClick={this.handlePaymentsAccept}
          >
            {t("modal.Yes")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default withTranslation()(
  connect(mapStateToProps, { getUserPayments })(PaymentConfirmModal)
);
