import PaymentConfirmModal from "./components/PaymentConfirmModal";
import CongragulationsModal from "./components/CongragulationsModal";
import FilterStatusModal from "./components/FilterStatusModal";
import CookiesModal from "./components/CookiesModal";
import AuthAlertModal from "./components/AuthAlertModal";
import AreYouShureModal from "./components/AreYouShureModal";
import PhoneShowModal from "./components/PhoneShowModal";
import TelegramModal from "./components/TelegramModal";
import DetektedWorkerAccount from "./components/DetektedWorkerAccount";

const Switcher = (props) => {
  let result = null;
  switch (props.type) {
    case "PaymentConfirmModal":
      return <PaymentConfirmModal {...props} />;
    case "CongragulationsModal":
      return <CongragulationsModal {...props} />;
    case "FilterStatusModal":
      return <FilterStatusModal {...props} />;
    case "CookiesModal":
      return <CookiesModal {...props} />;
    case "AuthAlertModal":
      return <AuthAlertModal {...props} />;
    case "AreYouShureModal":
      return <AreYouShureModal {...props} />;
    case "PhoneShowModal":
      return <PhoneShowModal {...props} />;
    case "TelegramModal":
      return <TelegramModal {...props} />;
    case "DetektedWorkerAccount":
      return <DetektedWorkerAccount {...props} />;
    default:
      break;
  }
  return null;
};

export default Switcher;
