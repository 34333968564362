import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../onSubmit";
import { updateUserProfile } from "../../../actions/user";
import phone from "./../../../resources/img/icons/phone.svg";
import user from "./../../../resources/img/icons/user.svg";
import email from "./../../../resources/img/icons/email.svg";
import date_of_birth from "./../../../resources/img/icons/date-of-birth.svg";
import Icon from "@mui/material/Icon";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneInput from "react-phone-number-input";

import InputAdornment from "@mui/material/InputAdornment";

import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { withTranslation } from "react-i18next";

class EditProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.props.initialize({
      phone_number:
        (this.props.user && this.props.user.phone) || this.props.phone || "",
      first_name: this.props.user && this.props.user.first_name,
      last_name: this.props.user && this.props.user.last_name,
      birth_date: this.props.user && this.props.user.birth_date,
      email: this.props.user && this.props.user.email,
    });

    this.props.changeLogoState(true);
  }

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  renderPhoneMask = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="UA"
        limitMaxLength
        {...input}
        {...custom}
      />
    );
  };

  renderCardInput = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <InputMask mask={"9999-9999-9999-9999"} {...input} {...custom}>
        {() => (
          <TextField
            error={touched && !!error}
            helperText={touched && !!error && error}
            {...input}
            {...custom}
            variant="outlined"
          />
        )}
      </InputMask>
    );
  };

  renderDatePicker = ({
    input: { onBlur, value, ...inputProps },
    meta: { touched, error },
    ...custom
  }) => {
    const onChange = (dates) => {
      Date.parse(dates)
        ? inputProps.onChange(dates.toISOString())
        : inputProps.onChange(null);
    };
    return (
      <MobileDatePicker
        {...inputProps}
        {...custom}
        autoOk
        error={!!error}
        animateYearScrolling
        allowKeyboardControl={false}
        disableFuture
        variant="inline"
        inputVariant="outlined"
        value={value || null}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} {...custom} />}
      />
    );
  };
  render() {
    const { t } = this.props;
    console.log(this.props.submitting)
    return (
      <div className="login-data-container">
        <div className="welcome-text center">
          <h3>{t("edit.my_data")}</h3>
        </div>

        <div className="select-auth-method">
          <Form onSubmit={this.props.handleSubmit}>
            <div className="row item full">
              <Field
                name="first_name"
                component={this.renderTextField}
                placeholder={t("reg.First Name")}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={user} height={25} width={25} alt="user" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="row item full">
              <Field
                name="last_name"
                component={this.renderTextField}
                placeholder={t("reg.Last Name")}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={user} height={25} width={25} alt="user" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="row item full">
              <Field
                name="birth_date"
                component={this.renderDatePicker}
                placeholder="Date Of Brith"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img
                          src={date_of_birth}
                          height={25}
                          width={25}
                          alt="date_of_birth"
                        />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="row item full">
              <Field
                name="phone_number"
                component={this.renderPhoneMask}
                placeholder={t("reg.Phone Number")}
                variant="outlined"
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={phone} height={25} width={25} alt="phone" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {false ? (
              <div className="row item full">
                <Field
                  name="card_code"
                  component={this.renderCardInput}
                  label={t("withdrawContainer.ad_16_number")}
                  variant="outlined"
                />
              </div>
            ) : null}

            <div className="row item full">
              <Field
                name="email"
                type="email"
                component={this.renderTextField}
                placeholder={t("reg.Email")}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={email} height={25} width={25} alt="email" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {false ? (
              <ButtonBase className="custom-select-container">
                <LocationOnOutlinedIcon />
                <h3>{t("mapPage.select")}</h3>
              </ButtonBase>
            ) : null}

            <Box className="light center margin-large txt14">
              <Button
                className="orange rounded center"
                type="submit"
              >
                {this.state.loading ? (
                  <CircularProgress size={23} style={{ color: "#fff" }} />
                ) : (
                  `${t("modal.Accept")}`
                )}
              </Button>
            </Box>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    user: state.user && state.user.info && state.user.info.user,
    phone: state.user && state.user.info && state.user.info.phone,
  };
};

const validate = (values) => {
  const errors = {};
  if (values.phone_number && values.phone_number.includes("_")) {
    errors.phone_number = "Incorrect prone number";
  }
  return errors;
};

const withForm = reduxForm({
  form: "EditUserPage",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: updateUserProfile,
});

export default withTranslation()(
  connect(mapStateToProps, { changeLogoState })(withForm(EditProfilePage))
);
