import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";

import soroban from "./../../../resources/img/soroban.svg";

import BrandsComponent from "../../../components/Brands";
import { setUserProfile, getUserContracts } from "../../../actions/user";
import { getServices } from "../../../actions/service";
import { withTranslation } from "react-i18next";
import { getUserPayments } from "../../../actions/payments";
import { toogleSnackbar } from "../../../actions/modal";


class HomeAbmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [
        {
          logo: soroban,
          title: "Soroban",
          program_title: "soroban",
          raiting: "4.5",
          liked: true,
          brand_data: {
            leads: "30",
            cust: "5",
            sum: "1750",
          },
        },
        // {
        //   logo: null,
        //   title: "Artishok",
        //   liked: false,
        //   program_title: "soroban",
        //   brand_data: {
        //     leads: "11",
        //     cust: "1",
        //     sum: "100",
        //   },
        // },
        // {
        //   logo: null,
        //   title: "LegoEduc",
        //   liked: true,
        //   program_title: "soroban",
        //   brand_data: {
        //     payed: "50",
        //     result: "26",
        //     price: "350 UAH",
        //   },
        // },
      ],
    };
  }
  componentDidMount() {
    if (!this.props.user.first_name && !this.props.user.first_name) {
      this.props.history.push("/edit");
      this.props.toogleSnackbar({
        open: true,
        message: "Please complete your profile.",
      });
    } else {
      this.fetchData();
      this.props.changeLogoState(true);
    }
  }

  async fetchData() {
    await this.props.getUserPayments();
    await getUserContracts();
    await this.getUserServices();
  }

  async getUserServices() {
    const resService = await this.props.getServices();
    if (
      resService &&
      resService.data &&
      resService.data.length &&
      resService.data.find((el) => el.role === 3)
    ) {
      this.props.setUserProfile({
        role_id: 1,
        services: resService.data.filter((el) => el.role === 3),
      });
    }
  }

  render() {
    return (
      <>
        <div className="home-top-text center-demo">
          {/*
            <div>{t("homeAbmPage.favoruites_brands_list")}</div>
            <div
              className="orange"
              onClick={() => this.props.history.push("/brands")}
            >
              {t("homeAbmPage.view_brands")}
            </div>
          */}
        </div>

        <BrandsComponent brands={this.state.brands} {...this.props} home />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user && state.user.info && state.user.info.user };
};

export default withTranslation()(
  connect(mapStateToProps, {
    toogleSnackbar,
    changeLogoState,
    setUserProfile,
    getServices,
    getUserPayments,
  })(HomeAbmPage)
);
