import Types from "../constants/modal";

export function openModal(item) {
  return (dispatch, getState) => {
    dispatch({
      type: Types.OPEN,
      modal: {
        ...item,
      },
    });
  };
}

export function toogleSnackbar(item) {
  return (dispatch, getState) => {
    dispatch({
      type: Types.SNACKBAR,
      modal: {
        snackbar: {
          ...item,
        },
      },
    });
  };
}

export function closeRequestModal() {
  return (dispatch, getState) => {
    dispatch({
      type: Types.CLOSE,
    });
  };
}
