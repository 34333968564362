import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { withTranslation } from "react-i18next";
import { getServiceContracts } from "./../../../actions/service";
import CustomersComponent from "../../../components/Customers";
import { clearContracts } from "../../../actions/contracts";

import { FormControl, Select, MenuItem, OutlinedInput } from "@mui/material";
const schema = {
  0: 2,
  1: 4,
  3: 3,
};

const serviceList = [
  { role: 3, label: "menu.Ambassador" },
  { role: 2, label: "menu.Workers" },
  { role: 4, label: "customers.user" },
];
const workerList = [{ role: 3, label: "menu.Ambassador" },   { role: 4, label: "customers.user" },];

const ambList = [{ role: 4, label: "customers.user" }];

const displayMenuSchema = {
  0: serviceList,
  1: ambList,
  3: workerList,
};

function ServiceUsers({ ...props }) {
  const { role_id } = props;
  const [selectedUsers, setSelectedUsers] = useState(schema[role_id]);

  useEffect(() => {
    function fetchData() {
      props.getServiceContracts({
        limit: 10,
        offset: 0,
        role: selectedUsers,
      });
    }
    fetchData();
    return () => {
      props.clearContracts();
    };
  }, [selectedUsers]);

  const { t } = props;

  return (
    <>
      <div className="home-top-text">
        <FormControl sx={{ width: "220px" }} className="service-picker">
          <Select
            value={selectedUsers}
            onChange={(e) => setSelectedUsers(e.target.value)}
            input={<OutlinedInput />}
          >
            {displayMenuSchema[role_id].map((el, index) => (
              <MenuItem value={el.role} key={index}>
                {t(el.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <CustomersComponent {...props} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    role_id: state.user && state.user.role_id,
  };
};

// export default connect(mapStateToProps, { changeLogoState, openDrawler })(
//   BrandsComponent
// );
export default withTranslation()(
  connect(mapStateToProps, {
    changeLogoState,
    getServiceContracts,
    clearContracts,
  })(ServiceUsers)
);
