import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import { changeLogoState } from "../../../actions/ui";
import { reduxForm, Form, Field } from "redux-form";
import onSubmit from "../../onSubmit";
import phone from "./../../../resources/img/icons/phone.svg";
import email from "./../../../resources/img/icons/email.svg";
import Icon from "@mui/material/Icon";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import upload from "./../../../resources/img/upload.svg";
import PhoneInput from "react-phone-number-input";

import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import UploadPhotoService from "../../../requests/services/upload.service";
import { setServiceData } from "../../../actions/user";

import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { withTranslation } from "react-i18next";
import { setHeaderName } from "../../../actions/ui";
import { modifyService } from "./../../../actions/service";

import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";

class ModifyServicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file_upload_error: null,
      pdf_upload_error: null,
      file: null,
      file_name: null,
    };
  }
  componentDidMount() {
    this.props.setServiceData({
      logo: null,
    });
    const activeService =
      this.props.services &&
      this.props.services.find((el) => el.service.active === true).service;
    this.props.setHeaderName(`${activeService.public_name}`);
    this.props.initialize({
      ...activeService,
      about: activeService.about.en,
      youtube: activeService.youtube.en,
      amb_cl_fee: null,
      phone: `${activeService.phone}`,
      phone_owner: `${activeService.phone_owner}`
    });

    this.props.changeLogoState(true);
  }

  renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        {...input}
        {...custom}
        error={touched && !!error}
        helperText={touched && !!error && error}
      />
    );
  };

  onFileChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      // console.log();
      let file = document.getElementById("file_pdf").files[0];
      if (file.type !== "application/pdf") {
        this.setState({ pdf_upload_error: "Неправильный тип файла" });
        return;
      }
      const res = await UploadPhotoService.UploadFile(file);
      this.props.setServiceData({
        pdf: res,
      });
      this.setState({
        pdf_upload_error: null,
        file: res,
        file_name: `${file.name}`,
      });
    }
  };

  onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      // console.log();
      let img = document.getElementById("file").files[0];
      if (!img.type.includes("image")) {
        this.setState({ file_upload_error: "Неправильный тип файла" });
        return;
      }

      const res = await UploadPhotoService.UploadFile(img);
      this.props.setServiceData({
        logo: res,
      });
      this.setState({
        file_upload_error: null,
      });
    }
  };

  renderPhoneMask = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="UA"
        limitMaxLength
        {...input}
        {...custom}
      />
    );
  };

  renderDatePicker = ({
    input: { onBlur, value, ...inputProps },
    meta: { touched, error },
    ...custom
  }) => {
    const onChange = (dates) => {
      Date.parse(dates)
        ? inputProps.onChange(dates.toISOString())
        : inputProps.onChange(null);
    };
    return (
      <MobileDatePicker
        {...inputProps}
        {...custom}
        autoOk
        error={!!error}
        animateYearScrolling
        allowKeyboardControl={false}
        disableFuture
        variant="inline"
        inputVariant="outlined"
        value={value || null}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} {...custom} />}
      />
    );
  };

  renderSelect = ({ input, meta: { touched, error }, ...custom }) => {
    return (
      <FormControl variant="outlined" required>
        <Select
          native
          {...custom}
          {...input}
          inputProps={{
            name: input.name,
          }}
        >
          {custom.data.map((item, key) => (
            <option key={key} value={item.title}>
              {item.title}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  };

  render() {
    const form = this.props.ModifyServicePage;
    const { t } = this.props;
    return (
      <div className="login-data-container">
        <div className="welcome-text center">
          <h3>{t("edit.my_data")}</h3>
        </div>

        <div className="select-auth-method">
          <Form onSubmit={this.props.handleSubmit}>
            <div className="row item full">
              <Field
                name="public_name"
                component={this.renderTextField}
                label={t("reg.Brand Name")}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="legal_name"
                component={this.renderTextField}
                label={t("reg.legal_name")}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="addresses"
                component={this.renderTextField}
                label={`${t("reg.country")}, ${t("reg.city")}`}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="legal_address"
                component={this.renderTextField}
                label={t("reg.address")}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="geo"
                component={this.renderTextField}
                label={t("reg.city")}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="about"
                component={this.renderTextField}
                label={t("reg.about")}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="youtube"
                component={this.renderTextField}
                label={t("reg.YouTube")}
                variant="outlined"
              />
            </div>

            <div className="row item full">
              <Field
                name="weblink"
                component={this.renderTextField}
                label={t("reg.WebLink")}
                variant="outlined"
              />
            </div>

            <div className="row item full">
              <Field
                name="phone"
                component={this.renderPhoneMask}
                label={t("reg.contact_number")}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={phone} height={25} width={25} alt="phone" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <div className="row item full">
              <Field
                name="email"
                component={this.renderTextField}
                label={t("reg.Email")}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={email} height={25} width={25} alt="email" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <div className="row item full select">
              <div>Данные директора / учредителя</div>
              <Field
                name="email_owner"
                component={this.renderTextField}
                label="Почта директора"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={email} height={25} width={25} alt="email" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <div className="row item full select">
              <Field
                name="first_name_owner"
                component={this.renderTextField}
                label="Имя директора"
                variant="outlined"
                required
              />
            </div>

            <div className="row item full select">
              <Field
                name="last_name_owner"
                component={this.renderTextField}
                label="Фамилия директора"
                variant="outlined"
                required
              />
            </div>

            <div className="row item full select">
              <Field
                name="phone_owner"
                component={this.renderPhoneMask}
                label="Телефон директора"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <img src={phone} height={25} width={25} alt="phone" />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <div className="row item full select">
              <Field
                name="okpo"
                component={this.renderTextField}
                label="Код ЄДРПОУ"
                variant="outlined"
                required
              />
            </div>

            <div className="row item full select">
              <div>{t("reg.currency")}</div>
              <Field
                name="currency"
                component={this.renderSelect}
                data={[{ title: "UAH" }, { title: "RUB" }]}
                variant="outlined"
                required
              />
            </div>

            <div className="row item full select">
              <div>{t("reg.bonus_amb_lead")}</div>
              <Field
                name="amb_cl_fee"
                component={this.renderTextField}
                label={t("reg.in-dev")}
                variant="outlined"
                disabled
              />
            </div>

            <div className="row item full">
              <Field
                name="amb_fee"
                component={this.renderTextField}
                label={t("reg.bonus_out_amb")}
                variant="outlined"
                required
              />
            </div>
            {false && (
              <div className="row item full">
                <Field
                  name="amb_bonus"
                  component={this.renderTextField}
                  label="amb_bonus"
                  variant="outlined"
                  required
                />
              </div>
            )}

            <div className="row item full">
              <Field
                name="worker_fee"
                component={this.renderTextField}
                label={t("reg.bonus_employee")}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="client_discount"
                component={this.renderTextField}
                label={t("reg.client_discount")}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="min_withdraw"
                component={this.renderTextField}
                label={t("reg.min_withdraw")}
                variant="outlined"
                required
              />
            </div>
            <div className="row item full">
              <Field
                name="new_amb_bonus"
                component={this.renderTextField}
                label={t("reg.bonus_new_amb")}
                variant="outlined"
                required
              />
            </div>
            {/*<div className="row item full select">*/}
            {/*  <div>{t("reg.brand_logo")}</div>*/}
            {/*  <div className="upload-logo">*/}
            {/*    <div>*/}
            {/*      {this.props.image ||*/}
            {/*      (form && form.values && form.values.logo_url) ? (*/}
            {/*        <img*/}
            {/*          src={*/}
            {/*            this.props.image ||*/}
            {/*            (form && form.values && form.values.logo_url)*/}
            {/*          }*/}
            {/*          height={30}*/}
            {/*          width={30}*/}
            {/*          alt="upload"*/}
            {/*        />*/}
            {/*      ) : (*/}
            {/*        t("serviceRegisterPage.upload_logo")*/}
            {/*      )}*/}
            {/*    </div>*/}
            {/*    <div className="upload-logo-item">*/}
            {/*      <Icon>*/}
            {/*        <img src={upload} height={25} width={25} alt="upload" />*/}
            {/*      </Icon>*/}
            {/*    </div>*/}
            {/*    <input*/}
            {/*      type="file"*/}
            {/*      onChange={this.onImageChange}*/}
            {/*      name="myImage"*/}
            {/*      id="file"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <Box m={2}>*/}
            {/*    <Collapse in={this.state.file_upload_error}>*/}
            {/*      <Typography component="p" className="error">*/}
            {/*        {t("errors.wrong_img")}*/}
            {/*      </Typography>*/}
            {/*    </Collapse>*/}
            {/*  </Box>*/}
            {/*</div>*/}

            <Box className="light center margin-large txt14">
              <Button className="orange rounded center" type="submit">
                {this.state.loading ? (
                  <CircularProgress size={23} style={{ color: "#fff" }} />
                ) : (
                  `${t("mapPage.save")}`
                )}
              </Button>
            </Box>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user && state.user.loggedin,
    ModifyServicePage: state.form && state.form.ModifyServicePage,
    services: state.user && state.user.services,
    image: state.user && state.user.service && state.user.service.logo,
  };
};

const validate = (values, props) => {
  const activeService =
    props.services &&
    props.services.find((el) => el.service.active === true).service;
  const errors = {};
  if (values.phone && values.phone.includes("_")) {
    errors.phone = "Incorrect prone number";
  }

  if (values.phone_owner && values.phone_owner.includes("_")) {
    errors.phone_owner = "Incorrect prone number";
  }
  values.seq = activeService.seq;
  return errors;
};

const withForm = reduxForm({
  form: "ModifyServicePage",
  updateUnregisteredFields: true,
  onSubmit,
  validate,
  onAccept: modifyService,
});

export default withTranslation()(
  connect(mapStateToProps, {
    changeLogoState,
    setHeaderName,
    modifyService,
    setServiceData,
  })(withForm(ModifyServicePage))
);
