import TypesService from "../constants/service";

import history from "../history";
import ServiceService from "./../requests/services/service.service";
import { setUserProfile, updateUserProfile } from "./user";
import { changeContracts } from "./contracts";
import STATUSES from "../constants/statuses";

export function getBotSecret() {
  return async (dispatch, getState) => {
    const { services } = getState().user;
    const activeService =
      services && services.find((el) => el.service.active === true).service;
    const res = await ServiceService.BotSecret(activeService.seq);
    return res;
  };
}

export function changeClentStatus(body, query) {
  return async (dispatch, getState) => {
    const res = await ServiceService.ChangeClientStatus(body, query);
    return res;
  };
}

export function createServiceQr(data) {
  return async (dispatch, getState) => {
    const res = await ServiceService.CreateInviteService(data);
    const { role_id } = getState().user;
    getServices(role_id)(dispatch, getState);
    return res;
  };
}

export function updateService(data) {
  return async (dispatch, getState) => {
    const { services } = getState().user;
    setUserProfile({
      services: services.map((el, index) => ({
        ...el,
        service: {
          ...el.service,
          active: index === data,
        },
      })),
    })(dispatch, getState);
  };
}

export function modifyService(data) {
  return async (dispatch, getState) => {
    const send = data;
    const image =
      getState().user &&
      getState().user.service &&
      getState().user.service.logo;
    send.youtube = { en: send.youtube };
    send.about = { en: send.about };
    send.phone = send.phone.replaceAll(" ", "");
    send.phone_owner = send.phone_owner.replaceAll(" ", "");
    send.logo_url = image ? image : send.logo_url;
    send.amb_cl_fee = 1;
    const seq = data.seq;
    delete send.seq;
    await ServiceService.ModifyService({ send, seq });
    const res = await ServiceService.GetService();
    setUserProfile({
      services: res.data
        .filter((el) => el.role === 1)
        .map((el, index) => ({
          ...el,
          service: {
            ...el.service,
            active: el.service.id === send.id,
          },
        })),
    })(dispatch, getState);
    history.push("/service/home");
  };
}

export function getServiceContracts(data) {
  return async (dispatch, getState) => {
    const { services } = getState().user;
    const statusFilter = getState().ui && getState().ui.filter;
    const activeFilter = statusFilter.filter((el) => el.active);
    const filterValues =
      (activeFilter &&
        activeFilter.length &&
        activeFilter.map((el) => el.value).join(",")) ||
      null;
    if (filterValues) data.status = filterValues;
    const activeService =
      services && services.find((el) => el.service.active === true).service;
    if (activeService) {
      let res = await ServiceService.GetServiceContracts(
        data,
        activeService.seq
      );
      if (res) changeContracts(res.data, data)(dispatch, getState);
      return res;
    }
  };
}

export function sendServiceContracts(
  data = { statuses: STATUSES.map((el) => el.value).join(",") }
) {
  return async (dispatch, getState) => {
    const { services } = getState().user;
    const statusFilter = getState().ui && getState().ui.filter;
    const activeFilter = statusFilter.filter((el) => el.active);
    const filterValues =
      (activeFilter &&
        activeFilter.length &&
        activeFilter.map((el) => el.value).join(",")) ||
      null;
    if (filterValues) data.statuses = filterValues;
    const activeService = services.find(
      (el) => el.service.active === true
    ).service;
    // console.log(data);
    const res = await ServiceService.SendContracts(data, activeService.seq);
    return res;
  };
}

export function getServices(role = false) {
  return async (dispatch, getState) => {
    const res = await ServiceService.GetService();
    if (res && res.data && role !== false && role === 0) {
      setUserProfile({
        services: res.data
          .filter((el) => el.role === 1)
          .map((el, index) => ({
            ...el,
            service: {
              ...el.service,
              active: index === 0,
            },
          })),
      })(dispatch, getState);
    }
    if (res && res.data && role !== false && role === 3) {
      setUserProfile({
        services: res.data
          .filter((el) => el.role === 2)
          .map((el, index) => ({
            ...el,
            service: {
              ...el.service,
              active: index === 0,
            },
          })),
      })(dispatch, getState);
    }
    return res;
  };
}

export function createService() {
  return async (dispatch, getState) => {
    const service = getState().service;
    const user = getState().user && getState().user.service;
    const data = {
      ...service["2"],
      ...service["1"],
      ...service["3"],
      ...service["4"],
      ...service["5"],
      ...user,
    };
    const body = {
      public_name: data.brand_name,
      legal_name: data.legal_name,
      addresses: `${data.country.name}, ${data.city}`,
      legal_address: data.address,
      geo: data.city,
      about: { en: data.about },
      youtube: { en: data.YouTube },
      phone: data.contact_number.replaceAll(" ", ""),
      email: data.email,
      currency: data.currency,
      amb_fee: data.bonus_out_amb,
      amb_cl_fee: data.bonus_amb_lead || 1,
      amb_bonus: 100,
      worker_fee: data.bonus_employee,
      client_discount: data.client_discount,
      min_withdraw: data.min_withdraw,
      new_amb_bonus: data.bonus_new_amb,
      logo_url: data.logo || "",
      email_owner: data.email_owner,
      first_name_owner: data.first_name_owner,
      last_name_owner: data.last_name_owner,
      phone_owner: data.phone_owner.replaceAll(" ", ""),
      okpo: data.organization_code,
      weblink: data.WebLink,
    };
    const res = await ServiceService.CreateService(body);
    await updateUserProfile({
      first_name: body.first_name_owner,
      last_name: body.last_name_owner,
      birth_date: null,
      email: body.email_owner,
    })(dispatch, getState);
    if (res && res.data) {
      await getServices(0)(dispatch, getState);
      await dispatch({
        type: TypesService.CLEAR_SERVICE_DATA,
      });
      return res;
    }
  };
}
