import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
// Redirect
import LoginLayout from "../layout/Login";
import i18n from "i18next";
import { openModal } from "../actions/modal";
import { setUserProfile } from "../actions/user";
// import history from "../history";

class ProtectedLoginRoute extends Component {
  componentDidMount() {
    const cookies = localStorage.getItem("cookies");
    if (!cookies) {
      setTimeout(() => {
        this.props.openModal({ type: "CookiesModal" });
      }, 1000);
    }
  }

  handleCheckAuth(no_modal = false) {
    if (
      this.props.loggedin &&
      this.props.role_id !== null &&
      this.props.role_id !== 2 &&
      !this.props.protected
    ) {
      if (!no_modal && !this.props.reset) {
        return;
      }
      if (this.props.reg_start) return;
    }
  }

  render() {
    const schema = {
      0: "/service/home",
      1: "/home",
      2: "/user/home",
    };
    const reset = !!this.props.reset;
    const isAuthenticated = this.props.loggedin;
    const Component = this.props.component;
    const path = this.props.path;
    const protectedUrl = !!this.props.protected;
    if (reset) {
      this.props.setUserProfile();
    } else {
      if (!isAuthenticated && protectedUrl) return <Redirect to="/" />;
    }
    return (
      <Route
        path={path}
        render={(props) => {
          return (
            <LoginLayout
              {...props}
              i18n={i18n}
              protected={this.props.protected}
            >
              <Component
                {...props}
                i18n={i18n}
                protected={this.props.protected}
              />
            </LoginLayout>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedin: state.user && state.user.loggedin,
    role_id: state.user && state.user.role_id,
    reg_start: state.user && state.user.reg_start,
  };
};
export default connect(mapStateToProps, { openModal, setUserProfile })(
  ProtectedLoginRoute
);
